import React from 'react';
import AppSelect from 'components/select';
import CountriesList from 'utils/data/languages.json';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';

export default function AddLanguage() {
    const {
        control,
        formState: { errors },
    } = useFormContext<{ languages: { language: string; level: string }[] }>();

    const { fields, append } = useFieldArray({ control, name: 'languages' });
    console.log('fields', fields);
    return (
        <>
            <Flex bg="bgGray" p="12px" rounded="8px" mb="25px" mt="64px" gap="16px" wrap="wrap">
                {fields.map((field, index) => (
                    <React.Fragment key={field.id}>
                        <FormControl
                            w="calc(50% - 8px)"
                            isInvalid={Boolean(errors?.languages?.[index]?.language)}
                        >
                            <FormLabel>Language</FormLabel>
                            <Controller
                                control={control}
                                name={`languages.${index}.language`}
                                render={({ field: { onChange, ref } }) => (
                                    <AppSelect
                                        onChange={newValue =>
                                            onChange((newValue as { label: string })?.label)
                                        }
                                        options={CountriesList}
                                        selectedOptionStyle="color"
                                        placeholder="Select language"
                                        refProp={ref}
                                        isSearchable
                                        useBasicStyles
                                        defaultValue={{ label: 'English', value: 'english' }}
                                    />
                                )}
                            />
                            <FormErrorMessage>
                                {errors.languages?.[index]?.language &&
                                    errors.languages?.[index]?.language?.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            w="calc(50% - 8px)"
                            isInvalid={Boolean(errors.languages?.[index]?.level)}
                        >
                            <FormLabel>Proficiency Level</FormLabel>
                            <Controller
                                control={control}
                                name={`languages.${index}.level`}
                                render={({ field: { onChange, ref } }) => (
                                    <AppSelect
                                        onChange={newValue =>
                                            onChange((newValue as { label: string })?.label)
                                        }
                                        options={[
                                            { label: 'Basic', value: 'basic' },
                                            { label: 'Conversational', value: 'conversational' },
                                            { label: 'Fluent', value: 'fluent' },
                                            { label: 'Native Bilingual', value: 'native' },
                                        ]}
                                        selectedOptionStyle="color"
                                        placeholder="Select level"
                                        refProp={ref}
                                        useBasicStyles
                                    />
                                )}
                            />

                            <FormErrorMessage>
                                {errors.languages?.[index]?.level &&
                                    errors.languages?.[index]?.level?.message}
                            </FormErrorMessage>
                        </FormControl>
                    </React.Fragment>
                ))}
            </Flex>
            <Button
                variant="light"
                mx="auto"
                rightIcon={<AddIcon />}
                w="100%"
                size="lg"
                onClick={() => append({ language: '', level: '' })}
            >
                Add More
            </Button>
        </>
    );
}
