import React, { useMemo, useEffect } from 'react';
import {
    Heading,
    Box,
    Button,
    Text,
    PinInput,
    PinInputField,
    HStack,
    Center,
    FormControl,
    FormErrorMessage,
} from '@chakra-ui/react';
import { MailIcon } from 'assets/icons';
import { useTimer } from 'utils/hooks';
import { useReSendOtp, useVerifyOtp } from 'utils/apis/auth.api';
import { Controller, useForm } from 'react-hook-form';

export default function VerifyEmail({
    userEmail,
    setStep,
}: {
    userEmail?: string;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}) {
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<{ otp: string }>();
    const { mutate: verifyEmail, isSuccess, data: otpData, isLoading } = useVerifyOtp('talent');
    const { mutate: resendOtp } = useReSendOtp();
    const now = useMemo(() => new Date().getTime() + 3 * (60 * 1000), []);
    const { minutes, seconds, isTimerOff } = useTimer(now);

    const onSubmit = (values: { otp: string }) => {
        if (userEmail) verifyEmail({ otp: values?.otp, email: userEmail });
    };

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('otp_token', otpData?.token?.access_token);
            setStep(3);
        }
    }, [isSuccess, otpData, setStep]);

    return (
        <Center minH="calc(100vh - 50px)">
            <Box w="100%" maxW="520px" textAlign="center">
                <MailIcon />
                <Heading fontSize={{ base: '24px', md: '31px' }} mt="64px">
                    Verify your email
                </Heading>
                <Text mt="32px">
                    We just sent a 6 digits verification code to{' '}
                    <Text as="span" variant="secondary">
                        {`${userEmail?.replace(
                            userEmail?.slice(4, userEmail?.indexOf('@')),
                            '***',
                        )}`}
                    </Text>
                    . Verify by entering the code here.
                </Text>
                {!isTimerOff && (
                    <Text
                        as={Box}
                        variant="secondary"
                        border="1px solid"
                        borderColor="primary.500"
                        p="10px 16px"
                        rounded="8px"
                        mt="30px"
                        w="max-content"
                        mx="auto"
                    >
                        Time Remaining 0{minutes}: {seconds}
                    </Text>
                )}
                <Box as="form" mt="64px" onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={Boolean(errors?.otp)}>
                        <Controller
                            control={control}
                            name="otp"
                            rules={{ required: 'Enter valid OTP' }}
                            render={({ field: { onChange } }) => (
                                <HStack justify="center">
                                    <PinInput
                                        size="lg"
                                        focusBorderColor="primary.500"
                                        otp
                                        onChange={onChange}
                                        isInvalid={Boolean(errors?.otp)}
                                    >
                                        <PinInputField borderColor="primary.500" />
                                        <PinInputField borderColor="primary.500" />
                                        <PinInputField borderColor="primary.500" />
                                        <PinInputField borderColor="primary.500" />
                                        <PinInputField borderColor="primary.500" />
                                        <PinInputField borderColor="primary.500" />
                                    </PinInput>
                                </HStack>
                            )}
                        />
                        <FormErrorMessage>{errors?.otp && errors?.otp?.message}</FormErrorMessage>
                    </FormControl>
                    <Button w="100%" mt="40px" type="submit" size="lg" isLoading={isLoading}>
                        Verify Email
                    </Button>
                </Box>

                <Text mt="64px">
                    Not received yet?{' '}
                    <Button
                        textDecor="underline"
                        variant="link"
                        isDisabled={isLoading}
                        onClick={() => userEmail && resendOtp(userEmail)}
                    >
                        Resend a new code
                    </Button>
                </Text>
            </Box>
        </Center>
    );
}
