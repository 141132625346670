import React from 'react';
import {
    Card,
    CardBody,
    Divider,
    Flex,
    HStack,
    Heading,
    Icon,
    Tag,
    TagLabel,
    Text,
    Box,
    Button,
    ButtonGroup,
    Grid,
    Center,
} from '@chakra-ui/react';
import {
    DisLikeBtn,
    FavBtn,
    LinkIcon,
    ProjectIcon,
    RedFlag,
    StarRatingFillIcon,
} from 'assets/icons';
import Footer from 'components/footer';
import Header from 'components/header';
import { VerifyIcon } from 'assets/icons/index';
import { Link } from 'react-router-dom';

export default function JobDetail() {
    return (
        <>
            <Header />
            <Card mt="80px" maxW="1280px" mx="auto">
                <CardBody p="24px">
                    <Flex gap="34px">
                        <Heading>
                            Build a Minimum Viable responsive NFT auction website with blockchain
                            integration
                        </Heading>
                        <Flex gap="16px" align="center">
                            <Text
                                as="span"
                                bg="primary.50"
                                color="primary.500"
                                p="8px 14px"
                                borderRadius="4px"
                                whiteSpace="nowrap"
                            >
                                <strong>13</strong> / 100 Connects Required <Icon />
                            </Text>
                            <DisLikeBtn />
                            <FavBtn />
                        </Flex>
                    </Flex>
                    <Text mt="40px">
                        <strong>Job Posted On:</strong> May 30, 2023
                    </Text>
                    <Divider my="40px" borderColor="border" />
                    <Flex gap="16px" justify="center">
                        <ProjectUtils label="Project" value="Blockchain" />
                        <ProjectUtils label="Experience Level" value="Intermediate" />
                        <ProjectUtils label="Project Length" value="1 - 3 Months" />
                        <ProjectUtils label="Less than 30 hours/week" value="Hourly" />
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        Job Description
                    </Heading>
                    <Text mt="30px">
                        Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit fusce diam
                        tincidunt nibh nibh commodo. Sed in adipiscing blandit nullam. Aliquet orci
                        porta fermentum sed sit pretium. Scelerisque consectetur nisl feugiat quam
                        dictum vulputate porttitor turpis sit. Aliquet dapibus aliquam enim orci
                        elit congue justo pharetra ut. Scelerisque consequat faucibus ultricies at
                        id. Et aenean ut varius risus ultrices mattis elementum morbi luctus. Leo
                        cras ipsum metus imperdiet. Tortor consequat elit etiam felis nunc auctor.
                        <br />
                        <br />
                        Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit fusce diam
                        tincidunt nibh nibh commodo. Sed in adipiscing blandit nullam. Aliquet orci
                        porta fermentum sed sit pretium. Scelerisque consectetur nisl feugiat quam
                        dictum vulputate porttitor turpis sit. Aliquet dapibus aliquam enim orci
                        elit congue justo pharetra ut. Scelerisque consequat faucibus ultricies at
                        id. Et aenean ut varius risus ultrices mattis elementum morbi luctus. Leo
                        cras ipsum metus imperdiet. Tortor consequat elit etiam felis nunc auctor.
                    </Text>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        Skills & Expertise
                    </Heading>
                    <HStack mt="30px" flexWrap="wrap">
                        {[...Array(16)]?.map(item => (
                            <Tag key={item}>
                                <TagLabel>Solidity</TagLabel>
                            </Tag>
                        ))}
                    </HStack>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        Activity on this job
                    </Heading>
                    <Flex gap="16px" justify="center" mt="30px">
                        <ProjectUtils label="Proposal" value="10" />
                        <ProjectUtils label="Interviewing" value="04" />
                        <ProjectUtils label="Invites Sent" value="07" />
                        <ProjectUtils label="Unanswered Invites" value="02" />
                        <ProjectUtils label="Last view by client" value="4 days ago" />
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        About the client
                    </Heading>
                    <Flex align="center" gap="16px" mt="30px">
                        <Text
                            as={Flex}
                            rounded="28px"
                            p="6px 10px"
                            bg="#5BC0EB"
                            variant="primary"
                            alignItems="center"
                            gap="6px"
                        >
                            <VerifyIcon />
                            Payment Verified
                        </Text>{' '}
                        <Box display="inline-flex" gap="4px">
                            {[...Array(5)]?.map(item => (
                                <StarRatingFillIcon key={item} />
                            ))}
                        </Box>
                        <Text color="heading" variant="secondary">
                            4.8 / 5.0 (263)
                        </Text>
                    </Flex>
                    <Flex mt="24px" gap="24px">
                        <Grid
                            justifyContent="space-between"
                            p="24px"
                            gap="24px"
                            bg="bgGray"
                            flex="1"
                            rounded="8px"
                            gridTemplateColumns="max-content max-content"
                        >
                            <Text as="span">Jobs Posted:</Text>
                            <Text as="span">20 Jobs, 80% Hire Rate, 1 Job Open</Text>
                            <Text as="span">Total Spent</Text>
                            <Text textAlign="right" as="span">
                                100M USDT
                            </Text>
                        </Grid>
                        <Box p="24px" bg="bgGray" flex="1" rounded="8px">
                            <Text as="span">Link to job</Text>
                            <Flex mt="20px" gap="16px">
                                <Text bg="white" p="12px 16px" rounded="6px" flex="1">
                                    www.stoptalent.com/jobs/blockchian/36288939902
                                </Text>
                                <Center bg="primary.500" rounded="6px" p="10px">
                                    <LinkIcon />
                                </Center>
                            </Flex>
                        </Box>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <ButtonGroup justifyContent="center" w="100%" mb="16px">
                        <Button size="lg" w="300px" variant="danger" leftIcon={<RedFlag />}>
                            Flag as Inappropriate
                        </Button>
                        <Button as={Link} to="/job-proposal" w="300px" size="lg">
                            Apply Now
                        </Button>
                    </ButtonGroup>
                </CardBody>
            </Card>
            <Footer />
        </>
    );
}

function ProjectUtils({ label, value }: { label: string; value: string }) {
    return (
        <Flex
            bg="bgGray"
            gap="16px"
            p="20px 24px"
            minW="220px"
            maxW="max-content"
            w="100%"
            align="center"
        >
            <ProjectIcon />
            <Box>
                <Text variant="secondary" fontSize="14px">
                    {label}
                </Text>
                <Text color="heading">{value}</Text>
            </Box>
        </Flex>
    );
}
