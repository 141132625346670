import React from 'react';
import { Box, Container, Image, Heading, Center, Card, Text, IconButton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { MetamaskIcon, WalletConnectIcon } from 'assets/icons/user';
import { ChevronRightIcon } from '@chakra-ui/icons';

export default function ConnectWallet() {
    return (
        <Box minH="100vh" pb="90px">
            <Container maxW="1280px" pt="25px">
                <Link to="/">
                    <Image src={logo} />
                </Link>
                <Center minH="calc(100vh - 140px)" flexDirection="column">
                    <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                        Connect your crypto wallet
                    </Heading>
                    <Card p="16px" flexDirection="row" gap="16px" align="center" mt="64px">
                        <MetamaskIcon />
                        <Text>Continue with metamask</Text>
                        <IconButton
                            ml="70px"
                            aria-label="connect-btn"
                            icon={<ChevronRightIcon fontSize="26px" />}
                        />
                    </Card>
                    <Card p="16px" flexDirection="row" gap="16px" align="center" mt="16px">
                        <WalletConnectIcon />
                        <Text>Continue with Walletconnect</Text>
                        <IconButton
                            ml="40px"
                            aria-label="connect-btn"
                            icon={<ChevronRightIcon fontSize="26px" />}
                        />
                    </Card>
                </Center>
            </Container>
        </Box>
    );
}
