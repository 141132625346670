import React from 'react';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    Flex,
    PopoverBody,
    PopoverArrow,
    Portal,
    Button,
    Heading,
    Divider,
    Text,
    Box,
} from '@chakra-ui/react';
import { InvitationIcon, NotificationIcon, PaymentIcon, WorkLogIcon } from 'assets/icons';
import { Link } from 'react-router-dom';

export default function NotificationPopUp() {
    return (
        <Popover placement="bottom-start">
            <PopoverTrigger>
                <Button variant="unstyled">
                    <NotificationIcon />
                </Button>
            </PopoverTrigger>
            <Portal>
                <PopoverContent maxW="363px" w="100%">
                    <PopoverArrow />
                    <Flex p="16px" justify="space-between">
                        <Heading fontSize="20px" as="h6" fontWeight="500">
                            Notifications
                        </Heading>
                        <Button variant="link" fontSize="13px" fontWeight="400">
                            Mark all as read
                        </Button>
                    </Flex>
                    <PopoverBody p="0">
                        <Text mx="16px" mb="16px" fontSize="13px">
                            <Divider mb="16px" borderColor="border" />
                            Today
                        </Text>
                        <Notification
                            isNew
                            icon={<InvitationIcon />}
                            title="Invitation"
                            time="3 min"
                            text='You have received an invitation to interview for the job "Senior Product/UX Designer". '
                        />
                        <Notification
                            icon={<WorkLogIcon />}
                            title="Weekly Log"
                            time="11:52 AM"
                            text="The work week has ended, and your in time is weekly log is available for review."
                        />
                        <Notification
                            icon={<PaymentIcon />}
                            title="Payment"
                            time="08:00 AM"
                            text="You requested payment of USDT 14.00 from StopTalent."
                        />
                        <Text mx="16px" mb="16px" fontSize="13px">
                            Yesterday
                        </Text>
                        <Notification
                            icon={<InvitationIcon />}
                            title="Invitation"
                            time="3 min"
                            text='You have received an invitation to interview for the job "Senior Product/UX Designer". '
                        />
                        <Notification
                            icon={<WorkLogIcon />}
                            title="Weekly Log"
                            time="11:52 AM"
                            text="The work week has ended, and your in time is weekly log is available for review."
                        />
                    </PopoverBody>
                    <Button as={Link} to="/notifications" borderRadius="0 0 8px 8px">
                        View all
                    </Button>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}

function Notification({
    icon,
    title,
    text,
    time,
    isNew,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    title: string;
    text: string;
    time: string;
    isNew?: boolean;
}) {
    return (
        <Flex p="14px" bg={isNew ? 'primary.50' : ''} gap="12px">
            <Box>{icon}</Box>
            <Box>
                <Flex justify="space-between">
                    <Heading as="h6" fontSize="16px">
                        {title}
                    </Heading>
                    <Text variant="black" fontSize="10px">
                        {time}
                    </Text>
                </Flex>
                <Text mt="4px" fontSize="13px">
                    {text}
                </Text>
            </Box>
        </Flex>
    );
}
