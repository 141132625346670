import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Landing from 'views/landing';
import PrivateRoute from './privateRoute';
import ProjectCatelog from 'views/projectCatelog';
import JobListing from 'views/jobListing';
import JobDetail from 'views/jobDetail';
import JobProposal from 'views/jobProposal';
import Notifications from 'views/notifications';
import Profile from 'views/profile';
import Messages from 'views/messages';
import MyStats from 'views/myStats';
import HelpCenter from 'views/help';
import Article from 'views/article';
import SignUp from 'views/signUp';
import AddWallet from 'views/addWallet';
import UserInfo from 'views/userInfo';
import MyReports from 'views/myReports';
import WithdrawalRequest from 'views/withdrawalRequest';
import MyJobs from 'views/myJobs';
import MyJobDetail from 'views/myJobDetail';
import MyProposals from 'views/proposals';
import MyProposalDetail from 'views/myProposalDetail';
import ContactUs from 'views/contactUs';
import GiveRefund from 'views/giveRefund';
import SignIn from 'views/signIn';
import ForgotPassword from 'views/forgotPassword';
import JobPosting from 'views/jobPosting';
import ClientJobListing from 'views/clientJobListing';
import ClintJobDetail from 'views/clientJobDetail';
import ProposalDetail from 'views/proposalDetail';
import ClientProfile from 'views/clientProfile';
import ConnectWallet from 'views/connectWallet';

const routes = [
    {
        path: '/',
        Component: Landing,
    },
    {
        path: '/sign-in',
        Component: SignIn,
    },
    {
        path: '/forgot-password',
        Component: ForgotPassword,
    },
    {
        path: '/project-catalog',
        Component: ProjectCatelog,
    },
    {
        path: '/job-listing',
        Component: JobListing,
    },
    {
        path: '/job-detail',
        Component: JobDetail,
    },
    {
        path: '/job-proposal',
        Component: JobProposal,
    },
    {
        path: '/notifications',
        Component: Notifications,
        isPrivate: true,
    },
    {
        path: '/profile',
        Component: Profile,
        isPrivate: true,
    },
    {
        path: '/inbox',
        Component: Messages,
    },
    {
        path: '/my-jobs',
        Component: MyJobs,
        isPrivate: true,
    },
    {
        path: '/my-reports',
        Component: MyReports,
        isPrivate: true,
    },
    {
        path: '/help',
        Component: HelpCenter,
    },
    {
        path: '/article',
        Component: Article,
    },
    {
        path: '/sign-up',
        Component: SignUp,
    },
    {
        path: '/add-wallet',
        Component: AddWallet,
        isPrivate: true,
    },
    {
        path: '/add-info',
        Component: UserInfo,
        isPrivate: true,
    },
    {
        path: '/connect-wallet',
        Component: ConnectWallet,
        isPrivate: true,
    },
    {
        path: '/my-stats',
        Component: MyStats,
        isPrivate: true,
    },
    {
        path: '/withdrawal-request',
        Component: WithdrawalRequest,
        isPrivate: true,
    },
    {
        path: '/my-job-detail',
        Component: MyJobDetail,
        isPrivate: true,
    },
    {
        path: '/my-proposals',
        Component: MyProposals,
        isPrivate: true,
    },
    {
        path: '/my-proposal-detail',
        Component: MyProposalDetail,
        isPrivate: true,
    },
    {
        path: '/give-refund',
        Component: GiveRefund,
        isPrivate: true,
    },
    {
        path: '/contact-us',
        Component: ContactUs,
    },
    {
        path: '/post-job',
        Component: JobPosting,
    },
    {
        path: '/client-job-listing',
        Component: ClientJobListing,
    },
    {
        path: '/client-job-detail',
        Component: ClintJobDetail,
    },
    {
        path: '/proposal-detail',
        Component: ProposalDetail,
    },
    {
        path: '/client-profile',
        Component: ClientProfile,
    },
];

export default function AppRoutes() {
    return (
        <Routes>
            {routes?.map(({ path, Component, isPrivate }) => (
                <Route
                    key={path}
                    element={isPrivate ? <PrivateRoute component={<Component />} /> : <Component />}
                    path={path}
                />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}
