import React from 'react';
import {
    As,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Container,
    Divider,
    Flex,
    Heading,
    Highlight,
    IconButton,
    Image,
    List,
    ListIcon,
    ListItem,
    Text,
} from '@chakra-ui/react';
import Header from 'components/header';
import PatternBg from 'assets/images/pattern-bg.png';
import BannerImg from 'assets/images/banner-img.png';
import HowItWorks from 'assets/images/how-it-works.png';
import SecurePay from 'assets/images/secure-pay-img.png';
import MobileApp from 'assets/images/mobile-app.png';
import {
    AppleStoreIcon,
    BidIcon,
    BlockChainIcon,
    CopyIcon,
    ExternalLinkIcon,
    PlayStoreIcon,
    QuoteIcon,
    RegisterIcon,
    WorkWithIcon,
} from 'assets/icons';
import Footer from 'components/footer';
import Slider from 'react-slick';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import SearchInput from 'components/searchInput';
import { Link } from 'react-router-dom';

export default function Landing() {
    const settings = {
        className: 'center',
        centerMode: true,
        infinite: true,
        centerPadding: '0px',
        slidesToShow: 3,
        speed: 500,
        dots: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    return (
        <Box>
            <Header />
            <Box as="section" py="100px" bg={`url(${PatternBg}) no-repeat bottom`}>
                <Container maxW="1080px" textAlign="center">
                    <Heading fontSize="67px">
                        <Highlight
                            query="Unlock Your Potential;"
                            styles={{ color: 'var(--chakra-colors-primary-500)' }}
                        >
                            Unlock Your Potential; Find Your Dream Projects & Get Hired
                        </Highlight>
                    </Heading>
                    <Text my="40px">
                        Explore limitless freelancing possibilities and connect with exceptional
                        talent.
                    </Text>
                    <Image src={BannerImg} />
                </Container>
            </Box>
            <Box as="section" bg="primary.50" py="100px">
                <Container maxW="1280px">
                    <Heading fontSize="40px" maxW="434px" mx="auto" textAlign="center">
                        <Highlight
                            query="Some Popular services"
                            styles={{ color: 'var(--chakra-colors-primary-500)' }}
                        >
                            Some Popular services provided for You
                        </Highlight>
                    </Heading>
                    <Flex gap="15px" wrap="wrap" mt="65px">
                        {[...Array(8)]?.map(item => (
                            <Card minW="280px" flex="1" p="15px" key={item}>
                                <BlockChainIcon />
                                <Heading as="h6" fontSize="20px" my="20px">
                                    Blockchain
                                </Heading>
                                <Text>
                                    Securely decentralize transactions, streamline processes, and
                                    enable transparent data management with revolutionary
                                    distributed ledger technology.
                                </Text>
                                <Box ml="auto" mt="25px">
                                    <ExternalLinkIcon />
                                </Box>
                            </Card>
                        ))}
                    </Flex>
                </Container>
            </Box>
            <Box as="section" py="100px">
                <Container maxW="1280px">
                    <Flex align="center" gap="50px" justify="center">
                        <Box maxW="490px">
                            <Heading fontSize="40px">
                                <Highlight
                                    query="How it"
                                    styles={{ color: 'var(--chakra-colors-primary-500)' }}
                                >
                                    How it works?
                                </Highlight>
                            </Heading>
                            <Text my="30px">
                                Register for free, connect with skilled professionals, and achieve
                                exceptional results through seamless collaboration on our platform.
                            </Text>
                            <List spacing="30px">
                                <WorkSteps
                                    icon={RegisterIcon}
                                    title="Register for free"
                                    disc="Join our platform and connect with talented
                                            professionals."
                                />
                                <WorkSteps
                                    icon={BidIcon}
                                    title="Bid or post a job"
                                    disc="Showcase your skills or find the perfect project by posting or bidding on jobs."
                                />
                                <WorkSteps
                                    icon={WorkWithIcon}
                                    title="Work with best"
                                    disc="Collaborate with top-notch professionals and achieve exceptional results."
                                />
                            </List>
                            <ButtonGroup mt="30px">
                                <Button size="lg">Sign up for free</Button>
                                <Button size="lg" variant="secondary">
                                    Learn how to hire or bid
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <Image maxW="100%" src={HowItWorks} />
                    </Flex>
                </Container>
            </Box>
            <Box as="section" bg="bgGray" py="100px">
                <Container maxW="1280px">
                    <Heading fontSize="40px" maxW="436px" mx="auto" textAlign="center">
                        <Highlight
                            query="Looking for job?"
                            styles={{ color: 'var(--chakra-colors-primary-500)' }}
                        >
                            Looking for job? Browse now
                        </Highlight>
                    </Heading>
                    <Box maxW="880px" mt="60px" mx="auto">
                        <SearchInput />
                    </Box>
                    <Flex gap="15px" wrap="wrap" mt="65px">
                        {[...Array(6)]?.map(item => (
                            <JobCategory key={item} />
                        ))}
                    </Flex>
                </Container>
            </Box>
            <Box as="section" py="100px">
                <Container maxW="1280px">
                    <Flex gap="70px" justify="center">
                        <Image maxW="100%" src={SecurePay} />
                        <Box maxW="540px">
                            <Heading fontSize="40px">
                                <Highlight
                                    query="Secure and Convenient"
                                    styles={{ color: 'var(--chakra-colors-primary-500)' }}
                                >
                                    Secure and Convenient Payment Options
                                </Highlight>
                            </Heading>
                            <Text mt="30px" maxW="540px">
                                Secure & Hassle-free Payments: At Stoptalent, we prioritize seamless
                                payments for freelancers and clients. Our reliable and transparent
                                payment system is based on the USDT chain, ensuring stability and
                                efficiency. Enjoy the convenience of USDT, a digital asset pegged to
                                the US Dollar. We charge a nominal 1% fee on transactions from both
                                service providers and clients, supporting platform maintenance,
                                service improvements, and our vibrant community.
                            </Text>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            <Box as="section" bg="bgGray" py="100px">
                <Container maxW="1180px">
                    <Heading fontSize="40px" maxW="436px" mx="auto" textAlign="center">
                        <Highlight
                            query="Featured"
                            styles={{ color: 'var(--chakra-colors-primary-500)' }}
                        >
                            Featured Projects
                        </Highlight>
                    </Heading>
                    <Flex gap="15px" wrap="wrap" mt="65px">
                        {[...Array(3)]?.map(item => (
                            <FeaturedProject key={item} />
                        ))}
                    </Flex>
                </Container>
            </Box>
            <Box as="section" py="100px">
                <Container maxW="1086px">
                    <Box bg="primary.500" borderRadius="16px" p="65px 60px" position="relative">
                        <Box maxW="466px">
                            <Heading as="h3" fontSize="40px" color="white">
                                Download our mobile application
                            </Heading>
                            <Text variant="primary" my="10px">
                                Take your job search on the go. Download our mobile application for
                                Android and iOS devices!
                            </Text>
                            <Flex gap="15px">
                                <AppleStoreIcon />
                                <PlayStoreIcon />
                            </Flex>
                        </Box>
                        <Image
                            maxW="100%"
                            right="0"
                            top="-90px"
                            position="absolute"
                            src={MobileApp}
                        />
                    </Box>
                </Container>
            </Box>
            <Box as="section" py="100px">
                <Container maxW="1280px">
                    <Heading fontSize="40px" maxW="636px">
                        <Highlight
                            query="Hear from our satisfied clients"
                            styles={{ color: 'var(--chakra-colors-primary-500)' }}
                        >
                            Hear from our satisfied clients & talented professionals
                        </Highlight>
                    </Heading>
                    <Box
                        as={Slider}
                        {...settings}
                        sx={{
                            '.slick-center .slide': {
                                bg: 'primary.500',
                                p: { color: 'white' },
                                'svg path': { fill: '#57A3FF' },
                            },
                        }}
                        py="64px"
                    >
                        <ClientReview />
                        <ClientReview />
                        <ClientReview />
                        <ClientReview />
                        <ClientReview />
                    </Box>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
}

export function WorkSteps({ title, disc, icon }: { title: string; disc: string; icon: As }) {
    return (
        <ListItem as={Flex} gap="16px">
            <ListIcon as={icon} />
            <Box flex="1">
                <Heading as="h6" fontSize="25px">
                    {title}
                </Heading>
                <Text>{disc}</Text>
            </Box>
        </ListItem>
    );
}

function JobCategory() {
    return (
        <Card as={Link} to="/job-listing" minW="360px" flex="1" p="24px">
            <Heading as="h6" fontSize="20px">
                Software Development
            </Heading>
            <Text mt="24px">
                Building robust and scalable software solutions tailored to specific business needs,
                leveraging cutting-edge technologies for efficient development and deployment.
            </Text>
            <Flex mt=" 30px" justify="space-between">
                <Flex align="center" gap="7px">
                    <AvatarGroup size="xs" max={3}>
                        <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                        <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                        <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                    </AvatarGroup>
                    <Text fontSize="13px">25K+ Talent</Text>
                </Flex>
                <Flex align="center" gap="7px">
                    <CopyIcon />
                    <Text fontSize="13px">350+ Jobs</Text>
                </Flex>
            </Flex>
            <Button w="100px" mt=" 25px">
                Explore
            </Button>
        </Card>
    );
}

function FeaturedProject() {
    return (
        <Card minW="360px" flex="1" p="12px">
            <Image
                rounded="16px"
                objectFit="cover"
                maxW="100%"
                src="https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Caffe Latte"
            />
            <CardBody p="0 10px">
                <Heading as="h6" textAlign="center" fontSize="20px" my="25px">
                    A well-written press release for your crypto/blockchain/fintech...
                </Heading>
                <Flex align="center" justify="space-between">
                    <Box>
                        <Text>From</Text>
                        <Flex gap="7px">
                            <Avatar size="xs" name="USDT" />
                            <Text>
                                87.23{' '}
                                <Text as="span" fontSize="10px">
                                    USDT
                                </Text>
                            </Text>
                        </Flex>
                    </Box>
                    <Box>
                        <Text>Delivery Time</Text>
                        <Text fontSize="12px">3 Days Delivery</Text>
                    </Box>
                </Flex>
                <Divider my="30px" borderColor="border" />
                <Flex align="center" justify="space-between">
                    <Flex gap="7px">
                        <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                        <Text fontWeight="600">Brooklyn Simmons</Text>
                    </Flex>
                    <Box>
                        <Text>Ratings</Text>
                        <Text>4.5/5.0</Text>
                    </Box>
                </Flex>
            </CardBody>
        </Card>
    );
}

function ClientReview() {
    return (
        <Box px="10px">
            <Flex gap="24px" bg="bgGray" rounded="18px" p="24px" className="slide">
                <QuoteIcon />
                <Text flex="1">
                    I found my dream job through this platform. The seamless process and wide range
                    of job opportunities were impressive.
                </Text>
            </Flex>
            <Box
                mt="94px"
                mx="auto"
                bg="bgGray"
                rounded="18px"
                p="16px"
                maxW="197px"
                textAlign="center"
                className="slide"
            >
                <Text fontSize="20px" fontWeight="500">
                    Sarah D
                </Text>
                <Text mt="12px" fontSize="13px">
                    Product Manager
                </Text>
            </Box>
        </Box>
    );
}

function SampleNextArrow(props: any) {
    const { onClick } = props;
    return (
        <IconButton
            aria-label="next"
            rounded="full"
            position="absolute"
            borderColor="#22A7FA"
            size="sm"
            right={{ base: '0', md: '35px' }}
            top="0%"
            variant="outline"
            onClick={onClick}
        >
            <ChevronRightIcon fontSize="25px" color="#22A7FA" />
        </IconButton>
    );
}

function SamplePrevArrow(props: any) {
    const { onClick } = props;

    return (
        <IconButton
            aria-label="back"
            rounded="full"
            position="absolute"
            borderColor="#22A7FA"
            variant="outline"
            size="sm"
            left="unset"
            right="78px"
            top="0%"
            onClick={onClick}
        >
            <ChevronLeftIcon fontSize="25px" color="#22A7FA" />
        </IconButton>
    );
}
