import React from 'react';
import {
    Card,
    Container,
    Text,
    Heading,
    Divider,
    Button,
    Textarea,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Input,
    ButtonGroup,
    InputGroup,
    InputRightAddon,
    Box,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';

export default function GiveRefund() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="50px">
                <Card p="24px">
                    <Heading as="h1" fontSize="32px">
                        Give a Refund
                    </Heading>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h2" fontSize="20px">
                        Consultancy for solidity smart contract on Polygon
                    </Heading>
                    <Text mt="14px">
                        Micheal Pardos - Need full time team for building various blockchain
                        projects.
                    </Text>
                    <FormControl w="300px" mt="40px">
                        <FormLabel>Invoice(s)</FormLabel>
                        <Select placeholder="Select Invoice" size="lg" />
                    </FormControl>
                    <Divider my="40px" borderColor="border" />
                    <FormControl maxW="550px">
                        <FormLabel>Refund Amount</FormLabel>
                        <InputGroup size="lg">
                            <Input defaultValue="200.30" variant="primary" borderRight="none" />
                            <InputRightAddon
                                bg="transparent"
                                color="primary.500"
                                borderColor="border"
                            >
                                USDT
                            </InputRightAddon>
                        </InputGroup>
                    </FormControl>
                    <Flex justify="space-between" maxW="550px" my="40px">
                        <Text as="span" variant="black" fontSize="20px">
                            Current Balance
                        </Text>
                        <Text as="span">
                            <Text as="strong" fontSize="20px" variant="black">
                                300.00
                            </Text>{' '}
                            USDT
                        </Text>
                    </Flex>
                    <Flex justify="space-between" maxW="550px">
                        <Box>
                            <Text as="span" variant="black" fontSize="20px">
                                Your balance will be debited
                            </Text>
                            <Text>(Includes credit for Stoptalent)</Text>
                        </Box>
                        <Text as="span">
                            <Text as="strong" fontSize="20px" variant="black">
                                300.00
                            </Text>{' '}
                            USDT
                        </Text>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Text as="label">Notes to Client</Text>
                    <Textarea mt="14px" placeholder="Type here" variant="primary" minH="200px" />
                    <Divider my="40px" borderColor="border" />
                    <Text as="label">Notes to Self (Optional)</Text>
                    <Textarea mt="14px" placeholder="Type here" variant="primary" minH="200px" />
                    <ButtonGroup mt="32px" ml="auto">
                        <Button size="lg" w="160px" variant="light">
                            Cancel
                        </Button>
                        <Button size="lg" w="160px">
                            Refund
                        </Button>
                    </ButtonGroup>
                </Card>
            </Container>
            <Footer />
        </>
    );
}
