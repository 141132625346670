import {
    Card,
    Container,
    Flex,
    Avatar,
    Box,
    Text,
    Heading,
    Divider,
    Button,
    Center,
    Grid,
    Modal,
    useDisclosure,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Textarea,
    ModalFooter,
    ButtonGroup,
    Select,
    VStack,
    AvatarGroup,
} from '@chakra-ui/react';
import { StarRatingFillIcon } from 'assets/icons';
import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';
import {
    CalenderIcon,
    HiredBudgetIcon,
    HiredByIcon,
    HiredTimeLineIcon,
    JobHiredIcon,
    ChatIcon,
    TopRatedIcon,
} from 'assets/icons/user';
import { Link } from 'react-router-dom';

export default function ClintJobDetail() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="50px">
                <Card p="24px">
                    <Heading as="h4" fontSize="32px">
                        Consultancy for solidity smart contract on Polygon
                    </Heading>
                    <Flex gap="16px" as={Text} align="center" my="40px">
                        <Text
                            as="div"
                            variant="primary"
                            bg="primary.500"
                            p="10px"
                            w="126px"
                            rounded="8px"
                            textAlign="center"
                        >
                            completed
                        </Text>
                        <StarRatingFillIcon />
                        <StarRatingFillIcon />
                        <StarRatingFillIcon />
                        <StarRatingFillIcon />
                        <StarRatingFillIcon />
                    </Flex>
                    <Text as="h6" variant="black" fontSize="20px">
                        Description
                    </Text>
                    <Button
                        variant="link"
                        as="a"
                        href="#"
                        fontSize="14px"
                        my="24px"
                        justifyContent="flex-start"
                    >
                        https://docs.google.com/spreadsheetsd/1AKcUOF24/sadai13-IMIxMIYedit?usp
                        sharing
                    </Button>
                    <Text>
                        Lorem ipsum dolor sit amet consectetur. Quisque proin varius elit volutpat
                        odio posuere. In nullam bibendum blandit platea dictum arcu. Odio sit ipsum
                        lacus vulputate lacus amet eget ullamcorper. Neque mattis sed dictum dolor
                        amet. Quis tempus aliquam posuere sapien blandit maecenas arcu. Duis non
                        quisque sit vel ut amet leo netus elit. Interdum mollis praesent eu ut elit
                        facilisi. Eget sit sollicitudin purus quis semper a in. Condimentum viverra
                        mi euismod vitae. Mi feugiat maecenas lorem ornare ut mi faucibus nunc
                        dictum. Sit sed nibh ut elit lectus suspendisse.
                        <br />
                        <br />
                        Gravida phasellus dolor ipsum egestas consequat mattis. Et eget a gravida
                        orci egestas neque magna. Est scelerisque ullamcorper nec congue in tellus.
                        Suscipit nibh sagittis egestas at. Morbi proin dictum commodo at eget eget
                        non. Nisl nulla aliquet proin integer amet facilisi feugiat placerat. At
                        morbi blandit odio vitae sagittis volutpat eros leo. Dictum vitae vel sit
                        ultricies. Non non tellus sit pharetra duis etiam. Eget lacus quis pretium
                        posuere morbi lobortis ultrices. Integer eros enim facilisis ut urna. Diam
                        fusce platea tortor dolor arcu. Ultrices massa semper nisi quam netus nunc
                        purus augue non. Semper pulvinar urna risus at libero. Tellus eget etiam
                        risus orci quam.
                    </Text>
                    <Flex mt="32px" gap="24px">
                        <ContractUtils
                            icon={<JobHiredIcon />}
                            label="Job Category"
                            value="Full Stack Development"
                        />
                        <ContractUtils
                            icon={<HiredBudgetIcon />}
                            label="Budget"
                            value={
                                <>
                                    <Text fontSize="22px" as="strong">
                                        200.30
                                    </Text>{' '}
                                    USDT
                                </>
                            }
                        />
                        <ContractUtils
                            icon={<HiredByIcon />}
                            label="Poprposals"
                            value={
                                <Flex gap="10px">
                                    +15
                                    <AvatarGroup max={3} size="sm">
                                        <Avatar />
                                        <Avatar />
                                        <Avatar />
                                        <Avatar />
                                        <Avatar />
                                    </AvatarGroup>
                                </Flex>
                            }
                        />
                        <ContractUtils
                            icon={<HiredTimeLineIcon />}
                            label="Deadline"
                            value="08-01-2023 - 13-06-2023"
                        />
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="32px">
                        Milestones Timeline
                    </Heading>
                    <VStack
                        sx={{ ' >div:first-child': { p: 0 } }}
                        mt="40px"
                        flexDirection="column-reverse"
                        spacing="0"
                    >
                        {[...Array(3)]?.map((item, i) => (
                            <Flex
                                pb="35px"
                                key={item}
                                align="center"
                                gap="40px"
                                position="relative"
                                _after={{
                                    content: `''`,
                                    position: 'absolute',
                                    h: '100%',
                                    border: '1px dashed black',
                                    top: 0,
                                    left: '25px',
                                    zIndex: 0,
                                    borderColor: 'border',
                                }}
                            >
                                <Avatar
                                    name={(i + 1)?.toString()}
                                    zIndex="2"
                                    bg="white"
                                    border="2px solid"
                                    borderColor="text"
                                    color="text"
                                />
                                <Card flex="1" p="24px">
                                    <Flex justify="space-between">
                                        <Text as="h6" fontSize="25px" variant="black">
                                            #0{i + 1} Milestone
                                        </Text>
                                        <Text>
                                            <CalenderIcon /> Due on:{' '}
                                            <Text as="span" variant="secondary">
                                                25-05-2023
                                            </Text>
                                        </Text>
                                    </Flex>
                                    <Flex gap="16px" as={Text} align="center" my="25px">
                                        <Text>
                                            <Text as="strong" fontSize="20px">
                                                2000.30
                                            </Text>{' '}
                                            USDT
                                        </Text>
                                        <Text
                                            as="div"
                                            variant="primary"
                                            bg="primary.500"
                                            p="8px"
                                            w="96px"
                                            rounded="8px"
                                            textAlign="center"
                                        >
                                            Paid
                                        </Text>
                                    </Flex>
                                    <Text>
                                        Lorem ipsum dolor sit amet consectetur. Libero quisque
                                        vulputate convallis mauris a amet et. Elit diam ultrices
                                        massa in elit nec auctor et in. Suspendisse consectetur
                                        fermentum fringilla hac est commodo morbi sit mattis. Fusce
                                        sed sit donec facilisis feugiat. Porta amet at sed lorem
                                        tristique nulla vitae ullamcorper. Vulputate magnis orci
                                        nunc nunc. Ligula viverra quam vel fermentum fermentum eu
                                        leo. Scelerisque sagittis a suspendisse et egestas morbi
                                        tincidunt vitae nunc. Arcu elit pharetra elementum arcu
                                        vitae viverra. Quisque pellentesque et euismod imperdiet
                                        donec sed. Risus in odio integer posuere nibh. Sit augue
                                        elementum non et. Tincidunt nisl accumsan tempor mollis
                                        viverra. Tristique rhoncus sem enim ipsum enim.
                                    </Text>
                                </Card>
                            </Flex>
                        ))}
                    </VStack>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h5" fontSize="20px" mb="40px">
                        Top Porposals
                    </Heading>
                    <Card p="24px" mb="24px" as={Link} to="/proposal-detail">
                        <Flex justify="space-between" align="center">
                            <Flex gap="24px">
                                <Avatar w="56px" h="56px" />
                                <Box>
                                    <Text fontSize="25px" variant="secondary">
                                        Jenny Wilson
                                    </Text>
                                    <Text>Blockchain Developer | Backend Developer</Text>
                                </Box>
                            </Flex>
                            <ButtonGroup>
                                <Button ml="auto" leftIcon={<ChatIcon />}>
                                    Chat Now
                                </Button>
                                <Button variant="light">Award Project</Button>
                            </ButtonGroup>
                        </Flex>
                        <Flex gap="30px" my="28px" align="center">
                            <Flex
                                bg="#F4B000"
                                alignItems="center"
                                gap="10px"
                                p="8px 15px"
                                rounded="4px"
                            >
                                <TopRatedIcon />
                                <Text fontSize="12px" variant="primary">
                                    Top Rated Plus
                                </Text>
                            </Flex>
                            <Flex gap="8px" as={Text} align="center">
                                <StarRatingFillIcon /> 4.92 (230 Reviews)
                            </Flex>
                        </Flex>
                        <Heading as="h5" fontSize="20px">
                            Cover Letter
                        </Heading>
                        <Text mt="10px">
                            Lorem ipsum dolor sit amet consectetur. Tempus quis odio ultrices
                            consequat molestie tristique. Eget eu tempus ornare commodo in. Sed
                            tortor urna tortor auctor. Neque amet sed malesuada mus suscipit. Libero
                            curabitur massa facilisis metus. Volutpat volutpat dui odio diam eu diam
                            quis lectus morbi. Consequat nunc ut ornare fusce pretium et tortor
                            pulvinar. Ultrices amet auctor eu mi porta dictum.
                        </Text>
                        <Text mt="28px" variant="secondary">
                            Matched because they had previously collaborated on 10 similar projects.
                        </Text>
                    </Card>
                    <Button mx="auto" size="lg">
                        View All Porposal
                    </Button>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h5" fontSize="20px">
                        Recent Activities & Reviews
                    </Heading>
                    <Flex gap="16px" mt="40px">
                        <Grid
                            gap="16px"
                            gridTemplateColumns="120px 1fr"
                            flex="1"
                            alignContent="flex-start"
                        >
                            <Text variant="black" as="span">
                                Date
                            </Text>
                            <Text variant="black" as="span">
                                Discription
                            </Text>
                            {[...Array(4)]?.map(item => (
                                <React.Fragment key={item}>
                                    <Center
                                        flexDirection="column"
                                        rounded="8px"
                                        bg="bgGray"
                                        p="8px"
                                        minW="120px"
                                        minH="113px"
                                    >
                                        <Text as="strong" fontSize="31px">
                                            25
                                        </Text>
                                        <Text as="span" fontSize="13px">
                                            June, 25
                                        </Text>
                                    </Center>
                                    <Card p="16px">
                                        <Text variant="black" fontSize="20px">
                                            Leo gave you feedback
                                        </Text>
                                        <Flex gap="8px" my="12px">
                                            <StarRatingFillIcon />
                                            <StarRatingFillIcon />
                                            <StarRatingFillIcon />
                                            <StarRatingFillIcon />
                                            <StarRatingFillIcon />
                                        </Flex>
                                        <Text as="i">
                                            “Excellent work! I am impressed with the skills he has.”
                                        </Text>
                                    </Card>
                                </React.Fragment>
                            ))}
                        </Grid>
                        <Box
                            borderLeft="1px solid transparent"
                            borderColor="border"
                            mt="40px"
                            pl="16px"
                            maxW="550px"
                        >
                            <Center as={Card} p="16px" textAlign="center" h="calc(50% - 8px)">
                                <Text variant="black" fontSize="20px">
                                    Talent’s feedback to you
                                </Text>
                                <Flex gap="8px" my="12px" justify="center">
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                </Flex>
                                <Text as="i">
                                    “Excellent work! I am impressed with the skills he has.”
                                </Text>
                                <FeedbackModal />
                            </Center>
                            <Center
                                as={Card}
                                p="16px"
                                textAlign="center"
                                mt="16px"
                                h="calc(50% - 8px)"
                            >
                                <Text variant="black" fontSize="20px">
                                    Your’s feedback to client
                                </Text>
                                <Flex gap="8px" my="12px" justify="center">
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                </Flex>
                                <Text as="i">
                                    “Excellent work! I am impressed with the skills he has.”
                                </Text>
                            </Center>
                        </Box>
                    </Flex>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ContractUtils({
    icon,
    label,
    value,
    isEdit,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
}) {
    return (
        <Card
            as={Flex}
            flexDirection="row"
            maxW="300px"
            minW="max-content"
            p="24px 16px"
            align="center"
            gap="20px"
            w="100%"
        >
            {icon}
            <Box>
                <Text display="block" as="span" variant="secondary" fontSize="20px">
                    {label}
                </Text>
                <Text as="span">{value}</Text>
            </Box>
            {isEdit}
        </Card>
    );
}

function FeedbackModal() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
        <>
            <Button variant="light" size="lg" onClick={onOpen} mt="30px">
                Respond to the feedback
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        About
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text as="label">Project Status</Text>
                        <Select mt="14px" value="Completed" />
                        <Box
                            my="32px"
                            rounded="8px"
                            border="1px solid transparent"
                            borderColor="border"
                            p="16px"
                        >
                            <Text as="strong" variant="black" fontSize="20px">
                                Ratings
                            </Text>
                            <Divider my="24px" borderColor="border" />
                            <Flex justify="space-between" align="center">
                                <Text as="span">Communication</Text>
                                <Flex gap="8px" justify="center">
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon outline />
                                    <StarRatingFillIcon outline />
                                    <Text>3.0/5.0</Text>
                                </Flex>
                            </Flex>
                            <Flex justify="space-between" align="center" mt="24px">
                                <Text as="span">Commitment</Text>
                                <Flex gap="8px" justify="center">
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon outline />
                                    <StarRatingFillIcon outline />
                                    <Text>3.0/5.0</Text>
                                </Flex>
                            </Flex>
                            <Divider my="24px" borderColor="border" />
                            <Flex justify="space-between" align="center">
                                <Text as="span">Total Ratings</Text>
                                <Flex gap="8px" justify="center">
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon />
                                    <StarRatingFillIcon outline />
                                    <StarRatingFillIcon outline />
                                    <Text>3.0/5.0</Text>
                                </Flex>
                            </Flex>
                        </Box>
                        <Text as="label">Add Review</Text>
                        <Textarea
                            mt="14px"
                            placeholder="Type here"
                            variant="primary"
                            minH="180px"
                        />
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg">
                                Cancel
                            </Button>
                            <Button size="lg">Upload Review</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
