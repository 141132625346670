import React from 'react';
import Header from 'components/header';
import { Container, Card, CardBody, Heading, Text, Flex, Icon, Box } from '@chakra-ui/react';
import Footer from 'components/footer';
import Banner from 'components/banner';
import { DisLikeBtn, FavBtn, StarRatingFillIcon } from 'assets/icons';
import { Link } from 'react-router-dom';

export default function JobListing() {
    return (
        <>
            <Header />
            <Banner
                navList={['Development & IT', 'Development', 'Blockchain']}
                title="Blockchain, NFT, Cryptocurrency"
                subtitle="Get help with your next blockchain project."
                categories={['Blockchain Development']}
            />
            <Container maxW="1280px" py="60px">
                {[...Array(5)]?.map(item => (
                    <Card key={item} mt="40px">
                        <CardBody p="32px">
                            <Flex gap="10px">
                                <Heading to="/job-detail" as={Link} fontSize="25px">
                                    Build a Minimum Viable responsive NFT auction website with
                                    blockchain integration
                                </Heading>
                                <Flex gap="10px" align="center">
                                    <Text
                                        as="span"
                                        bg="primary.50"
                                        color="primary.500"
                                        p="8px 14px"
                                        borderRadius="4px"
                                        whiteSpace="nowrap"
                                    >
                                        <strong>13</strong> / 100 Connects Required <Icon />
                                    </Text>
                                    <DisLikeBtn />
                                    <FavBtn />
                                </Flex>
                            </Flex>
                            <Text mt="24px" fontSize="13px">
                                <strong>Job Posted On:</strong> May 30, 2023
                            </Text>
                            <Text my="40px">
                                Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit
                                fusce diam tincidunt nibh nibh commodo. Sed in adipiscing blandit
                                nullam. Aliquet orci porta fermentum sed sit pretium. Scelerisque
                                consectetur nisl feugiat quam dictum vulputate porttitor turpis sit.
                                Aliquet dapibus aliquam enim orci elit congue justo pharetra ut.
                                Scelerisque consequat faucibus ultricies at id. Et aenean ut varius
                                risus ultrices mattis elementum morbi luctus. Leo cras ipsum metus
                                imperdiet. Tortor consequat elit etiam felis nunc auctor.
                            </Text>
                            <Flex justify="space-between">
                                <Flex align="center" gap="10px">
                                    <Text as="span" variant="secondary" fontSize="13px">
                                        Skills:
                                    </Text>
                                    {[...Array(4)]?.map(item => (
                                        <Box
                                            borderRadius="28px"
                                            background="rgba(255, 229, 178, 0.60)"
                                            fontSize="13px"
                                            p="8px 16px"
                                            key={item}
                                        >
                                            Blockchain
                                        </Box>
                                    ))}
                                </Flex>
                                <Text color="heading" variant="secondary">
                                    <Text variant="secondary" as="span" fontSize="13px">
                                        Estimated Time:
                                    </Text>{' '}
                                    1 - 3 Months, More than 30 hours/week
                                </Text>
                            </Flex>
                            <Flex justify="space-between" mt="25px" align="flex-end">
                                <Flex align="center" gap="80px">
                                    <Text color="heading" variant="secondary" fontSize="31px">
                                        <Text variant="secondary" as="span" fontSize="13px">
                                            Amount Spent:
                                        </Text>{' '}
                                        35.63{' '}
                                        <Text as="span" fontSize="16px">
                                            USDT
                                        </Text>
                                    </Text>
                                    <Text color="heading" variant="secondary">
                                        <Text variant="secondary" as="span" fontSize="13px">
                                            Proposals:
                                        </Text>{' '}
                                        27+
                                    </Text>
                                </Flex>
                                <Text color="heading" variant="secondary">
                                    <Text variant="secondary" as="span" fontSize="13px">
                                        Reviews:
                                    </Text>{' '}
                                    <Box display="inline-flex" gap="5px" mx="10px">
                                        {[...Array(5)]?.map(item => (
                                            <StarRatingFillIcon key={item} />
                                        ))}
                                    </Box>{' '}
                                    4.8 / 5.0 (263)
                                </Text>
                            </Flex>
                        </CardBody>
                    </Card>
                ))}
            </Container>
            <Footer />
        </>
    );
}
