import React from 'react';
import {
    Box,
    Card,
    Container,
    Divider,
    Flex,
    Heading,
    Icon,
    Progress,
    Select,
    Text,
} from '@chakra-ui/react';
import { AnsweredInviteIcon } from 'assets/icons';
import { TopRatedIcon } from 'assets/icons/user';
import Footer from 'components/footer';
import Header from 'components/header';
import Chart from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts/types/react-apexcharts';

export default function MyStats() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="80px">
                <Card p="24px">
                    <Heading as="h2" fontSize="32px">
                        My Stats
                    </Heading>
                    <Divider borderColor="border" my="40px" />
                    <Heading as="h3" fontSize="20px">
                        Client Satisfaction
                    </Heading>
                    <Flex mt="56px">
                        <Box flex="1">
                            <Heading as="h6" fontSize="16px">
                                My Badges
                            </Heading>
                            <Flex
                                bg="#F4B000"
                                alignItems="center"
                                gap="10px"
                                p="8px 15px"
                                rounded="4px"
                                w="max-content"
                                my="12px"
                            >
                                <TopRatedIcon />
                                <Text fontSize="12px" variant="primary">
                                    Top Rated Plus
                                </Text>
                            </Flex>
                            <Text>Last Updated on June 20, 2023</Text>
                            <Heading as="h6" fontSize="16px" mt="40px">
                                Job Success Score
                            </Heading>
                            <Progress
                                value={80}
                                w="165px"
                                rounded="9px"
                                sx={{ div: { borderRadius: '9px' } }}
                                my="12px"
                            />
                            <Text>80% Job Success Rate</Text>
                        </Box>
                        <Card p="16px" flex="1" sx={{ '&.chakra-card>div:nth-child(1)': { m: 0 } }}>
                            <StatUtils
                                label="12 Months Earnings"
                                value={
                                    <Text as="span">
                                        <strong>1500.73</strong> USDT
                                    </Text>
                                }
                            />
                            <StatUtils
                                label="Clients who would recommend you?"
                                value={
                                    <Text as="span">
                                        <strong>20</strong> %
                                    </Text>
                                }
                            />
                            <StatUtils
                                label="Long-term Clients"
                                value={
                                    <Text as="span">
                                        <strong>10</strong> %
                                    </Text>
                                }
                            />
                            <StatUtils
                                label="Recent Account Holds"
                                value={<Text as="strong">0</Text>}
                            />
                            <StatUtils
                                label="Top Rated Eligible Weeks"
                                value={
                                    <Text as="span">
                                        <strong>16</strong>/ 16
                                    </Text>
                                }
                            />
                        </Card>
                    </Flex>
                    <Divider borderColor="border" my="40px" />
                    <Heading textAlign="center" as="h3" fontSize="20px">
                        Responsiveness in the past 90 days
                    </Heading>
                    <Flex mt="56px" wrap="wrap" justify="space-evenly">
                        <Card p="24px" maxW="332px" gap="15px">
                            <RadialGraph />
                            <Text w="160px" textAlign="center" mx="auto">
                                You respond to 80% of invites
                            </Text>
                        </Card>
                        <Box bg="bgGray" p="24px" maxW="300px">
                            <Text variant="black" textAlign="center" mb="24px">
                                In the past 90 days, you responded to 55 of 60 jobs invites (80%).
                                Nicely done!
                            </Text>
                            <Flex p="16px" bg="white" rounded="8px" gap="6px">
                                <AnsweredInviteIcon />
                                <Text fontSize="10px">
                                    You answered 40 invites within 24 hours.{' '}
                                </Text>
                            </Flex>
                        </Box>
                        <Card p="24px" maxW="332px">
                            <RadialGraph />
                            <Text textAlign="center" w="160px" mx="auto">
                                No initial message in the past 90 days.
                            </Text>
                        </Card>
                    </Flex>
                    <Divider borderColor="border" my="40px" />
                    <Heading as="h3" fontSize="20px">
                        Analytics
                    </Heading>
                    <Text mt="24px">Only you can see this.</Text>
                    <Text mt="24px" variant="black">
                        Show me
                    </Text>
                    <Flex maxW="800px" gap="16px" mt="16px">
                        <Select color="text">
                            <option>Profile Views</option>
                            <option>Proposals</option>
                        </Select>
                        <Select color="text">
                            <option>Last 15 days</option>
                            <option>Last 30 days</option>
                        </Select>
                    </Flex>
                    <Flex mt="64px" align="flex-start">
                        <Box w="100%" maxW="800px">
                            <Text>Profile Views</Text>
                            <Box mt="56px" minH="330px">
                                <ViewsGraph
                                    type="line"
                                    categories={[
                                        'Feb',
                                        'jan',
                                        'Mar',
                                        'Apr',
                                        'May',
                                        'Jun',
                                        'Jul',
                                        'Aug',
                                        'Sep',
                                        'Oct',
                                        'Nov',
                                        'Dec',
                                    ]}
                                    series={[
                                        {
                                            name: 'Views',
                                            data: [30, 40, 25, 30, 50, 39, 30, 52, 47, 55, 20, 40],
                                        },
                                    ]}
                                />
                            </Box>
                        </Box>
                        <Card p="24px" maxW="max-content" w="100%" mx="auto">
                            <Text variant="black" as="span" fontWeight="500" fontSize="25px">
                                15 views
                                <Icon ml="15px" />
                            </Text>
                            <Text mt="40px" fontSize="20px">
                                In the last 7 months
                            </Text>
                            <Text fontSize="12px" mt="24px">
                                <Text as="span" color="darkRed">
                                    -32%
                                </Text>{' '}
                                since previous 7 days
                            </Text>
                        </Card>
                    </Flex>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

// eslint-disable-next-line no-undef
function StatUtils({ label, value }: { label: string; value: JSX.Element }) {
    return (
        <Flex justify="space-between" mt="24px">
            <Text>{label}</Text>
            {value}
        </Flex>
    );
}

function ViewsGraph({
    type,
    categories,
    series,
}: {
    type: ReactApexChart['props']['type'];
    categories: any[];
    series: any[];
}) {
    const options = {
        chart: {
            id: 'basic-bar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        xaxis: {
            categories,
        },
        markers: {
            size: 6,
            colors: '#282828',
        },
        grid: {
            xaxis: {
                lines: {
                    show: true,
                },
            },
            yaxis: {
                lines: {
                    show: true,
                },
            },
            row: {
                colors: undefined,
                opacity: 0.5,
            },
            column: {
                colors: undefined,
                opacity: 0.5,
            },
        },
    };
    return <Chart options={options} series={series} type={type} width="100%" />;
}

function RadialGraph() {
    const options = {
        chart: {
            id: 'radialBar',
            toolbar: {
                show: false,
            },
        },
        colors: ['#2D8CFF'],
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                offsetY: -30,
                track: {
                    background: '#E9E9E9',
                    startAngle: -90,
                    endAngle: 90,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        fontSize: '30px',
                        show: true,
                    },
                },
            },
        },
        labels: ['Progress'],
        stroke: {
            lineCap: 'round',
        },
    };
    return (
        <Chart
            // @ts-ignore
            options={options}
            series={[80]}
            type="radialBar"
            height="280px"
        />
    );
}
