export default {
    global: {
        body: {
            overflowX: 'hidden',
            bg: 'background',
            color: 'text',
            fontSize: '16px',
            fontFamily: 'Metropolis Medium',
        },
    },
};
