import React, { useState } from 'react';
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Radio,
    RadioGroup,
    Text,
} from '@chakra-ui/react';
import { CheckIcon, FreelancerIcon, GroupIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import UserAuth from './signInForm';
import { ArrowLeftIcon } from '@chakra-ui/icons';

export default function SignIn() {
    const [, setUserType] = useState('');
    const [userStep, setUserStep] = useState(1);
    const handleGoBack = () => {
        if (userStep === 2) {
            setUserStep(1);
        }
    };
    return (
        <>
            <Container maxW="1280px" pt="25px">
                <Link to="/">
                    <Image src={logo} />
                </Link>
                {userStep > 1 && (
                    <Button
                        mt="30px"
                        leftIcon={<ArrowLeftIcon />}
                        onClick={handleGoBack}
                        variant="link"
                    >
                        Back
                    </Button>
                )}
            </Container>
            {userStep === 2 ? (
                <UserAuth setUserStep={setUserStep} />
            ) : (
                <ContinueAs setType={setUserType} setUserStep={setUserStep} />
            )}
        </>
    );
}

function ContinueAs({
    setType,
    setUserStep,
}: {
    setType: React.Dispatch<React.SetStateAction<string>>;
    setUserStep: React.Dispatch<React.SetStateAction<number>>;
}) {
    return (
        <Center minH="calc(100vh - 50px)">
            <Box textAlign="center">
                <Heading fontSize="32px">Join as a client or freelancer</Heading>
                <RadioGroup mt="64px" defaultValue="freelancer" onChange={val => setType(val)}>
                    <Radio variant="primary" value="client">
                        <GroupIcon />
                        <Text color="placeholder" flex="1">
                            I’m a client, hiring for a project
                        </Text>
                        <CheckIcon />
                    </Radio>
                    <Radio variant="primary" value="freelancer" mt="24px">
                        <FreelancerIcon />{' '}
                        <Text color="placeholder" flex="1">
                            I’m a freelancer, Looking for work
                        </Text>
                        <CheckIcon />
                    </Radio>
                </RadioGroup>
                <Button mt="40px" size="lg" w="100%" onClick={() => setUserStep(2)}>
                    Join as a Freelancer
                </Button>
                <Text mt="64px">
                    Already have an account? <Button variant="link">Log in</Button>
                </Text>
            </Box>
        </Center>
    );
}
