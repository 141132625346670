import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { UpdateUserInfo, User } from 'utils/types/user.type';
import { client } from './api-client';

export const useGetTalentUser = (id: string) =>
    useQuery<User, Error>(['userProfile', id], () => client(`talent-user/getProfile/${id}`));

export const useUpdateTalentProfile = () => {
    const query = useQueryClient();
    return useMutation<User, Error, UpdateUserInfo>(
        data =>
            client('talent-user/updateTalentUser', {
                data,
            }),
        {
            onSuccess() {
                query.invalidateQueries(['userProfile']);
            },
        },
    );
};

export const useGetTopProjects = (id: string) =>
    useQuery<any[], Error>(['getTopProjects', id], () =>
        client(`talent-user/getUserTopProjects/${id}?status=completed&offset=0&limit=10`),
    );
