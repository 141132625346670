import React from 'react';
import {
    Card,
    Container,
    Box,
    Text,
    Heading,
    Divider,
    Button,
    Image,
    Textarea,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Input,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import UploadFile from 'assets/images/upload-file.png';

export default function ContactUs() {
    return (
        <>
            <Header />
            <Container maxW="800px" mt="50px">
                <Card p="24px">
                    <Heading as="h1" fontSize="32px">
                        Contact Us
                    </Heading>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h2" fontSize="20px">
                        Love to hear from you, Get in touch
                    </Heading>
                    <Flex wrap="wrap" gap="32px" my="32px">
                        <FormControl w="calc(50% - 16px)">
                            <FormLabel>Name</FormLabel>
                            <Input placeholder="John" />
                        </FormControl>
                        <FormControl w="calc(50% - 16px)">
                            <FormLabel>Email</FormLabel>
                            <Input placeholder="user@example.com" />
                        </FormControl>
                        <FormControl w="calc(50% - 16px)">
                            <FormLabel>Who are you?</FormLabel>
                            <Select placeholder="ex: Client" />
                        </FormControl>
                        <FormControl w="calc(50% - 16px)">
                            <FormLabel>What do you want to talk about?</FormLabel>
                            <Select placeholder="ex: Project Proposal" />
                        </FormControl>
                    </Flex>
                    <Text as="label">Message</Text>
                    <Textarea
                        mt="14px"
                        placeholder="Type your message here"
                        variant="primary"
                        minH="180px"
                    />
                    <Text mt="32px" as="label">
                        Upload File (If there is any)
                    </Text>
                    <Box mt="14px" border="2px dashed #D4D4D4" py="50px" rounded="8px">
                        <Image src={UploadFile} maxW="154px" mx="auto" />
                        <Text textAlign="center" mt="25px" color="heading">
                            <Button variant="link">Click to browse</Button> or drag & drop
                            attachments files
                        </Text>
                        <Text textAlign="center" mt="20px" fontSize="14px">
                            Upload pdf, doc, jpeg, png files only of maximum size 30 MBs
                        </Text>
                    </Box>
                    <Button size="lg" w="300px" mt="32px" mx="auto">
                        Send Message
                    </Button>
                </Card>
            </Container>
            <Footer />
        </>
    );
}
