import React from 'react';
import { SearchBtnIcon } from 'assets/icons';
import { Button, Input, InputGroup, InputLeftAddon, InputRightAddon } from '@chakra-ui/react';
export default function SearchInput() {
    return (
        <InputGroup
            size="lg"
            bg="white"
            rounded="16px"
            border="1px solid #D4D4D4"
            overflow="hidden"
        >
            <InputLeftAddon height="auto" bg="white" border="none">
                <SearchBtnIcon />
            </InputLeftAddon>
            <Input
                border="none"
                boxShadow="none"
                outline="none"
                height="auto"
                focusBorderColor="transparent"
                placeholder="Search job title / keyword"
                pl="0"
            />
            <InputRightAddon height="auto" py="10px" bg="white" border="none">
                <Button>Search</Button>
            </InputRightAddon>
        </InputGroup>
    );
}
