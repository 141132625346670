import React, { useMemo, useState } from 'react';
import {
    Text,
    Flex,
    Tag,
    TagCloseButton,
    TagLabel,
    Icon,
    FormControl,
    FormLabel,
    FormErrorMessage,
} from '@chakra-ui/react';
// import { SearchIcon } from '@chakra-ui/icons';
import { CancelTagIcon } from 'assets/icons/user';
import { useFormContext, Controller } from 'react-hook-form';
import AppSelect from 'components/select';
import { chakraComponents } from 'chakra-react-select';
import { CancelIcon } from 'assets/icons/messages';

export default function AddSkills() {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext<{ skills: string[]; skill: string }>();

    const [skills, setSkills] = useState<string[]>([]);

    const handleRemoveSkill = (index: number) => {
        setValue(
            'skills',
            skills.filter(item => item !== skills[index]),
        );
        setSkills(skills.filter(item => item !== skills[index]));
    };

    const mergedArray = useMemo(() => {
        const customSkills = [
            'Mobile Development',
            'Frontend',
            'Flutter/ Dart',
            'IOS',
            'Tokenization',
            'Dart',
            'UI/UX',
            'Development',
        ];
        return Array.from(new Set([...customSkills, ...skills]))?.map(item => ({
            label: item,
            value: item,
        }));
    }, [skills]);

    return (
        <>
            <FormControl isInvalid={Boolean(errors?.skill)} mb="10px">
                <FormLabel>Skills</FormLabel>
                <Controller
                    control={control}
                    name="skill"
                    render={({ field: { onChange, ref, value } }) => (
                        <AppSelect
                            onChange={(newValue: any) => {
                                onChange('');
                                setValue('skills', [...skills, newValue?.value]);
                                setSkills([...skills, newValue?.value]);
                            }}
                            value={value}
                            options={mergedArray}
                            chakraStyles={{
                                dropdownIndicator: provided => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }}
                            components={{
                                ClearIndicator: props => (
                                    <chakraComponents.ClearIndicator {...props}>
                                        <Icon as={CancelIcon} w={4} h={4} />
                                    </chakraComponents.ClearIndicator>
                                ),
                            }}
                            selectedOptionStyle="color"
                            placeholder="Search Skills"
                            refProp={ref}
                            isSearchable
                            useBasicStyles
                        />
                    )}
                />
                <FormErrorMessage>{errors.skills && errors.skills?.message}</FormErrorMessage>
            </FormControl>
            <Text as="span" fontSize="13px">
                Maximum 10 skills.
            </Text>
            <Flex
                bg="white"
                rounded="8px"
                border="1px solid #D4D4D4"
                mt="24px"
                p="16px"
                gap="16px"
                wrap="wrap"
                minH="140px"
                align="flex-start"
            >
                {skills?.length ? (
                    skills?.map((filter, i) => (
                        <Tag key={filter} borderRadius="full" variant="outline">
                            <TagLabel>{filter}</TagLabel>
                            <TagCloseButton opacity="1" onClick={() => handleRemoveSkill(i)}>
                                <CancelTagIcon />
                            </TagCloseButton>
                        </Tag>
                    ))
                ) : (
                    <Text color="placeholder">Add skills </Text>
                )}
            </Flex>
        </>
    );
}
