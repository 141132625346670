/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import {
    Select,
    Props,
    SelectInstance,
    GroupBase,
    chakraComponents,
    ChakraStylesConfig,
    OptionProps,
} from 'chakra-react-select';

export default function AppSelect(
    props: Props & {
        refProp?: React.Ref<SelectInstance<unknown, boolean, GroupBase<unknown>>>;
    },
) {
    const customComponents = {
        Option: ({ children, ...props }: OptionProps) => (
            <chakraComponents.Option {...props}>
                <Checkbox defaultChecked={Boolean(props?.isSelected)} mr="10px" /> {children}
            </chakraComponents.Option>
        ),
    };

    const chakraStyles: ChakraStylesConfig = {
        container: provided => ({
            ...provided,
            background: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? 'transparent' : provided.background,
            color: state.isSelected ? 'inherit' : provided.color,
        }),
    };

    return (
        <Select
            chakraStyles={chakraStyles}
            {...props}
            ref={props?.refProp}
            components={{ ...props.components, ...customComponents }}
        />
    );
}
