export const MENU = [
    {
        label: 'Home',
        path: '/',
    },
    {
        label: 'Find work',
        path: '/project-catalog',
        subMenu: true,
    },
    {
        label: 'Contact Us',
        path: '/contact-us',
    },
];

export const LogIn_MENU = [
    {
        label: 'Home',
        path: '/',
    },
    {
        label: 'Find work',
        path: '/project-catalog',
        subMenu: true,
    },
    {
        label: 'My Jobs',
        path: '/my-jobs',
    },
    {
        label: 'My Reports',
        path: '/my-reports',
    },
    {
        label: 'My Stats',
        path: '/my-stats',
    },
    {
        label: 'Messages',
        path: '/inbox',
    },
];

export const JOB_MENU = [
    {
        label: 'Graphics & Design',
        path: '/job',
    },
    {
        label: 'Tech & Programming',
        path: '/job',
    },
    {
        label: 'Writing',
        path: '/job',
    },
    {
        label: 'Marketing & Business',
        path: '/job',
    },
    {
        label: 'AI Services',
        path: '/job',
    },
    {
        label: 'HR & trainings',
        path: '/job',
    },
    {
        label: 'More',
        path: '/job',
    },
];

export const COMPANY_MENU = [
    {
        label: 'About',
        path: '/about',
    },
    {
        label: 'How do we do',
        path: '/how-do-we-do',
    },
    {
        label: 'Careers',
        path: '/career',
    },
];

export const SERVICES_MENU = [
    {
        label: 'Blockchain Development',
        path: '/blockchain-dev',
    },
    {
        label: 'Mobile Development',
        path: '/app-development',
    },
    {
        label: 'UI/UX Designing',
        path: '/ui-ux-design-service',
    },
    {
        label: 'Web Development',
        path: '/web-development',
    },
    {
        label: 'Quality Assurance',
        path: '/qa-engineering',
    },
];

export const ADD_JOB_POINT = [
    {
        title: 'Title',
        question: 'Begin with a compelling title',
        detail: `This attracts the attention of suitable freelancers. It's their first impression, so make it impactful.`,
    },
    {
        title: 'Skill',
        question: 'What are the main skills required for your job?',
        detail: `Specify the key skills needed for your job to attract the perfect talent match and maximize success.`,
    },
    {
        title: 'Scope',
        question: 'Estimate the scope of your project.',
        detail: `Define the project scope for clear expectations and accurate proposals from talented professionals`,
    },
    {
        title: 'Project budget',
        question: 'Tell us about your project budget',
        detail: `By providing this information, we can effectively match talent within your specified range.`,
    },
    {
        title: 'Job Description',
        question: 'Write an effective job description',
        detail: `
        <ul class="list">
            <li>Convey project specifics and objectives, presenting exciting challenges to attract skilled professionals.</li>
            <li>Describe the desired expertise, including skill level, experience type, and industry insights you seek.</li>
            <li>Highlight unique aspects of your project or organization, such as a compelling mission or innovative use case.</li>
            <li>Feel free to mention if assistance is needed to confirm the scope of your main project.</li>
        </ul>
        `,
    },
    {
        title: 'Advance Preferences',
        question: 'To get more related results',
        detail: `Advanced preferences do not restrict qualified professionals or agencies from submitting proposals. Providing more information enables StopTalent's AI to facilitate better talent matches.`,
    },
];
