import { useState, useEffect } from 'react';
import { calculateTimeLeft } from 'utils/helper';

export function useTimer(time: number) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(time));
    const [isTimerOff, setIsTimerOff] = useState(false);

    useEffect(() => {
        if (time) {
            const timer = setTimeout(() => {
                setTimeLeft(calculateTimeLeft(time));
            }, 1000);
            if (!timeLeft?.seconds && !timeLeft?.minutes && !timeLeft?.hours) setIsTimerOff(true);
            return () => clearTimeout(timer);
        }
    }, [time, timeLeft?.seconds, timeLeft?.minutes, timeLeft?.hours]);
    return { ...timeLeft, isTimerOff };
}

export function useIsLogin() {
    return localStorage.getItem('token');
}

export function useGetId() {
    return localStorage.getItem('userId') || '';
}
