import React from 'react';
import {
    Button,
    Card,
    Container,
    Flex,
    Heading,
    Text,
    Divider,
    Box,
    Avatar,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import { InvitationLargeIcon, PaymentLargeIcon, WorkLogLargeIcon } from 'assets/icons';

export default function Notifications() {
    return (
        <>
            <Header />
            <Container maxW="1280px">
                <Card p="24px" mt="80px">
                    <Flex justify="space-between">
                        <Heading fontSize="31px" as="h2" fontWeight="500">
                            Notifications
                        </Heading>
                        <Button>Mark all as read</Button>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Text as="span" variant="black" fontSize="20px" mb="30px">
                        Most Recent
                    </Text>
                    <Notification
                        title="Mark Roonei"
                        time="11:04 AM"
                        text="Can we discuss the requirements for the project in detail on Monday."
                    />
                    <Notification
                        isNew
                        icon={<InvitationLargeIcon />}
                        title="Invitation"
                        time="15 Mins Ago"
                        text='You have received an invitation to interview for the job "Senior Product/UX Designer". '
                    />
                    <Notification
                        icon={<PaymentLargeIcon />}
                        title="Payment"
                        time="11:04 AM"
                        text="You requested payment of USDT 14.00 from StopTalent."
                    />
                    <Notification
                        icon={<WorkLogLargeIcon />}
                        title="Work Log"
                        time="Yesterday"
                        text="The work week has ended, and your in time is weekly log is available for review."
                    />
                    <Text as="span" variant="black" fontSize="20px" mb="30px">
                        Earlier
                    </Text>
                    <Notification
                        isNew
                        icon={<InvitationLargeIcon />}
                        title="Invitation"
                        time="15 Mins Ago"
                        text='You have received an invitation to interview for the job "Senior Product/UX Designer". '
                    />
                    <Notification
                        icon={<PaymentLargeIcon />}
                        title="Payment"
                        time="11:04 AM"
                        text="You requested payment of USDT 14.00 from StopTalent."
                    />
                    <Notification
                        icon={<WorkLogLargeIcon />}
                        title="Work Log"
                        time="Yesterday"
                        text="The work week has ended, and your in time is weekly log is available for review."
                    />
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function Notification({
    icon,
    title,
    text,
    time,
    isNew,
}: {
    // eslint-disable-next-line no-undef
    icon?: JSX.Element;
    title: string;
    text: string;
    time: string;
    isNew?: boolean;
}) {
    return (
        <Flex p="24px" bg={isNew ? 'primary.50' : ''} gap="16px" rounded="8px">
            <Box>{icon ? icon : <Avatar name={title} />}</Box>
            <Box flex="1">
                <Flex justify="space-between">
                    <Heading as="h6" fontSize="20px">
                        {title}
                    </Heading>
                    <Text>{time}</Text>
                </Flex>
                <Text mt="12px">{text}</Text>
            </Box>
        </Flex>
    );
}
