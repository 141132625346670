export default {
    baseStyle: {
        tab: {
            '--tabs-color': '#A9A9A9',
            '--chakra-fontSizes-md': '20px',
        },
        tabpanel: {
            p: 0,
        },
    },
};
