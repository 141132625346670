import React from 'react';
import {
    Card,
    Container,
    Flex,
    Heading,
    Text,
    Divider,
    Avatar,
    Box,
    Progress,
    HStack,
    Tag,
    TagLabel,
    SkeletonCircle,
    SkeletonText,
    Skeleton,
    ButtonGroup,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightAddon,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import { StarRatingFillIcon } from 'assets/icons';
import {
    CalenderIcon,
    ChatIcon,
    ClockIcon,
    DownloadIcon,
    ExperienceIcon,
    FileIcon,
    TopRatedIcon,
    VerifiedAccountIcon,
} from 'assets/icons/user';
import AppSelect from 'components/select';
export default function ProposalDetail() {
    const isLoading = false;
    return (
        <>
            <Header />
            <Container maxW="1280px">
                <Card p="55px" mt="80px">
                    <Flex gap="35px">
                        <SkeletonCircle isLoaded={!isLoading} w="auto" h="auto">
                            <Avatar
                                size="2xl"
                                outline="5px solid #59C778"
                                p="2px"
                                border="3px solid white"
                            />
                        </SkeletonCircle>
                        <Flex justify="space-between" flex="1">
                            <Box>
                                <SkeletonText
                                    noOfLines={1}
                                    skeletonHeight="12"
                                    mb="32px"
                                    isLoaded={!isLoading}
                                >
                                    <Heading as="h2">
                                        Paul Walker Jr
                                        <VerifiedAccountIcon />
                                    </Heading>
                                </SkeletonText>
                                <Flex gap="30px" align="center">
                                    <Skeleton isLoaded={!isLoading}>
                                        <Flex
                                            bg="#F4B000"
                                            alignItems="center"
                                            gap="10px"
                                            p="8px 15px"
                                            rounded="4px"
                                        >
                                            <TopRatedIcon />
                                            <Text fontSize="12px" variant="primary">
                                                Top Rated Plus
                                            </Text>
                                        </Flex>
                                    </Skeleton>
                                    <Skeleton isLoaded={!isLoading}>
                                        <Flex gap="8px" as={Text} align="center">
                                            <StarRatingFillIcon /> 4.92 (230 Reviews)
                                        </Flex>
                                    </Skeleton>
                                </Flex>
                            </Box>
                            <Box>
                                <Skeleton isLoaded={!isLoading}>
                                    <Text fontSize="20px" variant="black" textAlign="right">
                                        Total Earnings{' '}
                                        <Text ml="20px" fontSize="31px" as="span" variant="black">
                                            200.30
                                        </Text>{' '}
                                        <sub>USDT</sub>
                                    </Text>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} mt="45px">
                                    <Flex gap="15px" align="center">
                                        <Progress
                                            value={80}
                                            w="165px"
                                            rounded="9px"
                                            sx={{ div: { borderRadius: '9px' } }}
                                        />
                                        <Text variant="link" fontSize="20px">
                                            80% Job Success Rate
                                        </Text>
                                    </Flex>
                                </Skeleton>
                            </Box>
                        </Flex>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between" align="center" mb="40px">
                        <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                            <Heading as="h4" fontSize="25px">
                                Build a Minimum Viable responsive NFT auction website with
                                blockchain integration
                            </Heading>
                        </SkeletonText>
                        <Skeleton isLoaded={!isLoading}>
                            <ButtonGroup>
                                <Button ml="auto" leftIcon={<ChatIcon />}>
                                    Chat Now
                                </Button>
                                <Button variant="light">Award Project</Button>
                            </ButtonGroup>
                        </Skeleton>
                    </Flex>
                    <Flex justify="space-between" mb="40px">
                        <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                            <Heading as="h4" fontSize="20px">
                                About
                            </Heading>
                        </SkeletonText>
                        <Skeleton isLoaded={!isLoading}>
                            <Text as="span">Job Posted On: May 30, 2023</Text>
                        </Skeleton>
                    </Flex>
                    <Flex gap="24px">
                        <Box>
                            <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                                <Tag variant="solid">Blockchain Development</Tag>
                            </SkeletonText>
                            <SkeletonText
                                my="32px"
                                noOfLines={6}
                                skeletonHeight="4"
                                isLoaded={!isLoading}
                            >
                                <Text>
                                    {`Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit fusce
                            diam tincidunt nibh nibh commodo. Sed in adipiscing blandit nullam.
                            Aliquet orci porta fermentum sed sit pretium. Scelerisque consectetur
                            nisl feugiat quam dictum vulputate porttitor turpis sit. Aliquet dapibus
                            aliquam enim orci elit congue justo pharetra ut. Scelerisque consequat
                            faucibus ultricies at id. Et aenean ut varius risus ultrices mattis
                            elementum morbi luctus. Leo cras ipsum metus imperdiet. Tortor consequat
                            elit etiam felis nunc auctor.`}
                                </Text>
                            </SkeletonText>
                            <Heading as="h4" fontSize="16px">
                                Skills & Expertise
                            </Heading>
                            <HStack mt="30px" flexWrap="wrap">
                                {['Cloud system engineer', 'Applications engineer', "NFT's"]?.map(
                                    item => (
                                        <Tag key={item}>
                                            <TagLabel>{item}</TagLabel>
                                        </Tag>
                                    ),
                                )}
                            </HStack>
                        </Box>
                        <Flex flexWrap="wrap">
                            <ProfileUtils
                                icon={<ExperienceIcon />}
                                label="Experience Level"
                                value={'Intermediate'}
                                isLoaded={isLoading}
                            />
                            <ProfileUtils
                                icon={<CalenderIcon />}
                                label="Project Length"
                                value="1- 3 months"
                                isLoaded={isLoading}
                            />
                            <ProfileUtils
                                icon={<ClockIcon />}
                                label="Propose Term"
                                value="Fixed price"
                                isLoaded={isLoading}
                            />
                        </Flex>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                        <Heading as="h4" fontSize="20px">
                            Proposed Terms
                        </Heading>
                    </SkeletonText>
                    <Card p="24px" mt="32px">
                        <Flex p="16px" rounded="8px" bg="primary.50" gap="30px" align="center">
                            <Text>Your’s budget:</Text>
                            <Text variant="black" fontSize="25px">
                                394.00
                                <Text ml="10px" as="span" variant="secondary" fontSize="20px">
                                    USDT
                                </Text>
                            </Text>
                        </Flex>
                        <Heading as="h6" fontSize="16px" mt="32px">
                            How does client want to be paid?
                        </Heading>
                        <Text mt="32px">By project</Text>
                        <Heading as="h6" fontSize="16px" mt="40px">
                            Total price of the project
                        </Heading>
                        <Text variant="black" fontSize="25px" mt="24px">
                            1200
                            <Text ml="10px" as="span" variant="secondary" fontSize="20px">
                                USDT
                            </Text>
                        </Text>
                        <Divider my="40px" borderColor="border" />
                        <Heading as="h6" fontSize="16px" mt="40px">
                            Your client will receive
                        </Heading>
                        <Text my="24px">The estimated payment, after service fees</Text>
                        <Text variant="black" fontSize="25px" mt="24px">
                            1150
                            <Text ml="10px" as="span" variant="secondary" fontSize="20px">
                                USDT
                            </Text>
                        </Text>
                    </Card>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                        <Heading as="h4" fontSize="20px">
                            Cover Letter
                        </Heading>
                    </SkeletonText>
                    <Text mt="40px">
                        Dear Steve,
                        <br />
                        <br />
                        {`I am writing to express my deep interest in the exciting blockchain
                        project at [Company/Organization Name]. As a passionate and dedicated
                        blockchain enthusiast, I am thrilled at the prospect of contributing my
                        skills and knowledge to your innovative team. I have been following the
                        advancements in blockchain technology closely and strongly believe in its
                        transformative potential across various industries. The
                        [Company/Organization Name] has consistently impressed me with its
                        groundbreaking initiatives and commitment to pushing the boundaries of
                        blockchain applications. I am genuinely inspired by your vision to
                        revolutionize [specific industry/field] through the implementation of
                        distributed ledger technology. With a solid educational background in
                        computer science and hands-on experience in blockchain development, I am
                        confident in my ability to make valuable contributions to your project.
                        During my previous role as a blockchain developer at [Previous
                        Company/Organization], I successfully designed and implemented smart
                        contracts, developed decentralized applications (dApps), and collaborated
                        with cross-functional teams to ensure seamless integration of blockchain
                        solutions. My expertise in Ethereum, Solidity, and Hyperledger Fabric,
                        combined with my understanding of cryptographic protocols, make me
                        well-equipped to contribute to your project's success. Beyond my technical
                        skills, I am a strong communicator and an enthusiastic team player. I thrive
                        in collaborative environments and enjoy working alongside motivated
                        professionals who share a common goal. I am eager to leverage my
                        interpersonal skills to foster effective collaboration within your team and
                        contribute to a positive and productive work culture. I am particularly
                        drawn to the [Company/Organization Name] because of its reputation for
                        fostering innovation, providing professional growth opportunities, and
                        embracing a forward-thinking approach. Your commitment to staying at the
                        forefront of blockchain technology aligns perfectly with my own aspirations,
                        and I am eager to contribute to your mission and contribute to the success
                        of your projects. Thank you for considering my application. I would welcome
                        the opportunity to discuss how my skills and experience can support your
                        blockchain project further. I have attached my resume for your review, and I
                        look forward to the possibility of an interview to explore this opportunity
                        in more detail. Thank you for your time and consideration.`}
                        <br />
                        <br />
                        Sincerely,
                        <br /> Michal Jordan
                    </Text>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                        <Heading as="h4" fontSize="20px">
                            Other Documents
                        </Heading>
                    </SkeletonText>
                    <Flex p="16px" mt="24px" gap="16px" rounded="8px" bg="bgGray" maxW="450px">
                        <FileIcon />
                        <Box flex="1">
                            <Text variant="black" as="strong">
                                Milestone-002.pdf
                            </Text>
                            <Text as="span" fontSize="13px" display="block">
                                245 KB
                            </Text>
                        </Box>
                        <DownloadIcon />
                    </Flex>
                </Card>
                <ButtonGroup mt="30px" w="100%" justifyContent="flex-end">
                    <Button size="lg" variant="light" w="160px">
                        Reject
                    </Button>
                    <AcceptProposalModal />
                </ButtonGroup>
            </Container>
            <Footer />
        </>
    );
}

function ProfileUtils({
    icon,
    label,
    value,
    isLoaded,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value?: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isLoaded: boolean;
}) {
    return (
        <Skeleton flex="150px" minW="max-content" isLoaded={!isLoaded}>
            <Card
                as={Flex}
                flexDirection="row"
                p="20px 18px"
                align="center"
                gap="16px"
                rounded="8px"
                bg="bgGray"
                border="none"
            >
                {icon}
                <Box>
                    <Text as="h6" variant="secondary" fontSize="14px">
                        {label}
                    </Text>
                    <Text variant="black">{value}</Text>
                </Box>
            </Card>
        </Skeleton>
    );
}

function AcceptProposalModal() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
        <>
            <Button size="lg" w="160px" onClick={onOpen}>
                Accept
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Give an offer
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <form>
                            <FormControl size="lg">
                                <FormLabel>Project Type</FormLabel>
                                <AppSelect
                                    size="lg"
                                    variant="primary"
                                    options={[
                                        { label: 'Fixed Price', value: 'fixed' },
                                        { label: 'Milestones', value: 'milestone' },
                                    ]}
                                />
                            </FormControl>

                            <FormControl mt="32px" size="lg">
                                <FormLabel>Amount</FormLabel>
                                <InputGroup size="lg">
                                    <Input
                                        defaultValue="200.30"
                                        variant="primary"
                                        borderRight="none"
                                    />
                                    <InputRightAddon
                                        bg="transparent"
                                        color="primary.500"
                                        borderColor="border"
                                    >
                                        USDT
                                    </InputRightAddon>
                                </InputGroup>
                            </FormControl>
                        </form>
                    </ModalBody>
                    <ModalFooter mt="10px">
                        <ButtonGroup>
                            <Button variant="secondary" size="lg" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button size="lg" onClick={onClose}>
                                Send Offer
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
