import {
    Card,
    Container,
    Heading,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from '@chakra-ui/react';
import Banner from 'components/banner';
import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';
import { Link } from 'react-router-dom';

export default function HelpCenter() {
    return (
        <>
            <Header />
            <Banner
                title="Find a solution fast"
                subtitle="Search hundreds of articles on Stoptalent Help"
                categories={['Connects']}
            />
            <Container maxW="1280px" mt="100px">
                <Heading as="h2" fontSize="32px">
                    Who you are? Choose an account type for personalized service.
                </Heading>
                <Tabs>
                    <TabList my="56px">
                        <Tab>I’m a Freelancer</Tab>
                        <Tab>I’m an Agency</Tab>
                    </TabList>
                    <Text fontSize="20px" fontWeight="500" variant="black">
                        Search Result
                    </Text>
                    <TabPanels>
                        <TabPanel sx={{ '&>div:nth-child(1)': { m: '0' } }}>
                            {[...Array(9)]?.map(item => (
                                <Card key={item} p="24px" mt="40px" as={Link} to="/article">
                                    <Text fontWeight="500" fontSize="20px" variant="black">
                                        Understanding and Using Connects
                                    </Text>
                                    <Text mt="24px" sx={{ strong: { color: 'primary.500' } }}>
                                        to <strong>connect</strong> with each other and build
                                        strong, productive relationships. As a freelancer or agency,{' '}
                                        {`you'll`} use <strong>Connects</strong> to
                                    </Text>
                                </Card>
                            ))}
                        </TabPanel>
                        <TabPanel sx={{ '&>div:nth-child(1)': { m: '0' } }}>
                            {[...Array(9)]?.map(item => (
                                <Card key={item} p="24px" mt="40px">
                                    <Text fontWeight="500" fontSize="20px" variant="black">
                                        Understanding and Using Connects
                                    </Text>
                                    <Text mt="24px" sx={{ strong: { color: 'primary.500' } }}>
                                        to <strong>connect</strong> with each other and build
                                        strong, productive relationships. As a freelancer or agency,{' '}
                                        {`you'll`} use <strong>Connects</strong> to
                                    </Text>
                                </Card>
                            ))}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Container>
            <Footer />
        </>
    );
}
