import React, { useEffect, useState } from 'react';
import {
    Card,
    Container,
    Flex,
    Heading,
    Text,
    Divider,
    Avatar,
    Box,
    Progress,
    AvatarBadge,
    HStack,
    Tag,
    TagLabel,
    Image,
    CardBody,
    IconButton,
    Button,
    useDisclosure,
    Modal,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    Input,
    Textarea,
    ModalFooter,
    ButtonGroup,
    Select,
    FormControl,
    FormLabel,
    Center,
    VisuallyHiddenInput,
    SkeletonCircle,
    SkeletonText,
    Skeleton,
    FormErrorMessage,
    Grid,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import { LinkIcon, StarRatingFillIcon } from 'assets/icons';
import {
    ClockIcon,
    CompletedIcon,
    EditBtnIcon,
    EditIcon,
    EducationIcon,
    JobIcon,
    LanguageIcon,
    TopRatedIcon,
    VerifiedAccountIcon,
    VerifiedWhiteIcon,
} from 'assets/icons/user';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import AddSkills from 'components/addSkills';
import { AddIcon } from '@chakra-ui/icons';
import certificatePlaceholder from 'assets/images/certificate-placeholder.png';
import UploadFile from 'assets/images/upload-file.png';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useGetTalentUser, useGetTopProjects, useUpdateTalentProfile } from 'utils/apis/user.api';
import { useGetId, useIsLogin } from 'utils/hooks';
import { UpdateUserInfo } from 'utils/types/user.type';
import { showToast } from 'utils/helper';
import AddLanguage from 'components/addLanguage';

export default function Profile() {
    const userId = useGetId();
    const { data: talentUser, isLoading } = useGetTalentUser(userId);
    const { data: topProjects, isLoading: projectLoading } = useGetTopProjects(userId);
    const isLogon = useIsLogin();
    return (
        <>
            <Header />
            <Container maxW="1280px">
                <Card p="55px" mt="80px">
                    <Flex gap="35px">
                        <SkeletonCircle isLoaded={!isLoading} w="auto" h="auto">
                            <Avatar
                                size="2xl"
                                outline="5px solid #59C778"
                                p="2px"
                                border="3px solid white"
                                src={talentUser?.profilePictureURL}
                            >
                                <EditProfilePic />
                            </Avatar>
                        </SkeletonCircle>
                        <Flex justify="space-between" flex="1">
                            <Box>
                                <SkeletonText
                                    noOfLines={1}
                                    skeletonHeight="12"
                                    mb="32px"
                                    isLoaded={!isLoading}
                                >
                                    <Heading as="h2">
                                        {talentUser?.name}
                                        <VerifiedAccountIcon />
                                    </Heading>
                                </SkeletonText>
                                <Flex gap="30px" align="center">
                                    <Skeleton isLoaded={!isLoading}>
                                        <Flex
                                            bg="#F4B000"
                                            alignItems="center"
                                            gap="10px"
                                            p="8px 15px"
                                            rounded="4px"
                                        >
                                            <TopRatedIcon />
                                            <Text fontSize="12px" variant="primary">
                                                Top Rated Plus
                                            </Text>
                                        </Flex>
                                    </Skeleton>
                                    <Skeleton isLoaded={!isLoading}>
                                        <Flex gap="8px" as={Text} align="center">
                                            <StarRatingFillIcon /> 4.92 (230 Reviews)
                                        </Flex>
                                    </Skeleton>
                                </Flex>
                            </Box>
                            <Box>
                                <Skeleton isLoaded={!isLoading}>
                                    <Text fontSize="20px" variant="black" textAlign="right">
                                        Total Earnings{' '}
                                        <Text ml="20px" fontSize="31px" as="span" variant="black">
                                            200.30
                                        </Text>{' '}
                                        <sub>USDT</sub>
                                    </Text>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} mt="45px">
                                    <Flex gap="15px" align="center">
                                        <Progress
                                            value={80}
                                            w="165px"
                                            rounded="9px"
                                            sx={{ div: { borderRadius: '9px' } }}
                                        />
                                        <Text variant="link" fontSize="20px">
                                            80% Job Success Rate
                                        </Text>
                                    </Flex>
                                </Skeleton>
                            </Box>
                        </Flex>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between" mb="30px">
                        <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                            <Heading as="h4" fontSize="25px">
                                About
                            </Heading>
                        </SkeletonText>
                        <Skeleton isLoaded={!isLoading}>
                            <HStack>
                                {isLogon && <EditAbout />}
                                <IconButton icon={<LinkIcon />} aria-label="copy-link" size="lg" />
                            </HStack>
                        </Skeleton>
                    </Flex>
                    <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                        <Text variant="black" fontSize="20px">
                            {talentUser?.title}
                        </Text>
                    </SkeletonText>
                    <SkeletonText mt="40px" noOfLines={6} skeletonHeight="4" isLoaded={!isLoading}>
                        <Text>{talentUser?.introduction}</Text>
                    </SkeletonText>
                    <Flex flexWrap="wrap" gap="24px" mt="55px">
                        <ProfileUtils
                            icon={<LanguageIcon />}
                            label="Language"
                            value={talentUser?.languages?.map(item => item?.language).join(', ')}
                            isEdit={<EditLanguage />}
                            isLoaded={isLoading}
                        />
                        <ProfileUtils
                            icon={<EducationIcon />}
                            label="Education"
                            value={talentUser?.education?.school}
                            isEdit={<EditEducation />}
                            isLoaded={isLoading}
                        />
                        <ProfileUtils
                            icon={<ClockIcon />}
                            label="Avg Response Time"
                            value="6 hours"
                            isLoaded={isLoading}
                        />
                        <ProfileUtils
                            isLoaded={isLoading}
                            icon={<JobIcon />}
                            label="Total Jobs"
                            value="100+"
                        />
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText
                        noOfLines={1}
                        skeletonHeight="12"
                        w="max-content"
                        isLoaded={!projectLoading}
                    >
                        <Heading as="h4" fontSize="25px">
                            Work History
                        </Heading>
                    </SkeletonText>
                    <Flex flexWrap="wrap" gap="24px" mt="30px">
                        {(topProjects || [...Array(3)])?.map(item => (
                            <WorkDone key={item} isLoading={projectLoading} />
                        ))}
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between">
                        <Heading as="h4" fontSize="25px">
                            Skills
                        </Heading>
                        <EditSkills />
                    </Flex>
                    <HStack mt="30px" flexWrap="wrap">
                        {talentUser?.skills?.map(item => (
                            <Tag key={item}>
                                <TagLabel>{item}</TagLabel>
                            </Tag>
                        ))}
                    </HStack>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="25px">
                        Certifications
                    </Heading>
                    <Center flexDirection="column" maxW="700px" mx="auto" mt="40px">
                        <Image src={certificatePlaceholder} />
                        <Text my="24px" textAlign="center">
                            Listing your certifications can help prove your specific knowledge or
                            abilities. (+10%). You can add them manually.
                        </Text>
                        <AddCertificate />
                    </Center>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="25px" mb="30px">
                        Project Catalog
                    </Heading>
                    <Flex gap="16px">
                        {[...Array(4)]?.map(item => (
                            <ProjectCatalog key={item} />
                        ))}
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between">
                        <Heading as="h4" fontSize="25px">
                            Testimonials
                        </Heading>
                        <RequestTestimonials />
                    </Flex>
                    <Flex gap="50px" flexWrap="wrap" mt="40px">
                        {[...Array(4)]?.map(item => (
                            <Testimonial key={item} />
                        ))}
                    </Flex>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ProfileUtils({
    icon,
    label,
    value,
    isEdit,
    isLoaded,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value?: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
    isLoaded: boolean;
}) {
    return (
        <Skeleton flex="150px" minW="max-content" isLoaded={!isLoaded}>
            <Card as={Flex} flexDirection="row" p="14px" align="center" gap="19px">
                {icon}
                <Box>
                    <Text as="h6" gap="9px" variant="black" fontSize="20px">
                        {label}
                    </Text>
                    <Text>{value}</Text>
                </Box>
                {isEdit}
            </Card>
        </Skeleton>
    );
}

function WorkDone({ isLoading }: { isLoading: boolean }) {
    return (
        <Skeleton flex="280px" maxW="363px" isLoaded={!isLoading}>
            <Card p="16px" rounded="8px">
                <Flex justify="space-between">
                    <Heading fontSize="20px">Blockchain Website</Heading>{' '}
                    <Text as={Flex} gap="7px">
                        <StarRatingFillIcon /> 4.9
                    </Text>
                </Flex>
                <Text mt="8px" fontSize="13px">
                    May 30, 2023 - June 15, 2023
                </Text>
                <Text my="32px" fontStyle="italic">
                    “Excellent work! I am impressed with the skills he has.”
                </Text>
                <Flex justify="space-between">
                    <Text>
                        <Text as="span" variant="black" fontSize="25px">
                            35.3{' '}
                        </Text>
                        USDT
                    </Text>
                    <Flex bg="#59C778" align="center" p="5px 10px" rounded="8px" gap="6px">
                        <Text variant="primary" as="span">
                            Job Completed
                        </Text>
                        <CompletedIcon />
                    </Flex>
                </Flex>
            </Card>
        </Skeleton>
    );
}

function ProjectCatalog() {
    return (
        <Card maxW="280px" flex="280px" p="12px" bg="bgGray">
            <Image
                rounded="8px"
                objectFit="cover"
                maxW="100%"
                src="https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
                alt="Caffe Latte"
            />
            <CardBody p="0">
                <Heading as="h6" textAlign="center" fontSize="16px" my="24px">
                    Lorem ipsum dolor sit amet consectetur.
                </Heading>
                <Text bg="white" p="14px" textAlign="center">
                    Delivery Time{' '}
                    <Text as="span" variant="black" fontWeight="600">
                        3 Days
                    </Text>
                </Text>
                <Flex align="center" justify="space-between" my="24px">
                    <Box>
                        <Text>From</Text>
                        <Flex gap="7px">
                            <Avatar size="xs" name="USDT" />
                            <Text variant="black">
                                87.23{' '}
                                <Text as="span" fontSize="10px">
                                    USDT
                                </Text>
                            </Text>
                        </Flex>
                    </Box>
                    <Box>
                        <Text>Ratings</Text>
                        <Text fontSize="12px">3 Days Delivery</Text>
                    </Box>
                </Flex>
                <Flex
                    as={Link}
                    to="/"
                    bg="lightGray"
                    p="10px"
                    justify="center"
                    rounded="8px"
                    mb="12px"
                >
                    View Project
                </Flex>
            </CardBody>
        </Card>
    );
}

function Testimonial() {
    return (
        <Box flex="360px">
            <Flex gap="24px">
                <Avatar size="lg" name="Bessie Cooper" />
                <Box>
                    <Flex as="h4" fontSize="20px" align="center" gap="10px">
                        Bessie Cooper{' '}
                        <Text
                            fontSize="12px"
                            variant="primary"
                            as={Flex}
                            bg="primary.500"
                            p="4px 8px"
                            gap="5px"
                            rounded="4px"
                            alignItems="center"
                        >
                            <VerifiedWhiteIcon /> Verified
                        </Text>
                    </Flex>
                    <Text mt="10px">May 20, 2015</Text>
                </Box>
            </Flex>
            <Text mt="24px" fontStyle="italic">
                “Lorem ipsum dolor sit amet consectetur. Maecenas orci sed curabitur sed eu rhoncus
                quis a venenatis. Ligula penatibus volutpat quis arcu facilisi congue gravida.
                Scelerisque risus et leo ac viverra hendrerit pharetra.”
            </Text>
        </Box>
    );
}

function EditProfilePic() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [profilePic, setProfilePic] = useState<File | undefined>(undefined);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [imageCrop, setImageCrop] = useState<Area | undefined>(undefined);

    console.log('crop');

    return (
        <>
            <AvatarBadge border="none" right="50%" transform="translate(50%, 51%)" onClick={onOpen}>
                <EditIcon />
            </AvatarBadge>
            <Modal isOpen={isOpen} isCentered onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Upload Profile Photo
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isEdit ? (
                            <Box position="relative" minH="260px">
                                {profilePic && (
                                    <CropImage
                                        profilePic={window.URL.createObjectURL(profilePic)}
                                        setImageCrop={setImageCrop}
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box
                                as="label"
                                display="block"
                                border="1px dashed #A9A9A9"
                                py="32px"
                                rounded="8px"
                                htmlFor="uploadFile"
                                pointerEvents={profilePic ? 'none' : 'all'}
                            >
                                <Avatar
                                    src={profilePic && window.URL.createObjectURL(profilePic)}
                                    size="2xl"
                                    mx="auto"
                                    bg="primary.200"
                                    display="block"
                                    outline={profilePic ? '5px solid #59C778' : 'none'}
                                    p="2px"
                                    border="3px solid white"
                                    sx={{
                                        img: {
                                            transform: `translate(${imageCrop?.x}px, ${imageCrop?.y}px)`,
                                        },
                                    }}
                                >
                                    {profilePic && (
                                        <AvatarBadge
                                            border="none"
                                            right="50%"
                                            transform="translate(50%, 51%)"
                                            onClick={onOpen}
                                        >
                                            <EditIcon />
                                        </AvatarBadge>
                                    )}
                                </Avatar>
                                <Text textAlign="center" mt="20px" fontSize="14px">
                                    Upload jpeg files only of maximum size 2 MBs
                                </Text>
                            </Box>
                        )}

                        <VisuallyHiddenInput
                            type="file"
                            id="uploadFile"
                            value={''}
                            onChange={e => setProfilePic(e?.target?.files?.[0])}
                        />
                    </ModalBody>
                    <ModalFooter>
                        {profilePic ? (
                            isEdit ? (
                                <Button size="lg" onClick={() => setEdit(false)}>
                                    Save & Crop
                                </Button>
                            ) : (
                                <ButtonGroup>
                                    <Button
                                        variant="secondary"
                                        size="lg"
                                        onClick={() => setProfilePic(undefined)}
                                    >
                                        Remove
                                    </Button>
                                    <Button size="lg" onClick={() => setEdit(true)}>
                                        Edit
                                    </Button>
                                </ButtonGroup>
                            )
                        ) : (
                            <Button as="label" size="lg" htmlFor="uploadFile">
                                Upload
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function CropImage({
    profilePic,
    setImageCrop,
}: {
    profilePic: string;
    setImageCrop: React.Dispatch<React.SetStateAction<Area | undefined>>;
}) {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setImageCrop(croppedArea);
        console.log(croppedArea, croppedAreaPixels);
    };

    return (
        <Cropper
            image={profilePic}
            crop={crop}
            zoom={zoom}
            aspect={2 / 2}
            onCropChange={w => setCrop(w)}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
        />
    );
}

function EditAbout() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateUserInfo>();
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Bio updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <Button variant="light" size="lg" w="100px" onClick={onOpen}>
                Edit
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        About
                    </ModalHeader>
                    <ModalCloseButton />
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <FormControl mb="32px" isInvalid={Boolean(errors?.title)}>
                                <FormLabel mb="14px">Title</FormLabel>
                                <Input
                                    {...register('title', { required: 'Tittle required' })}
                                    defaultValue="Full-Stack Blockchain Developer (Solidity, Smart Contract, NFT, Wallet)"
                                    variant="primary"
                                />
                                <FormErrorMessage>
                                    {errors?.title && errors?.title?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                mt="14px"
                                mb="32px"
                                isInvalid={Boolean(errors?.introduction)}
                            >
                                <FormLabel mt="14px">Overview</FormLabel>
                                <Textarea
                                    placeholder="Type here"
                                    variant="primary"
                                    minH="240px"
                                    {...register('introduction', { required: 'Overview required' })}
                                    defaultValue="Full-Stack Blockchain Developer (Solidity, Smart Contract, NFT, Wallet)"
                                />
                                <FormErrorMessage>
                                    {errors?.introduction && errors?.introduction?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="secondary" size="lg" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button size="lg" isLoading={isLoading} type="submit">
                                    Save & Update
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}

function EditLanguage() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const methods = useForm<UpdateUserInfo>({
        defaultValues: {
            languages: [{ language: 'english', level: 'basic' }],
        },
    });
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Language updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <IconButton
                aria-label="edit profile detail"
                icon={<EditBtnIcon />}
                variant="unstyled"
                minW="max-content"
                onClick={onOpen}
            />
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Add / Edit Language
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Add languages that you know to get more clients from all over the globe.
                        </Text>
                        <FormProvider {...methods}>
                            <Box as="form">
                                <AddLanguage />
                            </Box>
                        </FormProvider>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                size="lg"
                                isLoading={isLoading}
                                onClick={methods.handleSubmit(onSubmit)}
                            >
                                Save & Update
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function EditEducation() {
    const { onOpen, isOpen, onClose } = useDisclosure();

    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<UpdateUserInfo>();
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Education updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <IconButton
                aria-label="edit profile detail"
                icon={<EditBtnIcon />}
                variant="unstyled"
                minW="max-content"
                onClick={onOpen}
            />
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Education
                    </ModalHeader>

                    <ModalCloseButton />
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Text mb="32px">
                                Looking for a way to stand out from the crowd? Military veterans are
                                routinely sought after by clients who are looking to increase their
                                engagement with the veteran business community.
                            </Text>
                            <FormControl mb="32px" isInvalid={Boolean(errors?.education?.school)}>
                                <FormLabel mb="14px">School</FormLabel>
                                <Input
                                    {...register('education.school', {
                                        required: 'School is required',
                                    })}
                                    defaultValue="New York School"
                                    variant="primary"
                                />
                                <FormErrorMessage>
                                    {errors?.education?.school &&
                                        errors?.education?.school?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <Text as="label">Dates Attended (Optional)</Text>
                            <Grid
                                gap="16px"
                                mt="14px"
                                mb="24px"
                                gridTemplateColumns="1fr 1fr"
                                sx={{
                                    '.react-datepicker-wrapper': { display: 'block' },
                                }}
                            >
                                <Controller
                                    control={control}
                                    name="education.startDate"
                                    rules={{
                                        required: 'Start date required',
                                    }}
                                    render={({ field }) => (
                                        <FormControl
                                            isInvalid={Boolean(errors?.education?.startDate)}
                                        >
                                            <Input
                                                as={DatePicker}
                                                variant="primary"
                                                onChange={date => field.onChange(date)}
                                                selected={field.value}
                                                peekNextMonth
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="Start Date"
                                                dropdownMode="select"
                                            />
                                            <FormErrorMessage>
                                                {errors?.education?.startDate &&
                                                    errors?.education?.startDate?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="education.endDate"
                                    rules={{
                                        required: 'End date required',
                                    }}
                                    render={({ field }) => (
                                        <FormControl
                                            isInvalid={Boolean(errors?.education?.endDate)}
                                        >
                                            <Input
                                                as={DatePicker}
                                                variant="primary"
                                                onChange={date => field.onChange(date)}
                                                selected={field.value}
                                                peekNextMonth
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="End Date"
                                                dropdownMode="select"
                                                width="100%"
                                            />
                                            <FormErrorMessage>
                                                {errors?.education?.endDate &&
                                                    errors?.education?.endDate?.message}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                />
                            </Grid>
                            <Flex mb="32px" gap="16px">
                                <FormControl>
                                    <FormLabel mb="14px">Degree (Optional)</FormLabel>
                                    <Select {...register('education.degree')}>
                                        <option>Doctor of Engineering</option>
                                    </Select>
                                </FormControl>
                                <FormControl isInvalid={Boolean(errors?.education?.industry)}>
                                    <FormLabel mb="14px">Area of Study</FormLabel>
                                    <Input
                                        {...register('education.industry', {
                                            required: 'Industry required',
                                        })}
                                        placeholder="ex; Computer Science"
                                        variant="primary"
                                    />
                                    <FormErrorMessage>
                                        {errors?.education?.industry &&
                                            errors?.education?.industry?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <FormControl>
                                <FormLabel mb="14px">Description (Optional)</FormLabel>
                                <Textarea
                                    placeholder="Type here"
                                    variant="primary"
                                    minH="240px"
                                    {...register('education.description')}
                                />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="secondary" size="lg" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button size="lg" isLoading={isLoading} type="submit">
                                    Save & Update
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}

function EditSkills() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const methods = useForm<UpdateUserInfo>();
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Language updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <Button variant="light" size="lg" w="100px" onClick={onOpen}>
                Edit
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Edit Skills
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormProvider {...methods}>
                            <Box as="form">
                                <AddSkills />
                            </Box>
                        </FormProvider>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                size="lg"
                                isLoading={isLoading}
                                onClick={methods.handleSubmit(onSubmit)}
                            >
                                Save & Update
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function AddCertificate() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<UpdateUserInfo>();
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Education updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <Button variant="light" leftIcon={<AddIcon />} size="lg" onClick={onOpen}>
                Add Certificate
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Add Certification
                    </ModalHeader>

                    <ModalCloseButton />
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Text mb="32px" fontSize="13px">
                                Your uploaded certification will be displayed on your profile after
                                the verification.
                            </Text>
                            <FormControl mb="32px" isInvalid={Boolean(errors?.certificate?.name)}>
                                <FormLabel>Select Your Certification</FormLabel>
                                <Select
                                    {...register('certificate.name', {
                                        required: 'Certificate is required',
                                    })}
                                >
                                    <option value="" hidden>
                                        ex: AWS Certified Database Specialty
                                    </option>
                                    <option value="AWS Certified Database Specialty">
                                        AWS Certified Database Specialty
                                    </option>
                                    <option value="Master React Js">Master React Js</option>
                                </Select>
                                <FormErrorMessage>
                                    {errors?.certificate?.name &&
                                        errors?.certificate?.name?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <Flex
                                mb="32px"
                                gap="16px"
                                sx={{
                                    '.react-datepicker-wrapper': { display: 'block' },
                                }}
                            >
                                <FormControl isInvalid={Boolean(errors?.certificate?.issueDate)}>
                                    <FormLabel mb="14px">Issue Date</FormLabel>
                                    <Controller
                                        control={control}
                                        name="certificate.issueDate"
                                        rules={{
                                            required: 'Issue date required',
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                as={DatePicker}
                                                variant="primary"
                                                onChange={date => field.onChange(date)}
                                                selected={field.value}
                                                peekNextMonth
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="End Date"
                                                dropdownMode="select"
                                                width="100%"
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors?.certificate?.issueDate &&
                                            errors?.certificate?.issueDate?.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={Boolean(errors?.certificate?.expiryDate)}>
                                    <FormLabel mb="14px">Expiry Date</FormLabel>
                                    <Controller
                                        control={control}
                                        name="certificate.expiryDate"
                                        rules={{
                                            required: 'Expiry date required',
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                as={DatePicker}
                                                variant="primary"
                                                onChange={date => field.onChange(date)}
                                                selected={field.value}
                                                peekNextMonth
                                                showYearDropdown
                                                showMonthDropdown
                                                placeholderText="End Date"
                                                dropdownMode="select"
                                                width="100%"
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {errors?.certificate?.expiryDate &&
                                            errors?.certificate?.expiryDate?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>
                            <FormControl mb="32px" isInvalid={Boolean(errors?.certificate?.codeId)}>
                                <FormLabel mb="14px">Certification ID Code</FormLabel>
                                <Input
                                    {...register('certificate.codeId', {
                                        required: 'Certification ID is required',
                                    })}
                                    variant="primary"
                                    placeholder="ABCD0002880"
                                />
                                <FormErrorMessage>
                                    {errors?.certificate?.codeId &&
                                        errors?.certificate?.codeId?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl>
                                <FormLabel mb="14px">Upload File (Optional)</FormLabel>
                                <Box border="1.5px dashed #D4D4D4" py="50px" rounded="8px">
                                    <Image src={UploadFile} maxW="154px" mx="auto" />
                                    <Text textAlign="center" mt="25px" color="heading">
                                        <Button variant="link" as="label" htmlFor="uploadFile">
                                            Click to browse
                                        </Button>
                                        <VisuallyHiddenInput type="file" id="uploadFile" /> or drag
                                        & drop attachments files
                                    </Text>
                                    <Text textAlign="center" mt="20px" fontSize="14px">
                                        Upload pdf, doc, jpeg, png files only of maximum size 30 MBs
                                    </Text>
                                </Box>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="secondary" size="lg" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button size="lg" isLoading={isLoading} type="submit">
                                    Save & Update
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}

function RequestTestimonials() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    return (
        <>
            <Button variant="light" leftIcon={<AddIcon />} size="lg" onClick={onOpen}>
                Request a new testimonial
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Request a client testimonial
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb="32px" fontSize="13px">
                            Add your client’s contact details. Don’t worry—we’ll only display their
                            first name and last initial.
                        </Text>
                        <FormControl mb="32px">
                            <FormLabel>Name</FormLabel>
                            <Input placeholder="John Alex" variant="primary" />
                        </FormControl>
                        <Flex mb="32px" gap="16px">
                            <FormControl>
                                <FormLabel>Client’s Email</FormLabel>
                                <Input placeholder="user@email.com" variant="primary" />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Client’s LinkedIn Link</FormLabel>
                                <Input placeholder="www.linkedin.com/username" variant="primary" />
                            </FormControl>
                        </Flex>
                        <Flex mb="32px" gap="16px">
                            <FormControl>
                                <FormLabel>Project Type</FormLabel>
                                <Input placeholder="ex: blockchain development" variant="primary" />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Client’s Title</FormLabel>
                                <Input placeholder="ex: Director of Marketing" variant="primary" />
                            </FormControl>
                        </Flex>
                        <FormControl>
                            <FormLabel>Message to client</FormLabel>
                            <Textarea placeholder="Type here" variant="primary" minH="240px" />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg">
                                Cancel
                            </Button>
                            <Button size="lg">Save & Update</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
