import React, { useEffect, useState } from 'react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import {
    Container,
    Image,
    Button,
    Box,
    Heading,
    Text,
    Input,
    FormControl,
    FormErrorMessage,
    InputGroup,
    InputRightElement,
    Icon,
    Show,
    Hide,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { useForm } from 'react-hook-form';
import VerifyEmail from './verifyEmail';
import { useGetOtp, useResetPassword } from 'utils/apis/auth.api';
import { ShowIcon } from 'assets/icons';
import { showToast } from 'utils/helper';

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [userEmail, setUserEmail] = useState('');

    const handleGoBack = () => {
        if (step === 1) {
            navigate('/sign-in');
        } else if (step === 2) {
            setStep(1);
        } else if (step === 3) {
            setStep(2);
        }
    };
    return (
        <Container maxW="1280px" pt="25px">
            <Link to="/">
                <Image src={logo} />
            </Link>
            <Button mt="30px" leftIcon={<ArrowLeftIcon />} onClick={handleGoBack} variant="link">
                Back
            </Button>
            <Box pt="50px">
                {step === 1 && <GetUserEmail setStep={setStep} setUserEmail={setUserEmail} />}
                {step === 2 && <VerifyEmail setStep={setStep} userEmail={userEmail} />}
                {step === 3 && <ResetPassword setStep={setStep} />}
            </Box>
        </Container>
    );
}

function GetUserEmail({
    setStep,
    setUserEmail,
}: {
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setUserEmail: React.Dispatch<React.SetStateAction<string>>;
}) {
    const { mutate: getOtp, isSuccess, isLoading } = useGetOtp('talent');
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<{ email: string }>();

    const onSubmit = (values: { email: string }) => {
        getOtp(values?.email);
        setUserEmail(values?.email);
    };

    useEffect(() => {
        if (isSuccess) setStep(2);
    }, [isSuccess, setStep]);

    return (
        <Box mx="auto" maxW="520px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Forgot Password
            </Heading>
            <Text mt="32px" textAlign="center">
                Please enter the associated email ID to get verification code on.
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl mt="60px" isInvalid={Boolean(errors.email)}>
                    <Input
                        size="lg"
                        _autofill={{ bg: 'none' }}
                        focusBorderColor="primary.500"
                        placeholder="user@email.com"
                        {...register('email', {
                            required: 'Title required',
                        })}
                        variant="primary"
                    />
                    <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
                <Button mt="40px" type="submit" size="lg" w="100%" isLoading={isLoading}>
                    Get Verification Code
                </Button>
            </form>
        </Box>
    );
}

function ResetPassword({ setStep }: { setStep: React.Dispatch<React.SetStateAction<number>> }) {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const navigate = useNavigate();
    const { mutate: restPassword, isSuccess, isLoading } = useResetPassword();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<{ password: string; confirmPassword: string }>();

    const userPassword = watch('password');
    const onSubmit = (values: { password: string; confirmPassword: string }) => {
        if (values?.password) restPassword(values?.password);
    };

    useEffect(() => {
        if (isSuccess) {
            showToast('success', 'Password Reset Successfully!');
            localStorage.removeItem('otp_token');
            navigate('/sign-in');
        }
    }, [isSuccess, navigate, setStep]);

    return (
        <Box mx="auto" maxW="520px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Reset Password
            </Heading>
            <Text mt="32px" textAlign="center">
                Please enter the associated email ID to get verification code on.
            </Text>
            <Box as="form" mt="60px" onSubmit={handleSubmit(onSubmit)}>
                <FormControl isInvalid={Boolean(errors.password)} mb="25px">
                    <InputGroup size="md">
                        <Input
                            size="lg"
                            variant="primary"
                            focusBorderColor="primary.500"
                            {...register('password', {
                                required: 'Password is required',
                                validate: {
                                    isCharacter: (value: string) =>
                                        // eslint-disable-next-line
                                        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ||
                                        'Must contain one Special character',
                                    // eslint-disable-next-line
                                    isSmall: (value: string) =>
                                        /[a-z]/.test(value) || 'Must contain one Small letter',
                                    isCapital: (value: string) =>
                                        /[A-Z]/.test(value) || 'Must contain one Capital character',
                                    isDigit: (value: string) =>
                                        /\d/.test(value) || 'Must contain one Digit character',
                                },
                                minLength: {
                                    value: 8,
                                    message: 'Minimum length should be 8',
                                },
                            })}
                            pr="4.5rem"
                            type={show ? 'text' : 'password'}
                            placeholder="Password"
                            autoComplete="new-password"
                        />
                        <InputRightElement
                            onClick={() => setShow(!show)}
                            cursor="pointer"
                            // eslint-disable-next-line react/no-children-prop
                            children={<Icon as={show ? ShowIcon : ShowIcon} color="gray.400" />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.password && errors.password.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.confirmPassword)} mb="25px">
                    <InputGroup size="md">
                        <Input
                            size="lg"
                            variant="primary"
                            focusBorderColor="primary.500"
                            {...register('confirmPassword', {
                                required: 'Confirm Password is required',
                                validate: {
                                    isMatch: (value: string) =>
                                        userPassword === value || 'Password did not match',
                                },
                            })}
                            pr="4.5rem"
                            type={show2 ? 'text' : 'password'}
                            placeholder="Confirm Password"
                        />
                        <InputRightElement
                            onClick={() => setShow2(!show2)}
                            cursor="pointer"
                            // eslint-disable-next-line react/no-children-prop
                            children={<Icon as={show2 ? Hide : Show} color="gray.400" />}
                        />
                    </InputGroup>
                    <FormErrorMessage>
                        {errors.confirmPassword && errors.confirmPassword.message}
                    </FormErrorMessage>
                </FormControl>
                <Button mt="40px" type="submit" size="lg" w="100%" isLoading={isLoading}>
                    Get Verification Code
                </Button>
            </Box>
        </Box>
    );
}
