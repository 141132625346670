export default {
    baseStyle: { color: 'text' },
    variants: {
        primary: {
            color: 'white',
        },
        secondary: {
            color: 'primary.500',
        },
        link: {
            fontSize: '14px',
            '&.active': { color: 'primary.500' },
        },
        black: {
            color: 'heading',
        },
    },
};
