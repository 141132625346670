import { Box } from '@chakra-ui/layout';
import React, { useState } from 'react';

export default function StarRating() {
    const [rating, setRating] = useState(0);

    const stars = Array(5).fill(0); // Array to represent stars

    const handleClick = (index: number) => {
        setRating(index + 1);
    };

    return (
        <Box>
            {stars.map((_, index) => (
                <Box
                    key={index}
                    as="span"
                    fontSize="26px"
                    onClick={() => handleClick(index)}
                    color={index < rating ? 'yellow.400' : 'gray.300'}
                    cursor="pointer"
                >
                    ★
                </Box>
            ))}
        </Box>
    );
}
