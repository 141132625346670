import React from 'react';
import BannerImg from 'assets/images/horizontal-pattern.png';
import {
    Center,
    Heading,
    Text,
    Box,
    HStack,
    Tag,
    TagLabel,
    TagCloseButton,
} from '@chakra-ui/react';
import SearchInput from 'components/searchInput';

export default function Banner({
    title,
    subtitle,
    navList,
    categories,
}: {
    title: string;
    subtitle: string;
    navList?: string[];
    categories?: string[];
}) {
    return (
        <Center
            flexDirection="column"
            bg={`var(--chakra-colors-bgGray) url(${BannerImg}) no-repeat`}
            py="70px"
            bgSize="cover"
            mt="25px"
        >
            {navList && (
                <Text mb="32px" variant="secondary" whiteSpace="pre">
                    {navList?.join('  /   ')}
                </Text>
            )}
            <Heading mb="32px" fontSize="48px">
                {title}
            </Heading>
            <Text mb="30px">{subtitle}</Text>
            {categories && (
                <>
                    <Box mb="30px" w="100%" maxW="568px" mx="auto">
                        <SearchInput />
                    </Box>
                    <Text maxW="520px" w="100%">
                        Popular Categories:{' '}
                        <HStack display="inline-flex" spacing={4}>
                            {categories?.map(filter => (
                                <Tag key={filter} borderRadius="full" variant="subtle">
                                    <TagLabel>{filter}</TagLabel>
                                    <TagCloseButton />
                                </Tag>
                            ))}
                        </HStack>
                    </Text>
                </>
            )}
        </Center>
    );
}
