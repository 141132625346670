import React, { useEffect, useState } from 'react';
import {
    Heading,
    Box,
    Input,
    FormErrorMessage,
    FormControl,
    Button,
    InputGroup,
    InputRightElement,
    Text,
    Icon,
    Checkbox,
    Show,
    Hide,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AuthFormValues, User } from 'utils/types/user.type';
import { Link } from 'react-router-dom';
import { ShowIcon } from 'assets/icons';
import { useClientSignUp, useTalentSignUp } from 'utils/apis/auth.api';
import { getSignupType } from 'utils/helper';

export default function UserAuth({
    setUserStep,
    setUserData,
}: {
    setUserStep: React.Dispatch<React.SetStateAction<number>>;
    setUserData: React.Dispatch<React.SetStateAction<User | undefined>>;
}) {
    const { mutate: talentSignUp, isSuccess, isLoading, data: userData } = useTalentSignUp();
    const {
        mutate: clientSignUp,
        isSuccess: clientSuccess,
        isLoading: clientLoading,
        data: clientData,
    } = useClientSignUp();

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const {
        handleSubmit,
        register,
        formState: { errors },
        watch,
    } = useForm<AuthFormValues>();

    const isAgree = watch('terms');
    const userPassword = watch('password');

    const onSubmit = (values: AuthFormValues) => {
        console.log(getSignupType());
        if (getSignupType() === 'client') clientSignUp(values);
        if (getSignupType() === 'freelancer') talentSignUp(values);
    };

    useEffect(() => {
        if (isSuccess || clientSuccess) {
            setUserStep(3);
            if (userData || clientData) setUserData(userData?.user || clientData?.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, clientSuccess]);

    return (
        <Box minH="calc(100vh - 50px)">
            <Box w="100%" maxW="520px" mx="auto">
                <Heading fontSize={{ base: '24px', md: '31px' }}>
                    Sign Up and Unlock Opportunities
                </Heading>
                <Box mt="64px" as="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={Boolean(errors.name)} mb="25px">
                        <Input
                            size="lg"
                            _autofill={{ bg: 'none' }}
                            focusBorderColor="primary.500"
                            placeholder="John"
                            {...register('name', {
                                required: 'Name is required',
                            })}
                            variant="primary"
                        />
                        <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.email)} mb="25px">
                        <Input
                            size="lg"
                            _autofill={{ bg: 'none' }}
                            focusBorderColor="primary.500"
                            placeholder="john@mail.com"
                            {...register('email', {
                                required: 'Email is required',
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: 'Enter valid email.',
                                },
                            })}
                            variant="primary"
                        />
                        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.password)} mb="25px">
                        <InputGroup size="md">
                            <Input
                                size="lg"
                                variant="primary"
                                focusBorderColor="primary.500"
                                {...register('password', {
                                    required: 'Password is required',
                                    validate: {
                                        isCharacter: (value: string) =>
                                            // eslint-disable-next-line
                                            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ||
                                            'Must contain one Special character',
                                        // eslint-disable-next-line
                                        isSmall: (value: string) =>
                                            /[a-z]/.test(value) || 'Must contain one Small letter',
                                        isCapital: (value: string) =>
                                            /[A-Z]/.test(value) ||
                                            'Must contain one Capital character',
                                        isDigit: (value: string) =>
                                            /\d/.test(value) || 'Must contain one Digit character',
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Minimum length should be 8',
                                    },
                                })}
                                pr="4.5rem"
                                type={show ? 'text' : 'password'}
                                placeholder="Password"
                                autoComplete="new-password"
                            />
                            <InputRightElement
                                onClick={() => setShow(!show)}
                                cursor="pointer"
                                // eslint-disable-next-line react/no-children-prop
                                children={<Icon as={show ? ShowIcon : ShowIcon} color="gray.400" />}
                            />
                        </InputGroup>
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.confirmPassword)} mb="25px">
                        <InputGroup size="md">
                            <Input
                                size="lg"
                                variant="primary"
                                focusBorderColor="primary.500"
                                {...register('confirmPassword', {
                                    required: 'Confirm Password is required',
                                    validate: {
                                        isMatch: (value: string) =>
                                            userPassword === value || 'Password did not match',
                                    },
                                })}
                                pr="4.5rem"
                                type={show2 ? 'text' : 'password'}
                                placeholder="Confirm Password"
                            />
                            <InputRightElement
                                onClick={() => setShow2(!show2)}
                                cursor="pointer"
                                // eslint-disable-next-line react/no-children-prop
                                children={<Icon as={show2 ? Hide : Show} color="gray.400" />}
                            />
                        </InputGroup>
                        <FormErrorMessage>
                            {errors.confirmPassword && errors.confirmPassword.message}
                        </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.terms)} mb="25px">
                        <Checkbox
                            {...register('terms', {
                                required: 'Agree to our terms & conditions before register.',
                            })}
                            isChecked={isAgree}
                        >
                            Yes, I accept the{' '}
                            <Button variant="link" as="a" target="_blank" href="#">
                                terms & conditions
                            </Button>{' '}
                            and{' '}
                            <Button variant="link" as="a" target="_blank" href="#">
                                privacy policy
                            </Button>
                        </Checkbox>
                        <FormErrorMessage>{errors.terms && errors.terms.message}</FormErrorMessage>
                    </FormControl>
                    <Button isLoading={isLoading || clientLoading} type="submit" size="lg" w="100%">
                        Create Account
                    </Button>
                </Box>
                <Text mt="64px" textAlign="center">
                    Already have account?{' '}
                    <Button to="/login" as={Link} variant="link" type="submit">
                        Log in
                    </Button>
                </Text>
            </Box>
        </Box>
    );
}
