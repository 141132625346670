import React from 'react';
import { Box, Container, Flex, Heading, Highlight, List, Text } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/card';
import { Avatar, AvatarGroup } from '@chakra-ui/avatar';
import {
    Collaborate,
    ExternalLinkIcon,
    ProvideDetails,
    Purchase,
    Review,
    SheduleConsultation,
    ZoomMeeting,
} from 'assets/icons';
import StarRating from 'components/starRating';
import { Button } from '@chakra-ui/button';
import { WorkSteps } from 'views/landing';
import HowItWorks from 'assets/images/consultation.png';
import fixPrice from 'assets/images/fixed-price.png';

import { Image } from '@chakra-ui/image';
import { Tag } from '@chakra-ui/tag';
import Banner from 'components/banner';
import Header from 'components/header';
import Footer from 'components/footer';
import { Link } from 'react-router-dom';

export default function ProjectCatelog() {
    return (
        <>
            <Header />
            <Banner
                navList={['Development & IT', 'Development']}
                title="Blockchain, NFT, Cryptocurrency"
                subtitle="Get help with your next blockchain project."
                categories={['Blockchain Development']}
            />
            <Container maxW="1280px" my="3rem">
                <Heading fontSize="39px" fontWeight="500">
                    <Highlight query="Embrace the" styles={{ color: '#2D8CFF' }}>
                        Embrace the power of talent
                    </Highlight>
                </Heading>
                <Text>
                    From skilled designers to expert developers, find a diverse range of
                    professionals ready <br /> to collaborate and bring your projects to life
                </Text>

                <Flex gap="15px" wrap="wrap" mt="65px">
                    {[...Array(6)]?.map(item => (
                        <EmbraceTalent key={item} />
                    ))}
                </Flex>
                <Button mt="3rem" mx="auto" display="block">
                    Load More Categories
                </Button>
                <Box as="section" py="100px">
                    <Flex align="center" gap="50px" justify="center">
                        <Image maxW="545px" src={HowItWorks} />

                        <Box maxW="490px">
                            <Heading fontSize="40px">
                                <Highlight
                                    query="How consultations"
                                    styles={{ color: 'var(--chakra-colors-primary-500)' }}
                                >
                                    How consultations work?
                                </Highlight>
                            </Heading>
                            <Text my="30px">
                                Schedule a call, share project details, engage in a personalized
                                Zoom meeting, and receive helpful documents to move forward.
                            </Text>
                            <List spacing="30px">
                                <WorkSteps
                                    icon={SheduleConsultation}
                                    title="Schedule a Consultation"
                                    disc="Choose a convenient time slot for a 30 or 60-minute call with the consultant."
                                />
                                <WorkSteps
                                    icon={ProvideDetails}
                                    title="Provide Project Details"
                                    disc="Share information about your project and discussion topics."
                                />
                                <WorkSteps
                                    icon={ZoomMeeting}
                                    title="Zoom Meeting"
                                    disc="Engage in a one-on-one session for expert advice. Receive post-session documents to guide your progress."
                                />
                            </List>
                        </Box>
                    </Flex>
                </Box>
                <Box as="section" py="100px">
                    <Flex align="center" gap="50px" justify="center">
                        <Box maxW="490px">
                            <Heading fontSize="40px">
                                <Highlight
                                    query="How fixed-price"
                                    styles={{ color: 'var(--chakra-colors-primary-500)' }}
                                >
                                    How fixed-price projects work?
                                </Highlight>
                            </Heading>
                            <Text my="30px">
                                Purchase a project, collaborate with the professional, review and
                                approve the work upon satisfaction.
                            </Text>
                            <List spacing="30px">
                                <WorkSteps
                                    icon={Purchase}
                                    title="Purchase a project"
                                    disc="Discover ready-to-start, clearly defined work that matches your needs."
                                />
                                <WorkSteps
                                    icon={Collaborate}
                                    title="Collaborate and begin"
                                    disc="Share your requirements, and the professional will start working. Stay connected through project chat."
                                />
                                <WorkSteps
                                    icon={Review}
                                    title="Review and approve"
                                    disc="Evaluate the delivered work, providing your approval once you're satisfied."
                                />
                            </List>
                        </Box>
                        <Image maxW="545px" src={fixPrice} />
                    </Flex>
                </Box>

                <Heading fontSize="40px" textAlign="center">
                    <Highlight
                        query=" Browse all "
                        styles={{ color: 'var(--chakra-colors-primary-500)' }}
                    >
                        Browse all categories
                    </Highlight>
                </Heading>
                <Box maxW="800px" mx="auto" mt="4rem">
                    <Flex flexWrap="wrap" gap="10px">
                        {[
                            'Data Scientist',
                            'IT Coordinator',
                            'Frontend Engineer',
                            'Backend Engineer',
                            'Dev Ops',
                            'BlockChain Developer',
                            'defi',
                            'Data Scientist',
                            'IT Coordinator',
                            'Frontend Engineer',
                            'Backend Engineer',
                        ].map(name => (
                            <Tag
                                key={name}
                                borderRadius="full"
                                variant="outline"
                                colorScheme="gray"
                            >
                                {name}
                            </Tag>
                        ))}
                    </Flex>
                </Box>
            </Container>
            <Footer />
        </>
    );
}
function EmbraceTalent() {
    return (
        <>
            <Card as={Link} to="/job-listing" minW="302px" flex="1" p="24px">
                <Heading as="h6" fontSize="25px">
                    Blockchain Development
                </Heading>

                <Flex mt=" 30px" justify="space-between">
                    <Flex align="center" gap="7px">
                        <AvatarGroup size="md" max={3}>
                            <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                            <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                            <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                        </AvatarGroup>
                        <Text fontSize="16px" fontWeight={500}>
                            25K+ Talent
                        </Text>
                    </Flex>
                </Flex>
                <Flex justify="space-between" mt="1.4rem" align="center">
                    <Box>
                        <Text fontWeight={500}>Average Ratings</Text>
                        <StarRating />
                    </Box>
                    <ExternalLinkIcon />
                </Flex>
            </Card>
        </>
    );
}
