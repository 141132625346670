import React, { useState } from 'react';
import {
    Card,
    CardBody,
    Divider,
    Flex,
    HStack,
    Heading,
    Icon,
    Tag,
    TagLabel,
    Text,
    Box,
    Button,
    VStack,
    RadioGroup,
    Radio,
    InputGroup,
    Input,
    InputRightAddon,
    Center,
    Select,
    Textarea,
    Image,
    FormLabel,
    FormControl,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox,
} from '@chakra-ui/react';
import { PercentageIcon, PriceProtectionIcon, PriceTagIcon, ProjectIcon } from 'assets/icons';
import Footer from 'components/footer';
import Header from 'components/header';
import UploadFile from 'assets/images/upload-file.png';

export default function JobProposal() {
    const [projectType, setProjectType] = useState('byProject');
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Header />
            <Card mt="80px" maxW="1280px" mx="auto">
                <CardBody p="24px">
                    <Flex gap="34px" justify="space-between">
                        <Heading fontSize="31px">Submit Proposal</Heading>
                        <Text
                            as="span"
                            bg="primary.50"
                            color="primary.500"
                            p="8px 14px"
                            borderRadius="4px"
                            whiteSpace="nowrap"
                        >
                            <strong>13</strong> / 100 Connects Required <Icon />
                        </Text>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between">
                        <Heading as="h4" fontSize="20px">
                            Job Details
                        </Heading>
                        <Text>
                            <strong>Job Posted On:</strong> May 30, 2023
                        </Text>
                    </Flex>
                    <Flex mt="50px" gap="32px">
                        <Box flex="1">
                            <Tag variant="solid">
                                <TagLabel>Blockchain Development</TagLabel>
                            </Tag>
                            <Text my="30px">
                                Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit
                                fusce diam tincidunt nibh nibh commodo. Sed in adipiscing blandit
                                nullam. Aliquet orci porta fermentum sed sit pretium. Scelerisque
                                consectetur nisl feugiat quam dictum vulputate porttitor turpis sit.
                                Aliquet dapibus aliquam enim orci elit congue justo pharetra ut.
                                Scelerisque consequat faucibus ultricies at id. Et aenean ut varius
                                risus ultrices mattis elementum morbi luctus. Leo cras ipsum metus
                                imperdiet. Tortor consequat elit etiam felis nunc auctor.
                            </Text>
                            <Heading as="h4" fontSize="16px">
                                Skills & Expertise
                            </Heading>
                            <HStack mt="24px" flexWrap="wrap">
                                {[...Array(16)]?.map(item => (
                                    <Tag key={item}>
                                        <TagLabel>Solidity</TagLabel>
                                    </Tag>
                                ))}
                            </HStack>
                        </Box>
                        <VStack spacing="16px">
                            <ProjectUtils label="Experience Level" value="Intermediate" />
                            <ProjectUtils label="Project Length" value="1 - 3 Months" />
                            <ProjectUtils label="Propose your term" value="Fixed price" />
                        </VStack>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        Terms
                    </Heading>
                    <Text mt="40px">How do you want to be paid?</Text>
                    <RadioGroup value={projectType} onChange={val => setProjectType(val)}>
                        <Radio mt="32px" gap="10px" value="byProject">
                            <Heading as="h6" fontSize="14px">
                                By Project
                            </Heading>
                            <Text>
                                Get your entire payment at the end, when all work has been
                                delivered.
                            </Text>
                        </Radio>
                        <Radio mt="32px" value="byMiles" gap="10px">
                            <Heading as="h6" fontSize="14px">
                                By Milestones
                            </Heading>
                            <Text>
                                Divide the project into smaller segments, called milestones.{' '}
                                {`You'll`}
                                be paid for milestones as they are completed & approved.
                            </Text>
                        </Radio>
                    </RadioGroup>
                    {projectType === 'byMiles' && (
                        <>
                            <Text my="30px">How many milestones do you want to include?</Text>
                            <Box rounded="16px" bg="bgGray" p="24px">
                                <Heading as="h6" fontSize="16px" mb="24px">
                                    Milestones#1
                                </Heading>
                                <Text as="span" color="secondaryText">
                                    Description
                                </Text>
                                <Textarea
                                    mt="15px"
                                    bg="white"
                                    h="140px"
                                    rounded="8px"
                                    border="1px solid #D4D4D4"
                                    placeholder="Your Message here"
                                    p="24px 8px 8px 24px"
                                />
                                <Flex mt="30px" gap="24px">
                                    <FormControl>
                                        <FormLabel color="secondaryText">Amount</FormLabel>

                                        <InputGroup bg="white" borderColor="border">
                                            <Input borderRight="none" focusBorderColor="inherit" />
                                            <InputRightAddon bg="transparent">
                                                <Text fontSize="16px" variant="secondary">
                                                    USDT
                                                </Text>
                                            </InputRightAddon>
                                        </InputGroup>
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel color="secondaryText">Due Date</FormLabel>
                                        <InputGroup bg="white" borderColor="border">
                                            <Input borderRight="none" focusBorderColor="inherit" />
                                            <InputRightAddon bg="transparent">
                                                <Text fontSize="16px" variant="secondary">
                                                    USDT
                                                </Text>
                                            </InputRightAddon>
                                        </InputGroup>
                                    </FormControl>
                                </Flex>
                            </Box>
                        </>
                    )}
                    <Flex mt="40px">
                        <Box p="32px" maxW="600px" w="100%">
                            <Box maxW="420px" mx="auto" textAlign="center">
                                <Heading as="h6" fontSize="20px">
                                    What is the full amount you’d like to bid for this project
                                </Heading>
                                <Text mt="10px">
                                    Total amount the client will see on your proposal
                                </Text>
                            </Box>
                            <InputGroup mt="30px" borderColor="border">
                                <Input borderRight="none" focusBorderColor="inherit" />
                                <InputRightAddon bg="transparent">
                                    <Text fontSize="16px" variant="secondary">
                                        USDT
                                    </Text>
                                </InputRightAddon>
                            </InputGroup>
                            <Divider my="30px" borderColor="border" />
                            <Flex bg="bgGray" gap="16px" p="16px" rounded="8px">
                                <PriceTagIcon />
                                <Text>Total Project Price</Text>
                                <Text ml="auto" color="heading">
                                    400 USDT
                                </Text>
                            </Flex>
                            <Flex mt="16px" bg="bgGray" gap="16px" p="16px" rounded="8px">
                                <PercentageIcon />
                                <Text>Stoptalent Freelance Fee</Text>
                                <Icon />
                                <Text ml="auto" color="heading">
                                    1%
                                </Text>
                            </Flex>
                            <Divider my="30px" borderColor="border" />
                            <Flex justify="space-between" align="center">
                                <Text fontSize="20px" fontWeight={700}>
                                    Amount You Get
                                </Text>
                                <Text fontSize="32px">
                                    394.00{' '}
                                    <Text fontSize="20px" as="span" variant="secondary">
                                        USDT
                                    </Text>
                                </Text>
                            </Flex>
                        </Box>
                        <Center flex="1" flexDirection="column" p="32px">
                            <PriceProtectionIcon />
                            <Text fontSize="32px" textAlign="center" mt="32px">
                                Includes StopTalent Fixed-Price Protection. Learn more
                            </Text>
                        </Center>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="20px">
                        Additional Details
                    </Heading>
                    <Text mt="40px">How long this project will take?</Text>
                    <Select mt="16px">
                        <option>1 Day</option>
                    </Select>
                    <Heading as="h4" fontSize="16px" mt="40px">
                        Add a Cover letter
                    </Heading>
                    <Textarea mt="30px" minH="300px" p="24px" borderColor="border" />
                    <Box mt="32px" border="1.5px dashed #D4D4D4" py="50px" rounded="8px">
                        <Image src={UploadFile} maxW="154px" mx="auto" />
                        <Text textAlign="center" mt="25px" color="heading">
                            <Button variant="link">Click to browse</Button> or drag & drop
                            attachments files
                        </Text>
                        <Text textAlign="center" mt="20px" fontSize="14px">
                            Upload pdf, doc, jpeg, png files only of maximum size 30 MBs
                        </Text>
                    </Box>
                    <Text maxW="835px" mx="auto" textAlign="center" fontSize="13px" mt="32px">
                        You may attach up to files unfer the size of 25 mbs each. Include work
                        samples or other documents to support your application. Do not attach ypur
                        resume - your Stoptalent profile is automatically forwarded to the client
                        with your proposal.
                    </Text>
                    <Button
                        w="300px"
                        display="flex"
                        mx="auto"
                        size="lg"
                        mt="60px"
                        onClick={() => onOpen()}
                    >
                        Submit Proposal
                    </Button>
                </CardBody>
            </Card>
            <SubmitProposalModal isOpen={isOpen} onClose={onClose} />
            <Footer />
        </>
    );
}

function ProjectUtils({ label, value }: { label: string; value: string }) {
    return (
        <Flex
            bg="bgGray"
            gap="16px"
            p="20px 24px"
            minW="220px"
            maxW="max-content"
            w="100%"
            align="center"
        >
            <ProjectIcon />
            <Box>
                <Text variant="secondary" fontSize="14px">
                    {label}
                </Text>
                <Text mt="10px" color="heading" variant="secondary">
                    {value}
                </Text>
            </Box>
        </Flex>
    );
}

function SubmitProposalModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
    return (
        <Modal isOpen={isOpen} closeOnOverlayClick={false} isCentered onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader color="heading" fontWeight="600">
                    3 things you need to know
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text>
                        You are submitting for the fixed-price project. While tg=he majority
                        Stoptalent projects are completed successfully, please keep a few things in
                        mind:
                    </Text>

                    <Text fontWeight="600" variant="black" my="15px">
                        1. Stoptalent protection is in place for fixed-price jobs
                    </Text>
                    <Text>
                        Before you start the project, you and the client must agree to requirements,
                        a budget and milestone.
                        <br /> <br /> Stoptalent charges the client st the beginning of the project,
                        and the money for a milestone is deposited in USDT
                    </Text>
                    <Text fontWeight="600" variant="black" my="15px">
                        2. USDT funded payments are released when the client approves work
                    </Text>
                    <Text>
                        When milestone are completed, the client can either approve the work and
                        release payment or request modification to the work. Client can also request
                        that you approve the return of funds held in USDT.
                    </Text>
                    <Text fontWeight="600" variant="black" my="15px">
                        3. Stoptalent offers meditation services
                    </Text>
                    <Text>
                        If you do the fixed price jobs carefully. Only funds deposited for an active
                        milestone are coveres by USDT protection.
                    </Text>
                    <Checkbox my="30px" defaultChecked>
                        <Text>Yes, I understand</Text>
                    </Checkbox>
                    <Divider />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={onClose}>
                        cancel
                    </Button>
                    <Button onClick={onClose}>Continue Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}
