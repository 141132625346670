import React, { useEffect, useState } from 'react';
import {
    Heading,
    Box,
    Input,
    FormErrorMessage,
    FormControl,
    Button,
    InputGroup,
    InputRightElement,
    Text,
    Icon,
    Center,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { AuthFormValues } from 'utils/types/user.type';
import { Link, useNavigate } from 'react-router-dom';
import { ShowIcon } from 'assets/icons';
import { useTalentLogin } from 'utils/apis/auth.api';

export default function UserAuth({
    setUserStep,
}: {
    setUserStep: React.Dispatch<React.SetStateAction<number>>;
}) {
    const navigate = useNavigate();
    const { mutate: talentLogin, isSuccess, isLoading, data: userData } = useTalentLogin();

    const [show, setShow] = useState(false);
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<AuthFormValues>();

    const onSubmit = (values: AuthFormValues) => {
        console.log(values);
        talentLogin(values);
    };

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('token', userData?.token?.access_token?.split(' ')[1]);
            localStorage.setItem('userId', userData?.user?.id);
            navigate('/profile');
        }
    }, [isSuccess, userData, setUserStep, navigate]);

    return (
        <Center minH="calc(100vh - 50px)">
            <Box w="100%" maxW="520px" mx="auto">
                <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center">
                    Sign In
                </Heading>
                <Box mt="64px" as="form" onSubmit={handleSubmit(onSubmit)}>
                    <FormControl isInvalid={Boolean(errors.email)} mb="25px">
                        <Input
                            size="lg"
                            _autofill={{ bg: 'none' }}
                            focusBorderColor="primary.500"
                            placeholder="john@mail.com"
                            {...register('email', {
                                required: 'Email is required',
                                pattern: {
                                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                    message: 'Enter valid email.',
                                },
                            })}
                            variant="primary"
                        />
                        <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.password)} mb="25px">
                        <InputGroup size="md">
                            <Input
                                size="lg"
                                variant="primary"
                                focusBorderColor="primary.500"
                                {...register('password', {
                                    required: 'Password is required',
                                    validate: {
                                        isCharacter: (value: string) =>
                                            // eslint-disable-next-line
                                            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value) ||
                                            'Must contain one Special character',
                                        // eslint-disable-next-line
                                        isSmall: (value: string) =>
                                            /[a-z]/.test(value) || 'Must contain one Small letter',
                                        isCapital: (value: string) =>
                                            /[A-Z]/.test(value) ||
                                            'Must contain one Capital character',
                                        isDigit: (value: string) =>
                                            /\d/.test(value) || 'Must contain one Digit character',
                                    },
                                    minLength: {
                                        value: 8,
                                        message: 'Minimum length should be 8',
                                    },
                                })}
                                pr="4.5rem"
                                type={show ? 'text' : 'password'}
                                placeholder="Password"
                                autoComplete="new-password"
                            />
                            <InputRightElement
                                onClick={() => setShow(!show)}
                                cursor="pointer"
                                // eslint-disable-next-line react/no-children-prop
                                children={<Icon as={show ? ShowIcon : ShowIcon} color="gray.400" />}
                            />
                        </InputGroup>
                        <FormErrorMessage>
                            {errors.password && errors.password.message}
                        </FormErrorMessage>
                    </FormControl>
                    <Button
                        variant="link"
                        as={Link}
                        to="/forgot-password"
                        ml="auto"
                        display="flex"
                        mb="32px"
                        w="max-content"
                    >
                        Forgot password?
                    </Button>
                    <Button isLoading={isLoading} type="submit" size="lg" w="100%">
                        Login
                    </Button>
                </Box>
                <Text mt="64px" textAlign="center">
                    Not Registered Yet?{' '}
                    <Button to="/sign-up" as={Link} variant="link" type="submit">
                        Create Account
                    </Button>
                </Text>
            </Box>
        </Center>
    );
}
