import { useMutation } from '@tanstack/react-query';
import { AuthFormValues, VerifyEmail, User } from 'utils/types/user.type';
import { client } from './api-client';

export const useTalentLogin = () =>
    useMutation<{ token: { access_token: string }; user: User }, Error, AuthFormValues>(data =>
        client('auth/talentLogin', {
            data,
        }),
    );

export const useTalentSignUp = () =>
    useMutation<{ user: User }, Error, AuthFormValues>(data =>
        client('auth/talentSignUp', {
            data,
        }),
    );

export const useClientSignUp = () =>
    useMutation<{ user: User }, Error, AuthFormValues>(data =>
        client('auth/clientSignUp', {
            data,
        }),
    );

export const useVerifyEmail = (role: string) =>
    useMutation<{ user: User; token: { access_token: string } }, Error, VerifyEmail>(data =>
        client(`auth/verifyEmailOtp?role=${role}`, {
            data,
        }),
    );

export const useEmailExist = () =>
    useMutation<
        {
            status: boolean;
            user: {
                loginType: 'GOOGLE';
            };
        },
        Error,
        string
    >(email =>
        client('auth/isEmailExists', {
            data: { email },
        }),
    );

export const useGetOtp = (role: string) =>
    useMutation<unknown, Error, string>(email =>
        client(`auth/forgotPassword?role=${role}`, {
            data: { email },
        }),
    );

export const useVerifyOtp = (role: string) =>
    useMutation<
        {
            token: {
                access_token: string;
            };
        },
        Error,
        VerifyEmail
    >(data =>
        client(`auth/verifyForgotPasswordOtp?role=${role}`, {
            data,
        }),
    );

export const useReSendOtp = () =>
    useMutation<unknown, Error, string>(email =>
        client('auth/resendOtp', {
            data: { email },
        }),
    );

export const useResetPassword = () =>
    useMutation<unknown, Error, string>(password =>
        client('auth/resetPassword', {
            data: { password },
            headers: {
                Authorization: localStorage.getItem('otp_token') || '',
            },
        }),
    );

export const useChangePassword = () =>
    useMutation<unknown, Error, string>(password =>
        client('user/changePassword', {
            data: { password },
        }),
    );
