import {
    Card,
    Container,
    Flex,
    Avatar,
    Box,
    Text,
    Heading,
    Divider,
    Button,
    ButtonGroup,
    VStack,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import React from 'react';
import {
    CalenderIcon,
    HiredBudgetIcon,
    HiredTimeLineIcon,
    JobHiredIcon,
    ChatIcon,
} from 'assets/icons/user';
import { Link } from 'react-router-dom';

export default function MyProposalDetail() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="50px">
                <Card p="24px">
                    <Flex gap="30px" justify="space-between" align="center">
                        <Avatar
                            size="2xl"
                            outline="5px solid #59C778"
                            p="2px"
                            border="3px solid white"
                        />
                        <Box flex="1">
                            <Heading as="h2" mb="32px">
                                Paul Walker Jr
                            </Heading>
                            <Text fontSize="20px">Monday 10:28:48 AM, January 8th, 2023</Text>
                        </Box>
                        <ButtonGroup ml="auto">
                            <Button leftIcon={<ChatIcon />} size="lg">
                                Chat now
                            </Button>
                            <Button as={Link} to="/give-refund" size="lg" variant="secondary">
                                Give a refund
                            </Button>
                        </ButtonGroup>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="32px">
                        Consultancy for solidity smart contract on Polygon
                    </Heading>
                    <Text
                        as="div"
                        variant="primary"
                        bg="#F4B000"
                        p="10px 12px"
                        w="126px"
                        rounded="8px"
                        textAlign="center"
                        my="40px"
                    >
                        Pending
                    </Text>

                    <Text as="h6" variant="black" fontSize="20px">
                        Description
                    </Text>
                    <Button
                        variant="link"
                        as="a"
                        href="#"
                        fontSize="14px"
                        my="24px"
                        justifyContent="flex-start"
                    >
                        https://docs.google.com/spreadsheetsd/1AKcUOF24/sadai13-IMIxMIYedit?usp
                        sharing
                    </Button>
                    <Text>
                        Lorem ipsum dolor sit amet consectetur. Quisque proin varius elit volutpat
                        odio posuere. In nullam bibendum blandit platea dictum arcu. Odio sit ipsum
                        lacus vulputate lacus amet eget ullamcorper. Neque mattis sed dictum dolor
                        amet. Quis tempus aliquam posuere sapien blandit maecenas arcu. Duis non
                        quisque sit vel ut amet leo netus elit. Interdum mollis praesent eu ut elit
                        facilisi. Eget sit sollicitudin purus quis semper a in. Condimentum viverra
                        mi euismod vitae. Mi feugiat maecenas lorem ornare ut mi faucibus nunc
                        dictum. Sit sed nibh ut elit lectus suspendisse.
                        <br />
                        <br />
                        Gravida phasellus dolor ipsum egestas consequat mattis. Et eget a gravida
                        orci egestas neque magna. Est scelerisque ullamcorper nec congue in tellus.
                        Suscipit nibh sagittis egestas at. Morbi proin dictum commodo at eget eget
                        non. Nisl nulla aliquet proin integer amet facilisi feugiat placerat. At
                        morbi blandit odio vitae sagittis volutpat eros leo. Dictum vitae vel sit
                        ultricies. Non non tellus sit pharetra duis etiam. Eget lacus quis pretium
                        posuere morbi lobortis ultrices. Integer eros enim facilisis ut urna. Diam
                        fusce platea tortor dolor arcu. Ultrices massa semper nisi quam netus nunc
                        purus augue non. Semper pulvinar urna risus at libero. Tellus eget etiam
                        risus orci quam.
                    </Text>
                    <Flex mt="40px" gap="24px">
                        <ContractUtils
                            icon={<JobHiredIcon />}
                            label="Job Category"
                            value="Full Stack Development"
                        />
                        <ContractUtils
                            icon={<HiredBudgetIcon />}
                            label="Budget"
                            value={
                                <>
                                    <Text fontSize="22px" as="strong">
                                        200.30
                                    </Text>{' '}
                                    USDT
                                </>
                            }
                        />
                        <ContractUtils
                            icon={<HiredTimeLineIcon />}
                            label="Offer Date"
                            value="08-01-2023 - 13-06-2023"
                        />
                        <ContractUtils
                            icon={<HiredTimeLineIcon />}
                            label="Offer Expire"
                            value="08-01-2023 - 13-06-2023"
                        />
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Heading as="h4" fontSize="32px">
                        Contract Details
                    </Heading>
                    <VStack
                        sx={{ ' >div:first-child': { p: 0 } }}
                        mt="40px"
                        flexDirection="column-reverse"
                        spacing="0"
                    >
                        {[...Array(3)]?.map((item, i) => (
                            <Flex
                                pb="35px"
                                key={item}
                                align="center"
                                gap="40px"
                                position="relative"
                                _after={{
                                    content: `''`,
                                    position: 'absolute',
                                    h: '100%',
                                    border: '1px dashed black',
                                    top: 0,
                                    left: '25px',
                                    zIndex: 0,
                                    borderColor: 'border',
                                }}
                            >
                                <Avatar
                                    name={(i + 1)?.toString()}
                                    zIndex="2"
                                    bg="white"
                                    border="2px solid"
                                    borderColor="text"
                                    color="text"
                                />
                                <Card flex="1" p="24px">
                                    <Flex justify="space-between">
                                        <Text as="h6" fontSize="25px" variant="black">
                                            #0{i + 1} Milestone
                                        </Text>
                                        <Text>
                                            <CalenderIcon /> Due on:{' '}
                                            <Text as="span" variant="secondary">
                                                25-05-2023
                                            </Text>
                                        </Text>
                                    </Flex>
                                    <Text my="25px">
                                        <Text as="strong" fontSize="20px">
                                            2000.30
                                        </Text>{' '}
                                        USDT
                                    </Text>
                                    <Text>
                                        Lorem ipsum dolor sit amet consectetur. Libero quisque
                                        vulputate convallis mauris a amet et. Elit diam ultrices
                                        massa in elit nec auctor et in. Suspendisse consectetur.
                                    </Text>
                                </Card>
                            </Flex>
                        ))}
                    </VStack>
                    <ButtonGroup mt="40px" ml="auto">
                        <Button variant="light" size="lg" w="160px">
                            Reject
                        </Button>
                        <Button size="lg" w="160px">
                            Accept
                        </Button>
                    </ButtonGroup>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ContractUtils({
    icon,
    label,
    value,
    isEdit,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
}) {
    return (
        <Card
            as={Flex}
            flexDirection="row"
            maxW="300px"
            minW="max-content"
            p="24px 16px"
            align="center"
            gap="20px"
            w="100%"
        >
            {icon}
            <Box>
                <Text display="block" as="span" variant="secondary" fontSize="20px">
                    {label}
                </Text>
                <Text as="span">{value}</Text>
            </Box>
            {isEdit}
        </Card>
    );
}
