import {
    Card,
    Container,
    Heading,
    Divider,
    Flex,
    Box,
    Text,
    Input,
    CheckboxGroup,
    Checkbox,
    ButtonGroup,
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Icon,
    Tag,
    TagCloseButton,
    TagLabel,
    RadioGroup,
    Radio,
    InputGroup,
    InputRightAddon,
    Textarea,
    Image,
    Center,
} from '@chakra-ui/react';
import { CancelIcon } from 'assets/icons/messages';
import { CancelTagIcon, HiredBudgetIcon } from 'assets/icons/user';
import { chakraComponents } from 'chakra-react-select';
import Footer from 'components/footer';
import Header from 'components/header';
import AppSelect from 'components/select';
import React, { useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext, Controller } from 'react-hook-form';
import { ADD_JOB_POINT } from 'utils/const';
import CountriesList from 'utils/data/languages.json';
import postDoneImg from 'assets/images/job-post-done.png';

export default function JobPosting() {
    const [step, setStep] = useState(1);

    const methods = useForm<{ skills: string[] }>();

    const onSubmit = (values: { skills: string[] }) => console.log(values);
    const isSuccess = false;
    return (
        <>
            <Header />
            <Container maxW="1280px" py="60px">
                <Card p="24px">
                    {isSuccess ? (
                        <JobPostDone />
                    ) : (
                        <>
                            <Heading fontSize="31px">Post a new jobs</Heading>
                            <Divider my="40px" borderColor="border" />
                            <Flex justify="space-between">
                                <Box maxW="550px" w="100%">
                                    <Text
                                        as="span"
                                        variant="secondary"
                                        pb="8px"
                                        borderBottom="1px solid"
                                        borderColor="primary.500"
                                        pr="30px"
                                    >
                                        <Text as="strong" variant="secondary">
                                            0{step}
                                        </Text>{' '}
                                        / 06
                                    </Text>
                                    <Text as="h6" fontSize="20px" my="30px">
                                        {ADD_JOB_POINT[step - 1]?.title}
                                    </Text>
                                    <Heading as="h2" fontSize="31px">
                                        {ADD_JOB_POINT[step - 1]?.question}
                                    </Heading>
                                    <Text
                                        mt="32px"
                                        dangerouslySetInnerHTML={{
                                            __html: ADD_JOB_POINT[step - 1]?.detail,
                                        }}
                                    />
                                </Box>
                                <Box border="1px solid" borderColor="border" />
                                <FormProvider {...methods}>
                                    <Box as="form" maxW="520px" w="100%">
                                        {step === 1 && <Step1 />}
                                        {step === 2 && <Step2 />}
                                        {step === 3 && <Step3 />}
                                        {step === 4 && <Step4 />}
                                        {step === 5 && <Step5 />}
                                        {step === 6 && <Step6 />}
                                        <ButtonGroup
                                            justifyContent="space-between"
                                            w="100%"
                                            mt="80px"
                                        >
                                            <Button
                                                variant="light"
                                                size="lg"
                                                minW="134px"
                                                onClick={() => step > 1 && setStep(step - 1)}
                                            >
                                                Back
                                            </Button>
                                            <Button
                                                size="lg"
                                                onClick={() =>
                                                    step < 6
                                                        ? setStep(step + 1)
                                                        : methods.handleSubmit(onSubmit)
                                                }
                                            >
                                                Continue & Next, Add Skills
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                </FormProvider>
                            </Flex>
                        </>
                    )}
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function Step1() {
    return (
        <>
            <Text>Write a title for your job</Text>
            <Input mt="16px" mb="24px" variant="primary" size="lg" />
            <Text mb="40px">
                We will connect you with the talent that specializes in{' '}
                <Text as="strong">Frontend Development.</Text>
            </Text>
            <Text fontWeight="600" as="h6">
                Job Category
            </Text>
            <Text mt="16px" mb="24px">
                Using your title, Stoptalent will propose a relevant job category. If {`it's`} not
                quite right, you can manually choose a more accurate category and specialty.
            </Text>
            <CheckboxGroup>
                <Checkbox display="flex" mb="16px">
                    Frontend Development
                </Checkbox>
                <Checkbox display="flex" mb="16px">
                    Backend Development
                </Checkbox>
                <Checkbox display="flex" mb="16px">
                    Web Development
                </Checkbox>
            </CheckboxGroup>
        </>
    );
}

function Step2() {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext<{ skills: string[]; skill: string }>();

    const [skills, setSkills] = useState<string[]>([]);

    const handleRemoveSkill = (index: number) => {
        setValue(
            'skills',
            skills.filter(item => item !== skills[index]),
        );
        setSkills(skills.filter(item => item !== skills[index]));
    };

    const mergedArray = useMemo(() => {
        const customSkills = [
            'Mobile Development',
            'Frontend',
            'Flutter/ Dart',
            'IOS',
            'Tokenization',
            'Dart',
            'UI/UX',
            'Development',
        ];
        return Array.from(new Set([...customSkills, ...skills]))?.map(item => ({
            label: item,
            value: item,
        }));
    }, [skills]);

    return (
        <>
            <FormControl isInvalid={Boolean(errors?.skill)} mb="10px">
                <FormLabel>Skills</FormLabel>
                <Controller
                    control={control}
                    name="skill"
                    render={({ field: { onChange, ref, value } }) => (
                        <AppSelect
                            onChange={(newValue: any) => {
                                onChange('');
                                setValue('skills', [...skills, newValue?.value]);
                                setSkills([...skills, newValue?.value]);
                            }}
                            value={value}
                            options={mergedArray}
                            chakraStyles={{
                                dropdownIndicator: provided => ({
                                    ...provided,
                                    display: 'none',
                                }),
                            }}
                            components={{
                                ClearIndicator: props => (
                                    <chakraComponents.ClearIndicator {...props}>
                                        <Icon as={CancelIcon} w={4} h={4} />
                                    </chakraComponents.ClearIndicator>
                                ),
                            }}
                            selectedOptionStyle="color"
                            placeholder="Search Skills"
                            refProp={ref}
                            isSearchable
                            useBasicStyles
                        />
                    )}
                />
                <FormErrorMessage>{errors.skills && errors.skills?.message}</FormErrorMessage>
            </FormControl>
            <Text as="span" fontSize="13px">
                Add 3 - 5 skills for the best results.
            </Text>
            <Text my="16px">Selected Skills</Text>
            <Flex
                bg="white"
                rounded="8px"
                border="1px solid #D4D4D4"
                p="16px"
                gap="16px"
                wrap="wrap"
                minH="140px"
                align="flex-start"
            >
                {skills?.length ? (
                    skills?.map((filter, i) => (
                        <Tag key={filter} borderRadius="full" variant="outline">
                            <TagLabel>{filter}</TagLabel>
                            <TagCloseButton opacity="1" onClick={() => handleRemoveSkill(i)}>
                                <CancelTagIcon />
                            </TagCloseButton>
                        </Tag>
                    ))
                ) : (
                    <Text color="placeholder">Add skills </Text>
                )}
            </Flex>
            <Text my="16px">Suggested Skills</Text>
            <Flex gap="16px" wrap="wrap" minH="100px" align="flex-start">
                <Tag
                    borderRadius="full"
                    variant="outline"
                    sx={{ '--badge-color': 'var(--chakra-colors-text)' }}
                >
                    <TagLabel>Software engineer</TagLabel>
                </Tag>
            </Flex>
        </>
    );
}

function Step3() {
    return (
        <>
            <Text fontWeight="600">How long the project will take?</Text>
            <RadioGroup mt="16px">
                <Radio display="flex" mb="16px" value="6+">
                    More than 6 months
                </Radio>
                <Radio display="flex" mb="16px" value="3-6">
                    3 - 6 months
                </Radio>
                <Radio display="flex" mb="16px" value="1-6">
                    1 - 6 months
                </Radio>
            </RadioGroup>
            <Text mt="40px" fontWeight="600">
                Which level of experience will it need?
            </Text>
            <RadioGroup mt="16px">
                <Radio display="flex" mb="16px" value="entry">
                    Entry
                    <Text>Looking for someone relatively new to the field.</Text>
                </Radio>
                <Radio display="flex" mb="16px" value="intermediate">
                    Intermediate
                    <Text>Looking for substantial experience in the field.</Text>
                </Radio>
                <Radio display="flex" mb="16px" value="expert">
                    Expert
                    <Text>Looking for comprehensive & deep expertise in this field.</Text>
                </Radio>
            </RadioGroup>
        </>
    );
}

function Step4() {
    return (
        <>
            <FormControl mb="24px">
                <FormLabel>Enter Amount</FormLabel>
                <InputGroup size="lg">
                    <Input defaultValue="200.30" variant="primary" borderRight="none" />
                    <InputRightAddon bg="transparent" color="primary.500" borderColor="border">
                        USDT
                    </InputRightAddon>
                </InputGroup>
                <FormErrorMessage></FormErrorMessage>
            </FormControl>
            <Text mb="40px" fontSize="13px">
                For the projects, you and the engaged talent agree on a predetermined budget.
                Payment can be made upfront or by milestone, dividing the project into manageable
                portions. Escrow holds funds until work approval for secure transactions.
            </Text>
            <Card
                as={Flex}
                flexDirection="row"
                flex="150px"
                minW="max-content"
                p="24px 16px"
                align="center"
                gap="20px"
            >
                <HiredBudgetIcon />
                <Box>
                    <Text display="block" as="span" variant="secondary" fontSize="20px">
                        Project Budget
                    </Text>
                    <Text as="span">200.30 USDT</Text>
                </Box>
            </Card>
        </>
    );
}

function Step5() {
    return (
        <>
            <FormControl>
                <FormLabel>Job Description</FormLabel>
                <Textarea minH="380px" variant="primary" maxH="435px" />
                <FormErrorMessage></FormErrorMessage>
            </FormControl>
        </>
    );
}

function Step6() {
    const {
        control,
        formState: { errors },
    } = useFormContext<{ languages: { language: string; level: string } }>();
    return (
        <>
            <Flex gap="16px">
                <FormControl w="calc(50% - 8px)" isInvalid={Boolean(errors?.languages?.language)}>
                    <FormLabel>Language</FormLabel>
                    <Controller
                        control={control}
                        name={`languages.language`}
                        render={({ field: { onChange, ref } }) => (
                            <AppSelect
                                onChange={newValue =>
                                    onChange((newValue as { label: string })?.label)
                                }
                                size="lg"
                                options={CountriesList}
                                selectedOptionStyle="color"
                                placeholder="Select language"
                                refProp={ref}
                                isSearchable
                                useBasicStyles
                                defaultValue={{ label: 'English', value: 'english' }}
                            />
                        )}
                    />
                    <FormErrorMessage>
                        {errors.languages?.language && errors.languages?.language?.message}
                    </FormErrorMessage>
                </FormControl>
                <FormControl w="calc(50% - 8px)" isInvalid={Boolean(errors.languages?.level)}>
                    <FormLabel>Proficiency Level</FormLabel>
                    <Controller
                        control={control}
                        name={`languages.level`}
                        render={({ field: { onChange, ref } }) => (
                            <AppSelect
                                size="lg"
                                onChange={newValue =>
                                    onChange((newValue as { label: string })?.label)
                                }
                                options={[
                                    { label: 'Basic', value: 'basic' },
                                    { label: 'Conversational', value: 'conversational' },
                                    { label: 'Fluent', value: 'fluent' },
                                    { label: 'Native Bilingual', value: 'native' },
                                ]}
                                selectedOptionStyle="color"
                                placeholder="Select level"
                                refProp={ref}
                                useBasicStyles
                            />
                        )}
                    />

                    <FormErrorMessage>
                        {errors.languages?.level && errors.languages?.level?.message}
                    </FormErrorMessage>
                </FormControl>
            </Flex>
            <Text fontWeight="600" mt="40px">
                Hire Date
            </Text>
            <RadioGroup mt="16px">
                <Radio display="flex" mb="16px" value="6+">
                    1-3 days
                </Radio>
                <Radio display="flex" mb="16px" value="6+">
                    This week
                </Radio>
                <Radio display="flex" mb="16px" value="6+">
                    Two week
                </Radio>
                <Radio display="flex" mb="16px" value="3-6">
                    One month
                </Radio>
            </RadioGroup>
            <Text mt="40px" fontWeight="600">
                Which level of experience will it need?
            </Text>
            <RadioGroup mt="16px">
                <Radio display="flex" mb="16px" value="entry">
                    80% - 100%
                </Radio>
                <Radio display="flex" mb="16px" value="expert">
                    90% - 100%
                </Radio>
            </RadioGroup>
        </>
    );
}

function JobPostDone() {
    return (
        <Center flexDirection="column" minH="702px">
            <Image src={postDoneImg} />
            <Text variant="secondary" my="32px" as="h4" fontSize="32px">
                Congratulations!
            </Text>
            <Text>You have posted the job successfully.</Text>
            <Button mt="32px" size="lg">
                View All Jobs
            </Button>
        </Center>
    );
}
