import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Text,
    FormControl,
    Input,
    FormErrorMessage,
} from '@chakra-ui/react';
import logo from 'assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AddWalletIcon } from 'assets/icons';
import { useUpdateTalentProfile } from 'utils/apis/user.api';
import { AddWalletFormType } from 'utils/types/user.type';

export default function AddWallet() {
    const navigate = useNavigate();
    const { mutate: updateTalentInfo, isSuccess, isLoading } = useUpdateTalentProfile();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<AddWalletFormType>();

    const onSubmit = (values: AddWalletFormType) => {
        const { evmWalletAddress, tronWalletAddress } = values;
        updateTalentInfo({ evmWalletAddress, tronWalletAddress });
    };

    useEffect(() => {
        if (isSuccess) {
            navigate('/add-info');
        }
    }, [isSuccess, navigate]);

    return (
        <>
            <Container maxW="1280px" pt="25px">
                <Link to="/">
                    <Image src={logo} />
                </Link>
            </Container>
            <Center minH="calc(100vh - 50px)">
                <Box w="100%" maxW="520px">
                    <AddWalletIcon />
                    <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                        Enter your wallet address
                    </Heading>
                    <Box mt="64px" as="form" onSubmit={handleSubmit(onSubmit)}>
                        <FormControl isInvalid={Boolean(errors.tronWalletAddress)} mb="25px">
                            <Input
                                size="lg"
                                _autofill={{ bg: 'none' }}
                                focusBorderColor="primary.500"
                                placeholder="TRON Wallet Address"
                                {...register('tronWalletAddress', {
                                    required: 'TRON Wallet required',
                                })}
                                variant="primary"
                            />
                            <FormErrorMessage>
                                {errors.tronWalletAddress && errors.tronWalletAddress.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={Boolean(errors.evmWalletAddress)} mb="25px">
                            <Input
                                size="lg"
                                _autofill={{ bg: 'none' }}
                                focusBorderColor="primary.500"
                                placeholder="EVM Wallet Address"
                                {...register('evmWalletAddress', {
                                    required: 'Email is required',
                                })}
                                variant="primary"
                            />
                            <FormErrorMessage>
                                {errors.evmWalletAddress && errors.evmWalletAddress.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Text>
                            Same address will be used to withdraw on all EVM chains including
                            Ethereum, Polygon, Binance Smart Chain.
                        </Text>
                        <Button mt="40px" isLoading={isLoading} type="submit" size="lg" w="100%">
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Center>
        </>
    );
}
