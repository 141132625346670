import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { PlayIcon, VolumeIcon } from 'assets/icons/messages';
import WaveSurfer from 'wavesurfer.js';

export default function AudioPlayer({ audio, isChat }: { audio: string; isChat?: boolean }) {
    const containerRef = useRef(null);
    const waveSurferRef: any = useRef({
        isPlaying: () => false,
    });
    const [isPlaying, toggleIsPlaying] = useState(false);
    const [isMute, setIsMute] = useState(false);

    useEffect(() => {
        if (containerRef?.current) {
            const waveSurfer = WaveSurfer.create({
                container: containerRef.current,
                responsive: true,
                barWidth: 1,
                barHeight: 0.8,
                cursorWidth: 0.4,
                waveColor: 'rgba(40, 40, 40, 0.66)',
                height: 35,
            });
            waveSurfer.load(audio);
            waveSurfer.on('ready', () => {
                waveSurferRef.current = waveSurfer;
            });

            return () => {
                waveSurfer.destroy();
            };
        }
    }, [audio]);

    return (
        <Flex minW="266px" w="100%" p="8px" rounded="8px" align="center" bg={isChat ? 'white' : ''}>
            <Button
                variant="unstyled"
                onClick={() => {
                    waveSurferRef?.current?.playPause();
                    toggleIsPlaying(waveSurferRef?.current?.isPlaying());
                }}
                type="button"
            >
                {isPlaying ? <PlayIcon /> : <PlayIcon />}
            </Button>
            <Box
                flex="126px"
                ref={containerRef}
                sx={{ wave: { overflow: 'visible !important' } }}
            />
            <Text fontSize="14px">0.53</Text>
            {!isChat && (
                <IconButton
                    aria-label="mute"
                    bg="transparent"
                    _hover={{}}
                    _active={{}}
                    onClick={() => {
                        waveSurferRef?.current?.setMute(isMute ? false : true);
                        setIsMute(waveSurferRef?.current?.isMuted);
                    }}
                    icon={<VolumeIcon />}
                />
            )}
        </Flex>
    );
}
