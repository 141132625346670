import React from 'react';
import Footer from 'components/footer';
import Header from 'components/header';
import {
    Card,
    Container,
    Divider,
    Heading,
    Box,
    Text,
    Button,
    Flex,
    Modal,
    ModalContent,
    ModalOverlay,
    ModalHeader,
    useDisclosure,
    ModalBody,
    ModalCloseButton,
    InputGroup,
    Input,
    InputRightAddon,
    ModalFooter,
    ButtonGroup,
    Icon,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { AppTable } from 'components/table';
import { LongRightArrow, PercentFeeIcon, TronIcon } from 'assets/icons/user';

export default function WithdrawalRequest() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="60px">
                <Card p="24px">
                    <Heading fontSize="31px">Balance Withdrawal</Heading>
                    <Divider borderColor="border" my="40px" />
                    <Heading as="h4" fontSize="20px">
                        Amount in available chains
                    </Heading>
                    <Box my="56px">
                        <Text as="span" variant="black">
                            Total Balance in USDT
                        </Text>
                        <Box>
                            <Text as="span" fontSize="40px" variant="secondary">
                                200.30{' '}
                            </Text>
                            <Text as="span">USDT</Text>
                        </Box>
                    </Box>
                    <TransactionTable />
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function TransactionTable() {
    type UnitConversion = {
        chain: string;
        balance: string;
        fee: string;
        amount: string;
    };

    const data: UnitConversion[] = [
        {
            chain: 'TRON',
            balance: '300',
            fee: '3',
            amount: '297',
        },
    ];

    const columnHelper = createColumnHelper<UnitConversion>();

    const columns = [
        columnHelper.accessor('chain', {
            cell: info => (
                <Flex gap="15px" align="center">
                    <TronIcon />{' '}
                    <Text variant="secondary" fontSize="20px">
                        {info.getValue()}
                    </Text>
                </Flex>
            ),
            header: 'Chain',
        }),
        columnHelper.accessor('balance', {
            cell: info => (
                <>
                    <Text as="span" fontSize="20px">
                        {info.getValue()}
                    </Text>{' '}
                    <Text as="span">USDT</Text>
                </>
            ),
            header: 'Balance',
        }),
        columnHelper.accessor('fee', {
            cell: info => (
                <>
                    <Text as="span" fontSize="20px">
                        {info.getValue()}
                    </Text>{' '}
                    <Text as="span">USDT</Text>
                </>
            ),
            header: 'Fee',
        }),
        columnHelper.accessor('amount', {
            cell: info => (
                <>
                    <Text as="span" fontSize="20px">
                        {info.getValue()}
                    </Text>{' '}
                    <Text as="span">USDT</Text>
                </>
            ),
            header: 'Amount You Get',
        }),
        columnHelper.accessor('chain', {
            cell: () => <WithDrawalModal />,
            header: 'Action',
        }),
    ];

    return <AppTable columns={columns} data={[...Array(3).fill(data[0])]} />;
}

function WithDrawalModal() {
    const { isOpen, onClose, onOpen } = useDisclosure();
    return (
        <>
            <Button rightIcon={<LongRightArrow />} onClick={onOpen}>
                withdrawal
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Withdrawal Request</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text as="label">Amount</Text>
                        <InputGroup size="lg" my="14px">
                            <Input
                                variant="primary"
                                borderColor="primary.500"
                                borderRight="none"
                                defaultValue="1200.00 "
                            />
                            <InputRightAddon bg="transparent" borderColor="primary.500">
                                <TronIcon />
                            </InputRightAddon>
                        </InputGroup>
                        <Text as="label">Address</Text>
                        <InputGroup size="lg" my="14px">
                            <Input
                                variant="primary"
                                borderColor="primary.500"
                                borderRight="none"
                                defaultValue="00x0000.....00000"
                                _disabled={{
                                    borderColor: 'primary.500',
                                    borderRight: 'none',
                                    color: 'placeholder',
                                }}
                                isDisabled
                            />
                            <InputRightAddon bg="transparent" borderColor="primary.500">
                                <Button variant="link">Edit</Button>
                            </InputRightAddon>
                        </InputGroup>
                        <Flex
                            justify="space-between"
                            bg="bgGray"
                            p="16px"
                            align="center"
                            mb="24px"
                            rounded="8px"
                        >
                            <Flex align="center" gap="10px">
                                <PercentFeeIcon /> Withdrawal Fee <Icon />
                            </Flex>
                            <Text as="span" variant="black" fontSize="20px">
                                1%
                            </Text>
                        </Flex>
                        <Text as="label">Amount You Get</Text>
                        <Text fontSize="13px" my="14px">
                            The estimated amount {`you'll`} receive after service fees
                        </Text>
                        <InputGroup size="lg">
                            <Input
                                variant="primary"
                                borderColor="border"
                                borderRight="none"
                                defaultValue="1188.00 "
                            />
                            <InputRightAddon bg="transparent" borderColor="border">
                                <TronIcon />
                            </InputRightAddon>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button size="lg">Withdraw</Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}
