import React, { useState, useMemo, useEffect } from 'react';
import {
    Heading,
    Box,
    Button,
    Text,
    PinInput,
    PinInputField,
    HStack,
    Center,
} from '@chakra-ui/react';
import { MailIcon } from 'assets/icons';
import { useTimer } from 'utils/hooks';
import { User } from 'utils/types/user.type';
import { useReSendOtp, useVerifyEmail } from 'utils/apis/auth.api';
import { useNavigate } from 'react-router-dom';
import { getSignupType } from 'utils/helper';

export default function VerifyEmail({ userData }: { userData: User | undefined }) {
    const navigate = useNavigate();
    const type = getSignupType();
    const {
        mutate: verifyEmail,
        isSuccess,
        data: otpData,
        isLoading,
    } = useVerifyEmail(type === 'client' ? 'client' : 'talent');
    const { mutate: resendOtp } = useReSendOtp();
    const [otpValue, setOtpValue] = useState('');
    const now = useMemo(() => new Date().getTime() + 3 * (60 * 1000), []);
    const { minutes, seconds, isTimerOff } = useTimer(now);

    const onSubmit = () => {
        if (userData) verifyEmail({ otp: otpValue, email: userData?.email });
    };

    useEffect(() => {
        if (isSuccess) {
            localStorage.setItem('token', otpData?.token?.access_token?.split(' ')[1]);
            navigate(type === 'client' ? '/add-info' : '/add-wallet');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, otpData]);

    return (
        <Center minH="calc(100vh - 50px)">
            <Box w="100%" maxW="520px" textAlign="center">
                <MailIcon />
                <Heading fontSize={{ base: '24px', md: '31px' }} mt="64px">
                    Verify your email
                </Heading>
                <Text mt="32px">
                    We just sent a 6 digits verification code to{' '}
                    <Text as="span" variant="secondary">
                        {`${userData?.email.replace(
                            userData?.email?.slice(4, userData?.email?.indexOf('@')),
                            '***',
                        )}`}
                    </Text>
                    . Verify by entering the code here.
                </Text>
                {!isTimerOff && (
                    <Text
                        as={Box}
                        variant="secondary"
                        border="1px solid"
                        borderColor="primary.500"
                        p="10px 16px"
                        rounded="8px"
                        mt="30px"
                        w="max-content"
                        mx="auto"
                    >
                        Time Remaining 0{minutes} :{seconds}
                    </Text>
                )}
                <HStack mt="64px" justify="center">
                    <PinInput
                        size="lg"
                        focusBorderColor="primary.500"
                        otp
                        onChange={value => setOtpValue(value)}
                    >
                        <PinInputField borderColor="primary.500" />
                        <PinInputField borderColor="primary.500" />
                        <PinInputField borderColor="primary.500" />
                        <PinInputField borderColor="primary.500" />
                        <PinInputField borderColor="primary.500" />
                        <PinInputField borderColor="primary.500" />
                    </PinInput>
                </HStack>
                <Button
                    w="100%"
                    mt="40px"
                    type="submit"
                    size="lg"
                    onClick={() => onSubmit()}
                    isLoading={isLoading}
                >
                    Verify Email
                </Button>
                <Text mt="64px">
                    Not received yet?{' '}
                    <Button
                        textDecor="underline"
                        variant="link"
                        isDisabled={isLoading}
                        onClick={() => userData && resendOtp(userData?.email)}
                    >
                        Resend a new code
                    </Button>
                </Text>
            </Box>
        </Center>
    );
}
