export default {
    baseStyle: {
        field: {
            bg: 'white',
            border: '1px solid #D4D4D4',
            _placeholder: {
                color: 'placeholder',
            },
        },
    },
};
