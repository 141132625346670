export default {
    baseStyle: {
        bg: 'white',
        border: '1px solid #D4D4D4',
        p: '16px',
        _placeholder: {
            color: 'placeholder',
        },
    },
};
