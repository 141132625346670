import React from 'react';
import {
    Card,
    Container,
    Divider,
    Heading,
    Flex,
    Box,
    Text,
    Button,
    ButtonGroup,
} from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import { EarningsIcon, InReviewICon, WithdrawalIcon, WorkProgressIcon } from 'assets/icons/user';
import Footer from 'components/footer';
import Header from 'components/header';
import { AppTable } from 'components/table';
import { Link } from 'react-router-dom';

export default function MyReports() {
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="60px">
                <Card p="24px">
                    <Heading fontSize="31px">My Reports</Heading>
                    <Divider borderColor="border" my="40px" />
                    <Heading as="h4" fontSize="20px">
                        Overview
                    </Heading>
                    <Flex gap="24px" wrap="wrap" mt="56px">
                        <ProfileUtils
                            icon={<WorkProgressIcon />}
                            label="Work in Progress"
                            value="200.30"
                        />
                        <ProfileUtils icon={<InReviewICon />} label="In Review" value="200.30" />
                        <ProfileUtils icon={<EarningsIcon />} label="Earnings" value="200.30" />
                        <ProfileUtils icon={<WithdrawalIcon />} label="Withdrawal" value="200.30" />
                    </Flex>
                    <Divider borderColor="border" my="40px" />
                    <Heading as="h4" fontSize="20px">
                        Transaction History
                    </Heading>
                    <Flex my="56px" justify="space-between">
                        <Box>
                            <Text as="span" variant="black">
                                Available Balance
                            </Text>
                            <Box>
                                <Text as="span" fontSize="40px" variant="secondary">
                                    200.30{' '}
                                </Text>
                                <Text as="span">USDT</Text>
                            </Box>
                        </Box>
                        <ButtonGroup>
                            <Button size="lg" as={Link} to="/withdrawal-request">
                                Withdrawal Request
                            </Button>
                            <Button variant="outline" size="lg">
                                Download CSV
                            </Button>
                        </ButtonGroup>
                    </Flex>
                    <TransactionTable />
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ProfileUtils({
    icon,
    label,
    value,
    isEdit,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
}) {
    return (
        <Card
            as={Flex}
            flexDirection="row"
            flex="150px"
            minW="max-content"
            p="24px"
            align="center"
            gap="20px"
        >
            {icon}
            <Box>
                <Text display="block" as="span" variant="secondary" fontSize="20px">
                    {label}
                </Text>
                <Text as="span">{value} USDT</Text>
            </Box>
            {isEdit}
        </Card>
    );
}

function TransactionTable() {
    type UnitConversion = {
        date: string;
        type: string;
        description: string;
        client: string;
        amount: string;
        withdrawalFee: string;
        hash: string;
    };

    const data: UnitConversion[] = [
        {
            date: '15-07-2023',
            type: 'Service Fee',
            description:
                'Lorem ipsum dolor sit amet consectetur. Sapien sit enim cursus morbi consequat sed et in est.',
            client: 'Robert Fox',
            amount: '200 USDT',
            withdrawalFee: '20 USDT',
            hash: '00x0000---000000',
        },
    ];

    const columnHelper = createColumnHelper<UnitConversion>();

    const columns = [
        columnHelper.accessor('date', {
            cell: info => info.getValue(),
            header: 'Date',
        }),
        columnHelper.accessor('type', {
            cell: info => info.getValue(),
            header: 'Type',
        }),
        columnHelper.accessor('description', {
            cell: info => (
                <Text noOfLines={1} whiteSpace="initial">
                    {info.getValue()}
                </Text>
            ),
            header: 'Description',
        }),
        columnHelper.accessor('client', {
            cell: info => info.getValue(),
            header: 'Client',
        }),
        columnHelper.accessor('amount', {
            cell: info => info.getValue(),
            header: 'Amount',
        }),
        columnHelper.accessor('withdrawalFee', {
            cell: info => info.getValue(),
            header: 'Withdrawal Fee',
        }),
        columnHelper.accessor('hash', {
            cell: info => <Text variant="secondary">{info.getValue()}</Text>,
            header: 'Hash Id',
        }),
    ];

    return <AppTable columns={columns} data={[...Array(40).fill(data[0])]} />;
}
