import React from 'react';
import {
    Box,
    Container,
    Divider,
    Flex,
    List,
    ListItem,
    Text,
    Image,
    Heading,
    InputGroup,
    Input,
    InputRightAddon,
    Button,
} from '@chakra-ui/react';
import logo from 'assets/images/logo-white.png';
import Subscribe from 'assets/images/subscribe-img.png';
import { COMPANY_MENU, SERVICES_MENU } from 'utils/const';
import { Link } from 'react-router-dom';
import { FacebookIcon, DiscordIcon, LinkedinIcon, TwitterIcon } from 'assets/icons';
import { intoView } from 'utils/helper';

export default function Footer() {
    return (
        <Box py="60px" mt="220px" as="footer" bg="primary.500" p="80px 0 30px">
            <Box
                bg="heading"
                py="70px"
                pl="70px"
                maxW="1064px"
                rounded="18px"
                mx="auto"
                position="relative"
                mt="-200px"
            >
                <Heading as="h5" fontSize="18px" maxW="446px" color="white">
                    Stay updated with the latest job opportunities and industry trends. Subscribe
                    now!
                </Heading>
                <InputGroup borderRadius="12px" mt="15px" maxW="416px" p="10px" bg="white">
                    <Input
                        outline="none"
                        border="none"
                        focusBorderColor="transparent"
                        placeholder="Enter your email"
                    />
                    <InputRightAddon p="0" bg="transparent">
                        <Button>Subscribe</Button>
                    </InputRightAddon>
                </InputGroup>
                <Image position="absolute" maxW="100%" right="100px" bottom="0px" src={Subscribe} />
            </Box>
            <Container mt="60px" maxW="1280px">
                <Flex justify="space-between">
                    <Box w="100%" maxW="400px">
                        <Image src={logo} />
                        <Text variant="primary" my="30px" maxW="300px" fontSize="13px">
                            We offer various IT services to help our clients stay ahead in the
                            constantly evolving technology landscape and achieve their goals.
                        </Text>
                        <Flex gap="20px" mb="30px">
                            <a href="https://pk.linkedin.com" target="_blank" rel="noreferrer">
                                <LinkedinIcon />
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noreferrer">
                                <FacebookIcon />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noreferrer">
                                <TwitterIcon />
                            </a>
                            <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                                <DiscordIcon />
                            </a>
                        </Flex>
                    </Box>
                    <FooterLinks title="Company" menu={COMPANY_MENU} />
                    <FooterLinks title="Services" menu={SERVICES_MENU} />
                </Flex>
                <Divider borderColor="white" my="30px" display={{ base: 'none', md: 'block' }} />
                <Text variant="primary">
                    Copyright 2023, All rights reserved by Stoptalent. - Terms & Policies
                </Text>
            </Container>
        </Box>
    );
}

function FooterLinks({
    title,
    menu,
}: {
    title: string;
    menu: { label: string; path?: string; id?: string }[];
}) {
    return (
        <List spacing="15px" w="100%" maxW="250px" display={{ base: 'none', md: 'block' }}>
            <ListItem color="white" fontFamily="Metropolis Medium">
                {title}
            </ListItem>
            {menu.map(({ label, path, id }) => (
                <ListItem
                    as={Link}
                    to={path}
                    key={label}
                    display="block"
                    color="white"
                    fontSize="14px"
                    onClick={() => id && intoView(id)}
                >
                    {label}
                </ListItem>
            ))}
        </List>
    );
}
