import * as React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    chakra,
    Button,
    Tfoot,
    Box,
    Flex,
} from '@chakra-ui/react';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    TriangleDownIcon,
    TriangleUpIcon,
} from '@chakra-ui/icons';
import {
    useReactTable,
    flexRender,
    getCoreRowModel,
    ColumnDef,
    SortingState,
    getSortedRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table';

export type DataTableProps<Data extends object> = {
    data: Data[];
    columns: ColumnDef<Data, any>[];
};

export function AppTable<Data extends object>({ data, columns }: DataTableProps<Data>) {
    const [sorting, setSorting] = React.useState<SortingState>([]);
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        state: {
            sorting,
        },
    });

    return (
        <Table>
            <Thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <Tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                            const meta: any = header.column.columnDef.meta;
                            return (
                                <Th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                    isNumeric={meta?.isNumeric}
                                    bg="bgGray"
                                    color="heading"
                                    fontSize="16px"
                                    textTransform="capitalize"
                                    fontFamily="inherit"
                                    borderBottom="1px solid"
                                    borderColor="border"
                                    p="24px 16px"
                                    whiteSpace="nowrap"
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}

                                    <chakra.span pl="4">
                                        {header.column.getIsSorted() ? (
                                            header.column.getIsSorted() === 'desc' ? (
                                                <TriangleDownIcon aria-label="sorted descending" />
                                            ) : (
                                                <TriangleUpIcon aria-label="sorted ascending" />
                                            )
                                        ) : null}
                                    </chakra.span>
                                </Th>
                            );
                        })}
                    </Tr>
                ))}
            </Thead>
            <Tbody sx={{ 'tr:nth-child(even)': { bg: 'bgGray' } }}>
                {table.getRowModel().rows.map(row => (
                    <Tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                            // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                            const meta: any = cell.column.columnDef.meta;
                            return (
                                <Td
                                    border="none"
                                    key={cell.id}
                                    isNumeric={meta?.isNumeric}
                                    p="20px 16px"
                                    color="text"
                                    whiteSpace="nowrap"
                                >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </Td>
                            );
                        })}
                    </Tr>
                ))}
            </Tbody>
            {table.getPageCount() > 1 && (
                <Tfoot>
                    <Tr>
                        <Td
                            borderColor="border"
                            colSpan={table.getRowModel().rows[0]?.getVisibleCells()?.length}
                        ></Td>
                    </Tr>
                    <Tr>
                        <Td
                            border="none"
                            colSpan={table.getRowModel().rows[0]?.getVisibleCells()?.length}
                        >
                            <Flex gap="16px" justify="flex-end" align="center" pt="24px">
                                <Button
                                    variant="unstyled"
                                    onClick={() => table.previousPage()}
                                    isDisabled={!table.getCanPreviousPage()}
                                    bg="bgGray"
                                    p="8px"
                                    minW="max-content"
                                    h="auto"
                                >
                                    <ChevronLeftIcon fontSize="22px" />
                                </Button>
                                <Flex align="center" gap="8px">
                                    {[...Array(table.getPageCount())]?.map((item, i) => {
                                        const activeIndex = table.getState().pagination.pageIndex;
                                        if (i < 3) {
                                            return (
                                                <Box
                                                    key={i}
                                                    p="8px"
                                                    rounded="4px"
                                                    bg={i === activeIndex ? 'primary.50' : 'bgGray'}
                                                    color={i === activeIndex ? 'primary.500' : ''}
                                                    minW="34px"
                                                    textAlign="center"
                                                    onClick={() => table.setPageIndex(i)}
                                                    cursor="pointer"
                                                >
                                                    {i + 1}
                                                </Box>
                                            );
                                        }
                                    })}
                                    {table.getPageCount() > 3 && (
                                        <Box
                                            p="8px 10px"
                                            rounded="4px"
                                            bg={
                                                table.getState().pagination.pageIndex > 2
                                                    ? 'primary.50'
                                                    : 'bgGray'
                                            }
                                        >
                                            ...
                                        </Box>
                                    )}
                                </Flex>
                                <Button
                                    variant="unstyled"
                                    onClick={() => table.nextPage()}
                                    isDisabled={!table.getCanNextPage()}
                                    bg="bgGray"
                                    p="8px"
                                    minW="max-content"
                                    h="auto"
                                >
                                    <ChevronRightIcon fontSize="22px" />
                                </Button>
                            </Flex>
                        </Td>
                    </Tr>
                </Tfoot>
            )}
        </Table>
    );
}
