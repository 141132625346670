import React from 'react';
import {
    Box,
    Card,
    Container,
    Divider,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Flex,
} from '@chakra-ui/react';
import Banner from 'components/banner';
import Footer from 'components/footer';
import Header from 'components/header';
import { LikeArticleIcon, UnLikeArticleIcon } from 'assets/icons';

export default function Article() {
    return (
        <>
            <Header />
            <Banner
                navList={['Help & Support', 'Article']}
                title="Understanding and Using Connects"
                subtitle="Get help with your next blockchain project."
            />
            <Container maxW="1280px" mt="80px">
                <Card p="24px" gap="40px" flexDirection="row" flexWrap="wrap">
                    <Box flex="1">
                        <Text>
                            At Stoptalent, one of our key goals is to provide a robust marketplace
                            for freelancers and clients to connect with each other and build strong,
                            productive relationships. As a freelancer or agency, {`you'll`} use
                            Connects to reach out and let clients know
                            {`you're`} interested in their project.
                        </Text>
                        <br />
                        <br />
                        <Text>
                            Freelancing is your business, and like any business owner, you have to
                            make investments to sell your products or services. When business is
                            conducted in person, you might take a prospective client to lunch, spend
                            money on a special presentation, or travel to the client’s business or
                            home to discuss the work. With these steps, you are not only marketing
                            your services, you are also showing potential clients that you are
                            serious about your business and invested in building a working
                            relationship with them. Connects are an investment toward your freelance
                            career.
                        </Text>
                        <Divider borderColor="border" my="40px" />
                        <Text variant="black" fontSize="20px" fontWeight="500">
                            Get Connects
                        </Text>
                        <Text mt="16px">There are three ways to get Connects on Stoptalent.</Text>
                        <Text fontWeight="600" mt="24px">
                            Monthly Free Connect
                        </Text>
                        <Text mt="16px">
                            Each month, freelancers receive free Connects to help reach out to more
                            clients. Freelancer Basic accounts receive 10 free Connects each month,
                            and Freelancer Plus accounts receive 70 Connects each month (including
                            10 FREE!). Connects are given out each month on the first day of your
                            billing cycle. To see when your billing cycle is, go here and view your{' '}
                            {`"current billing cycle." `}The first date there is the date you will
                            receive your monthly Connects. For example, if your current billing
                            cycle is listed as Oct. 11, 2023 - Nov. 10, 2023, you will receive your
                            Connects on the 11th of each month.
                        </Text>
                    </Box>
                    <Accordion defaultIndex={[0]} allowMultiple maxW="350px" w="100%">
                        <AccordionItem as={Card} p="16px" rounded="8px">
                            <AccordionButton bg="bgGray" p="16px" rounded="8px">
                                <Text
                                    variant="black"
                                    as="span"
                                    flex="1"
                                    fontSize="20px"
                                    textAlign="left"
                                >
                                    In this article
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                <Text
                                    variant="primary"
                                    bg="primary.500"
                                    fontSize="13px"
                                    p="8px 16px"
                                    rounded="4px"
                                >
                                    Get Connects
                                </Text>
                                <Text variant="black" fontSize="13px" p="8px 16px" mt="6px">
                                    Redeem a Connects Reward
                                </Text>
                                <Text variant="black" fontSize="13px" p="8px 16px" mt="6px">
                                    Use Connects
                                </Text>
                                <Text variant="black" fontSize="13px" p="8px 16px" mt="6px">
                                    Menage Connects
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem as={Card} p="16px" mt="24px" rounded="8px">
                            <AccordionButton bg="bgGray" p="16px" rounded="8px">
                                <Text
                                    variant="black"
                                    as="span"
                                    flex="1"
                                    fontSize="20px"
                                    textAlign="left"
                                >
                                    Articles in ths section
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat.
                            </AccordionPanel>
                        </AccordionItem>

                        <AccordionItem as={Card} p="16px" mt="24px" rounded="8px">
                            <AccordionButton bg="bgGray" p="16px" rounded="8px">
                                <Text
                                    variant="black"
                                    as="span"
                                    flex="1"
                                    fontSize="20px"
                                    textAlign="left"
                                >
                                    Recently viewed articles
                                </Text>
                                <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel pb={4}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
                                ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat.
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                    <Box w="100%" textAlign="center" pb="16px">
                        <Divider borderColor="border" mb="50px" />
                        <Text fontWeight="500" fontSize="20px" variant="black">
                            Was this article helpful?
                        </Text>
                        <Flex gap="25px" justify="center" my="32px">
                            <LikeArticleIcon />
                            <UnLikeArticleIcon />
                        </Flex>
                        <Text>3028 out of 2893 found this helpful</Text>
                    </Box>
                </Card>
            </Container>
            <Footer />
        </>
    );
}
