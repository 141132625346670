import React, { useEffect, useState } from 'react';
import {
    Card,
    Container,
    Flex,
    Heading,
    Text,
    Divider,
    Avatar,
    Box,
    Progress,
    AvatarBadge,
    HStack,
    IconButton,
    Button,
    useDisclosure,
    Modal,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalOverlay,
    ModalContent,
    Input,
    Textarea,
    ModalFooter,
    ButtonGroup,
    FormControl,
    FormLabel,
    VisuallyHiddenInput,
    SkeletonCircle,
    SkeletonText,
    Skeleton,
    FormErrorMessage,
    Tag,
    Switch,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import { LinkIcon, StarRatingFillIcon } from 'assets/icons';
import {
    EditBtnIcon,
    EditIcon,
    EditPostIcon,
    JobIcon,
    LanguageIcon,
    SecurityIcon,
    VerifiedAccountIcon,
    VerifiedWhiteIcon,
} from 'assets/icons/user';
import 'react-datepicker/dist/react-datepicker.css';
import { FormProvider, useForm } from 'react-hook-form';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import { useGetId, useIsLogin } from 'utils/hooks';
import { UpdateUserInfo, UpdateUserPassword } from 'utils/types/user.type';
import { showToast } from 'utils/helper';
import AddLanguage from 'components/addLanguage';
import { useUpdateTalentProfile } from 'utils/apis/user.api';
import { Link } from 'react-router-dom';

export default function ClientProfile() {
    const userId = useGetId();
    const isLogon = useIsLogin();
    const isLoading = false;
    console.log('userId', userId);
    return (
        <>
            <Header />
            <Container maxW="1280px">
                <Card p="55px" mt="80px">
                    <Flex gap="35px">
                        <SkeletonCircle isLoaded={!isLoading} w="auto" h="auto">
                            <Avatar
                                size="2xl"
                                outline="5px solid #59C778"
                                p="2px"
                                border="3px solid white"
                            >
                                <EditProfilePic />
                            </Avatar>
                        </SkeletonCircle>
                        <Flex justify="space-between" flex="1">
                            <Box>
                                <SkeletonText
                                    noOfLines={1}
                                    skeletonHeight="12"
                                    mb="32px"
                                    isLoaded={!isLoading}
                                >
                                    <Heading as="h2">
                                        Paul Walker Jr
                                        <VerifiedAccountIcon />
                                    </Heading>
                                </SkeletonText>
                                <Skeleton isLoaded={!isLoading}>
                                    <Flex gap="8px" as={Text} align="center">
                                        <StarRatingFillIcon /> 4.92 (230 Reviews)
                                    </Flex>
                                </Skeleton>
                            </Box>
                            <Box>
                                <Skeleton isLoaded={!isLoading}>
                                    <Text fontSize="20px" variant="black" textAlign="right">
                                        Total Spending{' '}
                                        <Text ml="20px" fontSize="31px" as="span" variant="black">
                                            200.30
                                        </Text>{' '}
                                        <sub>USDT</sub>
                                    </Text>
                                </Skeleton>
                                <Skeleton isLoaded={!isLoading} mt="45px">
                                    <Flex gap="15px" align="center">
                                        <Progress
                                            value={80}
                                            w="165px"
                                            rounded="9px"
                                            sx={{ div: { borderRadius: '9px' } }}
                                        />
                                        <Text variant="link" fontSize="20px">
                                            80% Job Success Rate
                                        </Text>
                                    </Flex>
                                </Skeleton>
                            </Box>
                        </Flex>
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between" mb="30px">
                        <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                            <Heading as="h4" fontSize="25px">
                                About
                            </Heading>
                        </SkeletonText>
                        <Skeleton isLoaded={!isLoading}>
                            <HStack>
                                {isLogon && <EditAbout />}
                                <IconButton icon={<LinkIcon />} aria-label="copy-link" size="lg" />
                            </HStack>
                        </Skeleton>
                    </Flex>
                    <SkeletonText noOfLines={1} skeletonHeight="12" isLoaded={!isLoading}>
                        <Text variant="black" fontSize="20px">
                            Hiring talented Web Developers | Frontend Developer | Blockchain
                            Developers | UI/UX Designers
                        </Text>
                    </SkeletonText>
                    <SkeletonText mt="40px" noOfLines={6} skeletonHeight="4" isLoaded={!isLoading}>
                        <Text>
                            Lorem ipsum dolor sit amet consectetur. Facilisis praesent habitant sit
                            felis arcu mattis. Amet tincidunt leo varius ut fames ipsum luctus.
                            Facilisis sed et elit lorem cursus eget. Volutpat cursus neque arcu
                            molestie tincidunt aliquam sed. Quam vitae vestibulum tristique etiam
                            feugiat pellentesque. Libero est non nunc nisl proin sed habitant.
                            Tincidunt venenatis libero at gravida blandit quis mattis id. Faucibus
                            urna orci ultrices enim laoreet. Euismod elit amet amet sed et velit ac
                            in vestibulum. Mattis sit viverra mauris elementum non. Eget suspendisse
                            nulla integer tincidunt.
                        </Text>
                    </SkeletonText>
                    <Flex flexWrap="wrap" gap="24px" mt="55px">
                        <ProfileUtils
                            icon={<LanguageIcon />}
                            label="Language"
                            value={['English', 'Spanish'].join(', ')}
                            isEdit={<EditLanguage />}
                            isLoaded={isLoading}
                        />
                        <ProfileUtils
                            isLoaded={isLoading}
                            icon={<JobIcon />}
                            label="Total Jobs"
                            value="100+"
                        />
                        <ProfileUtils
                            isLoaded={isLoading}
                            icon={<JobIcon />}
                            label="Active Jobs"
                            value="10+"
                        />
                    </Flex>

                    <Divider my="40px" borderColor="border" />
                    <Flex justify="space-between">
                        <Heading as="h4" fontSize="25px">
                            Testimonials
                        </Heading>
                    </Flex>
                    <Flex gap="50px" flexWrap="wrap" mt="40px">
                        {[...Array(4)]?.map(item => (
                            <Testimonial key={item} />
                        ))}
                    </Flex>
                    <Switch mt="50px" defaultChecked>
                        Testimonials Visibility
                    </Switch>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText
                        noOfLines={1}
                        skeletonHeight="12"
                        w="max-content"
                        isLoaded={!isLoading}
                    >
                        <Heading as="h4" fontSize="25px">
                            My Jobs
                        </Heading>
                    </SkeletonText>
                    <Flex flexWrap="wrap" gap="24px" mt="30px">
                        {[...Array(3)]?.map(item => (
                            <Card
                                key={item}
                                p="32px"
                                rounded="8px"
                                mt="56px"
                                as={Link}
                                to="/client-job-detail"
                            >
                                <Flex justify="space-between">
                                    <Heading as="h3" fontSize="20px">
                                        Build a Minimum Viable responsive NFT auction website with
                                        blockchain integration
                                    </Heading>
                                    <Button leftIcon={<EditPostIcon />}>Edit</Button>
                                </Flex>
                                <Flex gap="30px" align="center" my="24px">
                                    <Text
                                        as="div"
                                        bg="success"
                                        variant="primary"
                                        p="10px"
                                        w="126px"
                                        rounded="8px"
                                        textAlign="center"
                                    >
                                        Active
                                    </Text>
                                    {/* <Text>Waiting for Client to fund a new milestone.</Text> */}
                                </Flex>
                                <Text as="span">Job Posted On: May 30, 2023</Text>
                                <Text my="40px">
                                    Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit
                                    fusce diam tincidunt nibh nibh commodo. Sed in adipiscing
                                    blandit nullam. Aliquet orci porta fermentum sed sit pretium.
                                    Scelerisque consectetur nisl feugiat quam dictum vulputate
                                    porttitor turpis sit. Aliquet dapibus aliquam enim orci elit
                                    congue justo pharetra ut. Scelerisque consequat faucibus
                                    ultricies at id. Et aenean ut varius risus ultrices mattis
                                    elementum morbi luctus. Leo cras ipsum metus imperdiet. Tortor
                                    consequat elit etiam felis nunc auctor.
                                </Text>
                                <Flex gap="14px" justify="space-between">
                                    <Box>
                                        <Text variant="secondary" as="label">
                                            Skills:
                                        </Text>
                                        <Flex mt="16px" gap="8px">
                                            <Tag variant="gray">Blockchain</Tag>
                                            <Tag variant="gray">JavaScript</Tag>
                                        </Flex>
                                    </Box>
                                    <Box>
                                        <Text variant="secondary" as="label">
                                            Estimated Time:
                                        </Text>
                                        <Text variant="black" mt="16px">
                                            1 - 3 Months, More than 30 hours/week
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text variant="secondary" as="label">
                                            Proposals:
                                        </Text>
                                        <Text variant="black" mt="16px">
                                            27+
                                        </Text>
                                    </Box>
                                    <Box>
                                        <Text variant="secondary" as="label">
                                            Budget
                                        </Text>
                                        <Text variant="black">
                                            <Text
                                                as="strong"
                                                fontSize="32px"
                                                variant="black"
                                                mr="8px"
                                            >
                                                350.63
                                            </Text>
                                            USDT
                                        </Text>
                                    </Box>
                                </Flex>
                            </Card>
                        ))}
                    </Flex>
                    <Divider my="40px" borderColor="border" />
                    <SkeletonText
                        noOfLines={1}
                        skeletonHeight="12"
                        w="max-content"
                        isLoaded={!isLoading}
                    >
                        <Heading as="h4" fontSize="25px">
                            Password & Security
                        </Heading>
                    </SkeletonText>
                    <Card p="32px" mt="40px">
                        <Heading as="h5" fontSize="20px">
                            Your Password
                        </Heading>
                        <Flex mt="32px">
                            <Box flex="1">
                                <Text variant="secondary">
                                    Password has been set <SecurityIcon />{' '}
                                </Text>
                                <Text mt="6px">
                                    Choose a strong, unique password that’s at least 8 characters
                                    long.
                                </Text>
                            </Box>
                            <ChangePasswoard />
                        </Flex>
                    </Card>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ProfileUtils({
    icon,
    label,
    value,
    isEdit,
    isLoaded,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value?: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
    isLoaded: boolean;
}) {
    return (
        <Skeleton flex="150px" minW="max-content" isLoaded={!isLoaded}>
            <Card as={Flex} flexDirection="row" p="14px" align="center" gap="19px">
                {icon}
                <Box>
                    <Text as="h6" gap="9px" variant="black" fontSize="20px">
                        {label}
                    </Text>
                    <Text>{value}</Text>
                </Box>
                {isEdit}
            </Card>
        </Skeleton>
    );
}

function Testimonial() {
    return (
        <Box flex="360px">
            <Flex gap="24px">
                <Avatar size="lg" name="Bessie Cooper" />
                <Box>
                    <Flex as="h4" fontSize="20px" align="center" gap="10px">
                        Bessie Cooper{' '}
                        <Text
                            fontSize="12px"
                            variant="primary"
                            as={Flex}
                            bg="primary.500"
                            p="4px 8px"
                            gap="5px"
                            rounded="4px"
                            alignItems="center"
                        >
                            <VerifiedWhiteIcon /> Verified
                        </Text>
                    </Flex>
                    <Text mt="10px">May 20, 2015</Text>
                </Box>
            </Flex>
            <Text mt="24px" fontStyle="italic">
                “Lorem ipsum dolor sit amet consectetur. Maecenas orci sed curabitur sed eu rhoncus
                quis a venenatis. Ligula penatibus volutpat quis arcu facilisi congue gravida.
                Scelerisque risus et leo ac viverra hendrerit pharetra.”
            </Text>
        </Box>
    );
}

function EditProfilePic() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const [profilePic, setProfilePic] = useState<File | undefined>(undefined);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [imageCrop, setImageCrop] = useState<Area | undefined>(undefined);

    console.log('crop');

    return (
        <>
            <AvatarBadge border="none" right="50%" transform="translate(50%, 51%)" onClick={onOpen}>
                <EditIcon />
            </AvatarBadge>
            <Modal isOpen={isOpen} isCentered onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Upload Profile Photo
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isEdit ? (
                            <Box position="relative" minH="260px">
                                {profilePic && (
                                    <CropImage
                                        profilePic={window.URL.createObjectURL(profilePic)}
                                        setImageCrop={setImageCrop}
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box
                                as="label"
                                display="block"
                                border="1px dashed #A9A9A9"
                                py="32px"
                                rounded="8px"
                                htmlFor="uploadFile"
                                pointerEvents={profilePic ? 'none' : 'all'}
                            >
                                <Avatar
                                    src={profilePic && window.URL.createObjectURL(profilePic)}
                                    size="2xl"
                                    mx="auto"
                                    bg="primary.200"
                                    display="block"
                                    outline={profilePic ? '5px solid #59C778' : 'none'}
                                    p="2px"
                                    border="3px solid white"
                                    sx={{
                                        img: {
                                            transform: `translate(${imageCrop?.x}px, ${imageCrop?.y}px)`,
                                        },
                                    }}
                                >
                                    {profilePic && (
                                        <AvatarBadge
                                            border="none"
                                            right="50%"
                                            transform="translate(50%, 51%)"
                                            onClick={onOpen}
                                        >
                                            <EditIcon />
                                        </AvatarBadge>
                                    )}
                                </Avatar>
                                <Text textAlign="center" mt="20px" fontSize="14px">
                                    Upload jpeg files only of maximum size 2 MBs
                                </Text>
                            </Box>
                        )}

                        <VisuallyHiddenInput
                            type="file"
                            id="uploadFile"
                            value={''}
                            onChange={e => setProfilePic(e?.target?.files?.[0])}
                        />
                    </ModalBody>
                    <ModalFooter>
                        {profilePic ? (
                            isEdit ? (
                                <Button size="lg" onClick={() => setEdit(false)}>
                                    Save & Crop
                                </Button>
                            ) : (
                                <ButtonGroup>
                                    <Button
                                        variant="secondary"
                                        size="lg"
                                        onClick={() => setProfilePic(undefined)}
                                    >
                                        Remove
                                    </Button>
                                    <Button size="lg" onClick={() => setEdit(true)}>
                                        Edit
                                    </Button>
                                </ButtonGroup>
                            )
                        ) : (
                            <Button as="label" size="lg" htmlFor="uploadFile">
                                Upload
                            </Button>
                        )}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function CropImage({
    profilePic,
    setImageCrop,
}: {
    profilePic: string;
    setImageCrop: React.Dispatch<React.SetStateAction<Area | undefined>>;
}) {
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setImageCrop(croppedArea);
        console.log(croppedArea, croppedAreaPixels);
    };

    return (
        <Cropper
            image={profilePic}
            crop={crop}
            zoom={zoom}
            aspect={2 / 2}
            onCropChange={w => setCrop(w)}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
        />
    );
}

function EditAbout() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdateUserInfo>();
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Bio updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <Button variant="light" size="lg" w="100px" onClick={onOpen}>
                Edit
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        About
                    </ModalHeader>
                    <ModalCloseButton />
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <FormControl mb="32px" isInvalid={Boolean(errors?.title)}>
                                <FormLabel mb="14px">Title</FormLabel>
                                <Input
                                    {...register('title', { required: 'Tittle required' })}
                                    defaultValue="Full-Stack Blockchain Developer (Solidity, Smart Contract, NFT, Wallet)"
                                    variant="primary"
                                />
                                <FormErrorMessage>
                                    {errors?.title && errors?.title?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                mt="14px"
                                mb="32px"
                                isInvalid={Boolean(errors?.introduction)}
                            >
                                <FormLabel mt="14px">Overview</FormLabel>
                                <Textarea
                                    placeholder="Type here"
                                    variant="primary"
                                    minH="240px"
                                    {...register('introduction', { required: 'Overview required' })}
                                    defaultValue="Full-Stack Blockchain Developer (Solidity, Smart Contract, NFT, Wallet)"
                                />
                                <FormErrorMessage>
                                    {errors?.introduction && errors?.introduction?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="secondary" size="lg" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button size="lg" isLoading={isLoading} type="submit">
                                    Save & Update
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}

function EditLanguage() {
    const { onOpen, isOpen, onClose } = useDisclosure();
    const { mutate: updateTalentProfile, isLoading, isSuccess, reset } = useUpdateTalentProfile();

    const methods = useForm<UpdateUserInfo>({
        defaultValues: {
            languages: [{ language: 'english', level: 'basic' }],
        },
    });
    const onSubmit = (values: UpdateUserInfo) => updateTalentProfile(values);

    useEffect(() => {
        if (isSuccess) {
            onClose();
            reset();
            showToast('success', 'Language updated successfully!');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <>
            <IconButton
                aria-label="edit profile detail"
                icon={<EditBtnIcon />}
                variant="unstyled"
                minW="max-content"
                onClick={onOpen}
            />
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Add / Edit Language
                    </ModalHeader>

                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Add languages that you know to get more clients from all over the globe.
                        </Text>
                        <FormProvider {...methods}>
                            <Box as="form">
                                <AddLanguage />
                            </Box>
                        </FormProvider>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button variant="secondary" size="lg" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                size="lg"
                                isLoading={isLoading}
                                onClick={methods.handleSubmit(onSubmit)}
                            >
                                Save & Update
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function ChangePasswoard() {
    const { onOpen, isOpen, onClose } = useDisclosure();

    const {
        register,
        handleSubmit,
        formState: { errors, isLoading },
    } = useForm<UpdateUserPassword>();
    const onSubmit = (values: UpdateUserPassword) => console.log(values);

    // useEffect(() => {
    //     if (isSuccess) {
    //         onClose();
    //         reset();
    //         showToast('success', 'Bio updated successfully!');
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isSuccess]);

    return (
        <>
            <Button variant="light" size="lg" onClick={onOpen}>
                Change Password
            </Button>
            <Modal isOpen={isOpen} isCentered onClose={onClose} size="2xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader as="h2" color="heading">
                        Change Password
                    </ModalHeader>
                    <ModalCloseButton />
                    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody>
                            <Text>Confirm your old password.</Text>
                            <FormControl mt="30px" isInvalid={Boolean(errors?.password)}>
                                <FormLabel mb="10px">Enter Old Password</FormLabel>
                                <Input
                                    {...register('password', { required: 'Password required' })}
                                    placeholder="Password"
                                    variant="primary"
                                    size="lg"
                                    type="password"
                                />
                                <FormErrorMessage>
                                    {errors?.password && errors?.password?.message}
                                </FormErrorMessage>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="secondary" size="lg" onClick={onClose}>
                                    Cancel
                                </Button>
                                <Button size="lg" isLoading={isLoading} type="submit">
                                    Save & Update
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </Box>
                </ModalContent>
            </Modal>
        </>
    );
}
