import React, { useState } from 'react';
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Radio,
    RadioGroup,
    Text,
} from '@chakra-ui/react';
import { CheckIcon, FreelancerIcon, GroupIcon } from 'assets/icons';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import UserAuth from './signupForm';
import VerifyEmail from './verifyEmail';
import { User } from 'utils/types/user.type';
import { setSignupType } from 'utils/helper';

export default function SignUp() {
    const [userStep, setUserStep] = useState(1);
    const [userData, setUserData] = useState<User | undefined>(undefined);

    return (
        <>
            <Container maxW="1280px" pt="25px">
                <Link to="/">
                    <Image src={logo} />
                </Link>
            </Container>
            {userStep === 3 ? (
                <VerifyEmail userData={userData} />
            ) : userStep === 2 ? (
                <UserAuth setUserStep={setUserStep} setUserData={setUserData} />
            ) : (
                <ContinueAs setUserStep={setUserStep} />
            )}
        </>
    );
}

function ContinueAs({
    setUserStep,
}: {
    setUserStep: React.Dispatch<React.SetStateAction<number>>;
}) {
    const [type, setType] = useState('freelancer');
    const handleType = (val: string) => {
        setType(val);
        setSignupType(val);
    };
    return (
        <Center minH="calc(100vh - 50px)">
            <Box textAlign="center">
                <Heading fontSize="32px">Join as a client or freelancer</Heading>
                <RadioGroup mt="64px" defaultValue="freelancer" onChange={handleType}>
                    <Radio variant="primary" value="client">
                        <GroupIcon />
                        <Text color="placeholder" flex="1">
                            I’m a client, hiring for a project
                        </Text>
                        <CheckIcon />
                    </Radio>
                    <Radio variant="primary" value="freelancer" mt="24px">
                        <FreelancerIcon />{' '}
                        <Text color="placeholder" flex="1">
                            I’m a freelancer, Looking for work
                        </Text>
                        <CheckIcon />
                    </Radio>
                </RadioGroup>
                <Button mt="40px" size="lg" w="100%" onClick={() => setUserStep(2)}>
                    Join as a {`${type}`}
                </Button>
                <Text mt="64px">
                    Already have an account? <Button variant="link">Log in</Button>
                </Text>
            </Box>
        </Center>
    );
}
