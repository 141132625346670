import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';
import { createStandaloneToast } from '@chakra-ui/react';
import ScrollToTop from 'components/scrollToTop';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

const { ToastContainer } = createStandaloneToast();
function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes />
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
