export default {
    primary: {
        50: '#EAF3FF',
        100: '#D5E8FF',
        200: '#ABD1FF',
        300: '#81BAFF',
        400: '#57A3FF',
        500: '#2D8CFF',
        600: '#1F84FF',
        700: '#0A78FF',
        800: '#006EF5',
        900: '#0065E0',
    },
    text: '#535353',
    placeholder: '#A9A9A9',
    background: '#FFFFFF',
    darkRed: '#EA3200',
    heading: '#282828',
    lightGray: '#E9E9E9',
    bgGray: '#F8F8F8',
    border: '#D4D4D4',
    success: '#59C778',
};
