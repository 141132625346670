import React, { useState } from 'react';
import Footer from 'components/footer';
import Header from 'components/header';
import {
    Card,
    Container,
    Flex,
    Text,
    InputGroup,
    InputLeftAddon,
    Input,
    Box,
    Avatar,
    Center,
    Button,
    Divider,
    VStack,
    Image,
    Collapse,
    IconButton,
    SlideFade,
    Textarea,
} from '@chakra-ui/react';
import {
    AddIcon,
    AttachmentIcon,
    CancelIcon,
    DeleteIcon,
    DoubleTickIcon,
    EditIcon,
    FileIcon,
    FolderIcon,
    LinkIcon,
    MenuDotsIcon,
    MicIcon,
    PDFIcon,
    // PlayIcon,
    QuoteIcon,
    SendBtnIcon,
    SettingIcon,
    TagIcon,
    // VolumeIcon,
} from 'assets/icons/messages';
import { SearchIcon } from 'assets/icons';
import { JobIcon } from 'assets/icons/user';
import { MentionsInput, Mention } from 'react-mentions';
import AudioPlayer from 'components/audioPlayer';
import AudioRecorder from 'components/audioRecorder';
// { RHAP_UI } from 'react-h5-audio-player';
// import 'react-h5-audio-player/lib/styles.css';

export default function Messages() {
    return (
        <>
            <Header />
            <Container maxW="1380px" mt="50px">
                <Flex gap="24px">
                    <Card flex="308px" p="16px">
                        <Flex justify="space-between">
                            <Text fontSize="20px" variant="black">
                                Messages
                            </Text>
                            <MenuDotsIcon />
                        </Flex>
                        <InputGroup rounded="8px" border="1px solid #D4D4D4" my="32px">
                            <InputLeftAddon height="auto" bg="transparent" border="none">
                                <SearchIcon />
                            </InputLeftAddon>
                            <Input
                                fontSize="13px"
                                border="none"
                                boxShadow="none"
                                outline="none"
                                focusBorderColor="transparent"
                                placeholder="Search message"
                            />
                        </InputGroup>
                        <Text fontSize="13px">Chats</Text>
                        {[...Array(5)]?.map((_item, i) => (
                            <Flex
                                mt="16px"
                                p="15px"
                                gap="9px"
                                align="center"
                                rounded="8px"
                                key={i}
                                bg={i == 0 ? 'primary.50' : ''}
                            >
                                <Avatar />
                                <Box flex="1">
                                    <Flex justify="space-between">
                                        <Text variant="black" fontSize="18px" fontWeight="600">
                                            Esther Howard
                                        </Text>
                                        <Text fontSize="13px">5 Mins ago</Text>
                                    </Flex>
                                    <Flex justify="space-between" mt="7px" align="flex-end">
                                        <Text fontSize="14px">I had uploaded new files</Text>
                                        {i == 0 && (
                                            <Text
                                                as={Center}
                                                variant="primary"
                                                bg="#EA3200"
                                                h="20px"
                                                w="20px"
                                                rounded="50%"
                                                fontSize="12px"
                                            >
                                                2
                                            </Text>
                                        )}
                                    </Flex>
                                </Box>
                            </Flex>
                        ))}
                    </Card>
                    <Card flex="584px" pb="24px">
                        <Flex
                            p="24px"
                            gap="12px"
                            align="center"
                            bg="bgGray"
                            borderRadius="16px 16px 0 0"
                        >
                            <Avatar size="lg" />
                            <Box flex="1">
                                <Text variant="black" fontSize="25px" fontWeight="600">
                                    Alex Rogue
                                </Text>
                                <Text>Online now</Text>
                            </Box>
                        </Flex>
                        <VStack
                            minH="600px"
                            py="35px"
                            px="24px"
                            flexDirection="column-reverse"
                            spacing="24px"
                        >
                            <DisplayChat type="voice" />
                            <DisplayChat type="voice" isSender />
                            <DisplayChat type="text" isSender />
                            <DisplayChat type="media" />
                            <DisplayChat type="text" isSender />
                        </VStack>
                        <UserChatTypeBox />
                    </Card>
                    <Card flex="308px" p="24px">
                        <UserDetails />
                    </Card>
                </Flex>
            </Container>
            <Footer />
        </>
    );
}

function DisplayChat({ type, isSender }: { type: string; isSender?: boolean }) {
    return (
        <Flex gap="16px" w="100%" dir={isSender ? 'rtl' : ''}>
            <Avatar />
            <Box>
                <Flex align="center" gap="8px">
                    <Box
                        bg="bgGray"
                        rounded="16px"
                        dir="ltr"
                        overflow="hidden"
                        minW="200px"
                        maxW="380px"
                    >
                        {type === 'text' && (
                            <Text p="16px">
                                Lorem ipsum dolor sit amet consectetur. Mattis quisque tortor turpis
                                orci ridiculus metus ultrices mattis enim. Tellus condimentum eu est
                                urna.
                            </Text>
                        )}
                        {type === 'media' && (
                            <Image
                                maxW="207px"
                                src="https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
                            />
                        )}
                        {type === 'voice' && (
                            <AudioPlayer audio="https://previews.customer.envatousercontent.com/files/456553788/preview.mp3" />
                        )}
                    </Box>
                    {isSender && (
                        <>
                            <DeleteIcon />
                            <EditIcon />
                        </>
                    )}
                    <QuoteIcon />
                </Flex>
                <Flex gap="12px" align="center" w="max-content" mt="10px" dir="ltr">
                    <Text fontSize="13px">28 April, 2023</Text>
                    {isSender && <DoubleTickIcon />}
                </Flex>
            </Box>
        </Flex>
    );
}

function UserChatTypeBox() {
    const [userMessage, setUserMessage] = useState('');
    const [userAudioMessage, setUserAudioMessage] = useState(false);

    return (
        <Box
            border="1px solid "
            borderColor="border"
            bg={userAudioMessage ? 'bgGray' : ''}
            mx="24px"
            p="16px"
            rounded="16px"
        >
            {userAudioMessage ? (
                <AudioRecorder />
            ) : (
                <>
                    <MentionsInput
                        value={userMessage}
                        onChange={e => setUserMessage(e?.target?.value)}
                        placeholder="Type your message...."
                        style={{ height: '65px' }}
                    >
                        <Mention
                            trigger="@"
                            data={[
                                { id: 1, display: 'John' },
                                { id: 2, display: 'Mack' },
                            ]}
                        />
                    </MentionsInput>
                    <Flex mt="16px" justify="space-between">
                        <Box></Box>
                        <Flex gap="6px" align="center">
                            <AttachmentIcon />
                            <Box as={TagIcon} onClick={() => setUserAudioMessage(true)} />
                            <Box onClick={() => setUserAudioMessage(true)} cursor="pointer">
                                <MicIcon />
                            </Box>
                            <SettingIcon />
                            <Divider orientation="vertical" borderColor="border" h="24px" />
                            <SendBtnIcon />
                        </Flex>
                    </Flex>
                </>
            )}
        </Box>
    );
}
function UserDetails() {
    const [userFiles, setUserFiles] = useState(false);
    const [userNotes, setUserNotes] = useState(false);
    return (
        <>
            <Collapse animateOpacity in={!(userFiles || userNotes)}>
                <Avatar size="xl" mx="auto" display="block" />
                <Text mt="18px" as="h4" fontSize="25px" variant="black" textAlign="center">
                    Alex Rogue
                </Text>
                <Box my="32px" p="16px" bg="bgGray" textAlign="center" rounded="8px">
                    <JobIcon fill="#2D8CFF" />
                    <Text variant="black" fontSize="13px">
                        Looking for blockchain developer for Bitcoin.
                    </Text>
                </Box>
                <Text fontSize="13px">People</Text>
                <Flex mt="24px" gap="12px" align="center">
                    <Avatar />
                    <Box flex="1">
                        <Text variant="black" fontSize="18px" fontWeight="600">
                            Jerome Bell
                        </Text>
                        <Text fontSize="13px">Admin</Text>
                    </Box>
                </Flex>
                <Flex mt="24px" gap="12px" align="center">
                    <Avatar />
                    <Box flex="1">
                        <Text variant="black" fontSize="18px" fontWeight="600">
                            Jerome Bell
                        </Text>
                        <Text fontSize="13px">Admin</Text>
                    </Box>
                </Flex>
                <Button
                    mt="24px"
                    p="8px"
                    gap="14px"
                    justifyContent="flex-start"
                    border="none"
                    variant="light"
                    h="auto"
                    w="100%"
                >
                    <AddIcon />
                    Add Member
                </Button>
                <Text fontSize="13px" mt="32px">
                    More actions
                </Text>
                <InputGroup rounded="8px" border="1px solid #D4D4D4" mt="24px">
                    <InputLeftAddon height="auto" bg="transparent" border="none">
                        <SearchIcon />
                    </InputLeftAddon>
                    <Input
                        fontSize="13px"
                        border="none"
                        boxShadow="none"
                        outline="none"
                        focusBorderColor="transparent"
                        placeholder="Search"
                    />
                </InputGroup>
                <Button
                    variant="unstyled"
                    mt="10px"
                    textAlign="left"
                    leftIcon={<FolderIcon />}
                    onClick={() => setUserFiles(!userFiles)}
                >
                    <Text as="span" verticalAlign="super">
                        Files & links
                    </Text>
                </Button>
                <Button
                    variant="unstyled"
                    mt="10px"
                    textAlign="left"
                    leftIcon={<FileIcon />}
                    onClick={() => setUserNotes(!userNotes)}
                >
                    <Text as="span" verticalAlign="super">
                        Personal Notepad
                    </Text>
                </Button>
            </Collapse>
            <SharedFiles isOpen={userFiles} onCancel={() => setUserFiles(!userFiles)} />
            <SharedNotes isOpen={userNotes} onCancel={() => setUserNotes(!userNotes)} />
        </>
    );
}

function SharedFiles({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) {
    return (
        <Collapse animateOpacity in={isOpen}>
            <Flex justify="space-between">
                <Flex align="center" gap="10px">
                    <FolderIcon />
                    <Text as="span">Files & links</Text>
                </Flex>
                <IconButton
                    aria-label="back to profile"
                    onClick={() => onCancel()}
                    icon={<CancelIcon />}
                    variant="unstyled"
                    minW="max-content"
                />
            </Flex>
            <Text fontSize="13px" mt="32px">
                Shared Images
            </Text>
            <Flex gap="8px" wrap="wrap" mt="16px">
                {[...Array(4)]?.map(item => (
                    <Image
                        key={item}
                        rounded="8px"
                        w="141px"
                        h="141px"
                        objectFit="cover"
                        src="https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60"
                    />
                ))}
            </Flex>
            <Text fontSize="13px" mt="32px">
                Shared Files
            </Text>
            <FileUtils icon={<PDFIcon />} name="file_name_83920.pdf" value="3.1 MB" />
            <Text fontSize="13px" mt="32px">
                Shared Links
            </Text>
            <FileUtils
                icon={<LinkIcon />}
                name="Figma Project"
                value="http//www.figma.com/project"
            />
        </Collapse>
    );
}

function SharedNotes({ isOpen, onCancel }: { isOpen: boolean; onCancel: () => void }) {
    const [addNote, setAddNote] = useState(false);
    return (
        <Collapse animateOpacity in={isOpen} style={{ overflow: 'unset' }}>
            <Flex justify="space-between">
                <Flex align="center" gap="10px">
                    <FileIcon />
                    <Text as="span">Personal Notepad</Text>
                </Flex>
                <IconButton
                    aria-label="back to profile"
                    onClick={() => onCancel()}
                    icon={<CancelIcon />}
                    variant="unstyled"
                    minW="max-content"
                />
            </Flex>
            <SlideFade
                in={!addNote}
                initial={{ height: 0 }}
                style={{ pointerEvents: addNote ? 'none' : 'all' }}
            >
                <>
                    <Button
                        mt="24px"
                        p="8px"
                        gap="14px"
                        justifyContent="flex-start"
                        border="none"
                        variant="light"
                        h="auto"
                        w="100%"
                        onClick={() => setAddNote(!addNote)}
                    >
                        <AddIcon />
                        Add Note
                    </Button>
                    <Box
                        p="16px"
                        my="16px"
                        rounded="8px"
                        _hover={{
                            boxShadow: '0px 5px 30px 0px rgba(0, 0, 0, 0.08)',
                        }}
                    >
                        <Flex justify="space-between">
                            <Text as="span" fontSize="10px">
                                20 June
                            </Text>{' '}
                            <CancelIcon />
                        </Flex>
                        <Text as="h6" variant="black" my="12px">
                            NFTs Notes
                        </Text>
                        <Text fontSize="13px">
                            Lorem ipsum dolor sit amet consectetur. Ut tempus massa nunc sed
                            maecenas suspendisse nunc. Nisl at pharetra id mattis enim sit
                            tincidunt.
                        </Text>
                    </Box>
                </>
            </SlideFade>
            <SlideFade in={addNote} style={{ pointerEvents: addNote ? 'all' : 'none' }}>
                <Box bg="bgGray" p="16px">
                    <Text as="label" fontSize="12px">
                        Tittle
                    </Text>
                    <Input mt="8px" placeholder="title" variant="primary" fontSize="14px" />
                    <Text mt="24px" as="label" fontSize="12px">
                        Note
                    </Text>
                    <Textarea mt="8px" placeholder="Add note..." fontSize="14px" />
                    <Button mt="24px" w="100%" onClick={() => setAddNote(!addNote)}>
                        Add Note
                    </Button>
                </Box>
            </SlideFade>
        </Collapse>
    );
}

// eslint-disable-next-line no-undef
function FileUtils({ icon, name, value }: { icon: JSX.Element; name: string; value: string }) {
    return (
        <Flex p="16px" gap="12px">
            {icon}
            <Box>
                <Text variant="black">{name}</Text>
                <Text fontSize="13px">{value}</Text>
            </Box>
        </Flex>
    );
}
