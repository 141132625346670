import React, { useState } from 'react';
import {
    useMediaQuery,
    Flex,
    Text,
    Button,
    IconButton,
    Box,
    List,
    ListItem,
    Slide,
    Fade,
    useDisclosure,
    Image,
    Container,
    Divider,
    Heading,
    InputLeftAddon,
    InputGroup,
    Input,
    VStack,
    Avatar,
    ButtonGroup,
} from '@chakra-ui/react';
import { JOB_MENU, LogIn_MENU, MENU } from 'utils/const';
import { Link, NavLink } from 'react-router-dom';
import { CloseMenuIcon, HamburgerIcon, SearchIcon } from 'assets/icons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { intoView } from 'utils/helper';
import logo from 'assets/images/logo.png';
import SubmenuImg from 'assets/images/submenu-img.png';
import Category1 from 'assets/images/category1.png';
import NotificationPopUp from './notificationPopUp';
import { useIsLogin } from 'utils/hooks';

export default function Header() {
    const [currentMenu, setCurrentMenu] = useState('job');

    const [isLargerThan960] = useMediaQuery('(min-width: 960px)', { ssr: false });

    const { isOpen, onClose, onOpen } = useDisclosure();

    const isLogin = useIsLogin();
    return (
        <Box as="header" zIndex="2" position="relative">
            <Container maxW="1280px">
                <Flex alignItems="center">
                    <Flex
                        py={{ base: '15px', lg: '20px' }}
                        align="center"
                        flex={{ base: '1', lg: 'none' }}
                        // bg="primaryGradient"
                    >
                        <NavLink to="/">
                            <Image src={logo} />
                        </NavLink>
                        {!isLargerThan960 && <MobileMenu onClick={intoView} />}
                    </Flex>
                    {isLargerThan960 && (
                        <Flex as="nav" mx="auto" align="center">
                            {(isLogin ? LogIn_MENU : MENU)?.map(({ label, path, subMenu }) => (
                                <>
                                    <Text
                                        onClick={() => intoView(path)}
                                        as={NavLink}
                                        to={path}
                                        key={label}
                                        variant="link"
                                        py={{ base: '14px', lg: '15px' }}
                                        onMouseEnter={() => subMenu && onOpen()}
                                        onMouseLeave={() => subMenu && onClose()}
                                        ml="25px"
                                    >
                                        {label}
                                        {subMenu && <ChevronDownIcon fontWeight="700" />}
                                    </Text>
                                </>
                            ))}
                        </Flex>
                    )}
                    {isLargerThan960 && (
                        <Flex gap="20px" align="center">
                            <InputGroup maxW="220px" size="sm">
                                <InputLeftAddon
                                    height="auto"
                                    bg="transparent"
                                    borderColor="border"
                                    rounded="6px"
                                >
                                    <SearchIcon />
                                </InputLeftAddon>
                                <Input
                                    pl="0"
                                    variant="primary"
                                    placeholder="Search for job"
                                    rounded="6px"
                                    borderLeft="none"
                                />
                            </InputGroup>
                            {isLogin ? (
                                <>
                                    <NotificationPopUp />
                                    <Link to="/profile">
                                        <Avatar size="sm" />
                                    </Link>
                                </>
                            ) : (
                                <ButtonGroup>
                                    <Button
                                        to="/sign-up"
                                        variant="light"
                                        as={NavLink}
                                        fontSize="12px"
                                        minW="92px"
                                    >
                                        Sig Up
                                    </Button>
                                    <Button to="/sign-in" as={NavLink} fontSize="12px" minW="92px">
                                        Sig In
                                    </Button>
                                </ButtonGroup>
                            )}
                        </Flex>
                    )}
                </Flex>
                <Box
                    as={Fade}
                    in={isOpen}
                    bg="white"
                    boxShadow="0px 8px 26px 0px #00000014"
                    borderTop="1px solid #D4D4D4"
                    position="absolute"
                    top="65px"
                    left="0"
                    w="100%"
                    pointerEvents={isOpen ? 'all' : 'none'}
                    onMouseEnter={() => onOpen()}
                    onMouseLeave={() => onClose()}
                    zIndex="2"
                >
                    <Box maxW="1280px" mx="auto" pb="80px">
                        <Flex>
                            <Button
                                variant="tabBtn"
                                className={currentMenu === 'job' ? 'active' : ''}
                                onClick={() => setCurrentMenu('job')}
                            >
                                Post a job & hire the best talent
                            </Button>
                            <Button
                                variant="tabBtn"
                                pl="48px"
                                className={currentMenu === 'projects' ? 'active' : ''}
                                onClick={() => setCurrentMenu('projects')}
                            >
                                Buy Projects
                            </Button>
                        </Flex>
                        {currentMenu === 'job' && <JobMarket />}
                        {currentMenu === 'projects' && <ProjectMarket />}
                    </Box>
                </Box>
                <Divider borderColor="border" />
                {isLargerThan960 && (
                    <Flex as="nav" mx="auto" align="center" justify="center">
                        {JOB_MENU.map(({ label, path }) => (
                            <>
                                <Text
                                    onClick={() => intoView(path)}
                                    as={NavLink}
                                    to={path}
                                    key={label}
                                    variant="link"
                                    py="10px"
                                    ml="25px"
                                >
                                    {label}
                                </Text>
                            </>
                        ))}
                    </Flex>
                )}
            </Container>
        </Box>
    );
}

// eslint-disable-next-line no-unused-vars
function MobileMenu({ onClick }: { onClick: (path: string) => void }) {
    const { onToggle, isOpen } = useDisclosure();
    return (
        <>
            <IconButton
                ml="auto"
                aria-label="menu-icon"
                onClick={() => onToggle()}
                icon={isOpen ? <CloseMenuIcon /> : <HamburgerIcon />}
                variant="unstyled"
            />
            <Box as={Slide} in={isOpen} sx={{ pointerEvents: 'none' }}>
                <List variant="mobMenu">
                    {MENU.map(({ label, path }) => (
                        <ListItem onClick={() => onClick(path)} as={NavLink} to={path} key={label}>
                            {label}
                        </ListItem>
                    ))}
                </List>
            </Box>
        </>
    );
}

function JobMarket() {
    return (
        <Flex justify="space-between" mt="55px">
            <Box
                pr="30px"
                minW="250px"
                maxW="465px"
                w="100%"
                borderRight="1px solid transparent"
                borderColor="lightgray"
            >
                <Heading as="h5" fontSize="20px">
                    Job marketplace
                </Heading>
                <Text my="32px">
                    Find top talent and post jobs to build your dream team for successful project
                    execution.
                </Text>
                <Button size="lg">Post a job now</Button>
            </Box>
            <Box px="30px" minW="230px" maxW="260px" w="100%">
                <List spacing="30px">
                    {[...Array(9)]?.map(item => (
                        <Text as={ListItem} variant="link" fontSize="14px" key={item}>
                            Blockchain Development
                        </Text>
                    ))}
                </List>
                <Button mt="25px" w="100%" fontSize="14px">
                    See All Specializations
                </Button>
            </Box>
            <Box pl="30px" minW="250px" maxW="282px" w="100%">
                <Image src={SubmenuImg} />
            </Box>
        </Flex>
    );
}

function ProjectMarket() {
    return (
        <Flex justify="space-between" mt="55px">
            <Box
                pr="30px"
                minW="250px"
                maxW="465px"
                w="100%"
                borderRight="1px solid transparent"
                borderColor="lightgray"
            >
                <Heading as="h5" fontSize="20px">
                    Looking for a specific projects
                </Heading>
                <Text my="32px">
                    Explore a diverse range of projects available for purchase, fulfilling your
                    specific requirements and expanding your business opportunities.
                </Text>
                <Button size="lg">Explore Now</Button>
            </Box>
            <Flex gap="24px" maxW="723px" w="100%" wrap="wrap">
                {[...Array(9)]?.map(item => (
                    <Box
                        maxW="225px"
                        key={item}
                        borderRadius="8px"
                        position="relative"
                        overflow="hidden"
                    >
                        <VStack
                            position="absolute"
                            background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.33%, #000 100%)"
                            w="100%"
                            h="100%"
                            top="0"
                            align="left"
                            p="24px 16px"
                            gap="0"
                        >
                            <Text mt="auto" textAlign="left" variant="primary" fontSize="13px">
                                Make your own brand
                            </Text>
                            <Text fontSize="20px" textAlign="left" variant="primary">
                                Logo Design
                            </Text>
                        </VStack>
                        <Image src={Category1} w="100%" objectFit="cover" />
                    </Box>
                ))}
            </Flex>
        </Flex>
    );
}
