import { Button, Flex, IconButton, Text } from '@chakra-ui/react';
import { DeleteBtnIcon, PlayIcon, SendBtnIcon } from 'assets/icons/messages';
import AudioPlayer from 'components/audioPlayer';
import React, { useState } from 'react';
import { WaveformAudioRecorder, WaveformAudioRecorderType } from 'waveform-audio-recorder';

export default function AudioRecorder() {
    const [recorderState, setRecorderState] = useState<WaveformAudioRecorderType | null>(null);

    return (
        <Flex py="24px" gap="24px" align="center">
            {recorderState?.audio ? (
                <>
                    <IconButton
                        aria-label="cancel audio"
                        bg="transparent"
                        _hover={{}}
                        _active={{}}
                        icon={<DeleteBtnIcon />}
                        onClick={() => {
                            recorderState?.cancelRecording;
                            setRecorderState({ ...recorderState, audio: '' });
                        }}
                    />
                    <AudioPlayer audio={recorderState?.audio} isChat />
                    <SendBtnIcon color="#2D8CFF" />
                </>
            ) : (
                <Flex
                    w="100%"
                    sx={{ canvas: { maxW: '260px' } }}
                    gap="24px"
                    justify="space-between"
                    bg="white"
                    p="10px"
                    rounded="8px"
                    align="center"
                >
                    <Button
                        variant="unstyled"
                        onClick={
                            recorderState?.initRecording
                                ? recorderState?.saveRecording
                                : recorderState?.startRecording
                        }
                    >
                        {recorderState?.initRecording ? 'Stop' : <PlayIcon />}
                    </Button>
                    <WaveformAudioRecorder setRecorderState={setRecorderState} />
                    <Text>{recorderState?.recordingDuration || '0:00'}</Text>
                </Flex>
            )}
        </Flex>
    );
}
