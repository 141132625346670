import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    Heading,
    Image,
    Input,
    Text,
    Textarea,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import logo from 'assets/images/logo.png';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import AddSkills from 'components/addSkills';
import AddLanguage from 'components/addLanguage';
import { UpdateUserInfo } from 'utils/types/user.type';
import { useUpdateTalentProfile } from 'utils/apis/user.api';
import { getSignupType } from 'utils/helper';

export default function UserInfo() {
    const navigate = useNavigate();
    const type = getSignupType();

    const [step, setStep] = useState(type === 'client' ? '02' : '01');

    const { mutate: updateTalentInfo, isLoading, isSuccess } = useUpdateTalentProfile();
    const methods = useForm<UpdateUserInfo>({
        defaultValues: {
            languages: [{ language: 'english', level: 'basic' }],
            skills: [],
        },
    });
    const onSubmit = (data: UpdateUserInfo) => {
        if (step === '01') return setStep('02');
        else if (step === '02') return type === 'client' ? setStep('04') : setStep('03');
        else if (step === '03') return setStep('04');

        if (step === '04') {
            console.log(data);
            updateTalentInfo({ ...data, isOnboarded: true });
        }
    };

    const handleGoBack = () => {
        if (step === '01') {
            navigate('/add-wallet');
        } else if (step === '02') {
            setStep('01');
        } else if (step === '03') {
            setStep('02');
        } else if (step === '04') {
            type === 'client' ? setStep('02') : setStep('03');
        }
    };

    useEffect(() => {
        if (isSuccess) {
            navigate(type === 'client' ? '/connect-wallet' : '/profile');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    return (
        <Box minH="100vh" pb="90px">
            <Container maxW="1280px" pt="25px">
                <Link to="/">
                    <Image src={logo} />
                </Link>
                <Flex mt="60px">
                    {!(type === 'client' && step === '02') && (
                        <Button leftIcon={<ArrowLeftIcon />} onClick={handleGoBack} variant="link">
                            Back
                        </Button>
                    )}
                    {!(type === 'client') && (
                        <Text variant="secondary" flex="1" textAlign="right">
                            <Text as="span" variant="secondary" fontSize="25px">
                                {step}
                            </Text>
                            /04
                        </Text>
                    )}
                </Flex>
            </Container>
            <FormProvider {...methods}>
                <Box pt="50px" as="form" onSubmit={methods.handleSubmit(onSubmit)}>
                    {step === '01' && <GetTitle />}
                    {step === '02' && <GetLanguage />}
                    {step === '03' && <GetSkills />}
                    {step === '04' && <GetBio isLoading={isLoading} />}
                </Box>
            </FormProvider>
            <Box position="absolute" bottom="40px" w="100%" bg="bgGray">
                <Box bg="primary.500" h="10px" w="25%" />
            </Box>
        </Box>
    );
}

function GetTitle() {
    const {
        register,
        formState: { errors },
    } = useFormContext<{ title: string }>();
    return (
        <Box mx="auto" maxW="520px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Next, showcase your expertise with a captivating title that grabs {`clients'`}
                attention.
            </Heading>
            <Text mt="32px" textAlign="center">
                Your title is the first impression, so make it impactful and unique. Describe your
                professional role in your own words.
            </Text>

            <FormControl mt="64px" isInvalid={Boolean(errors.title)} mb="25px">
                <Input
                    size="lg"
                    _autofill={{ bg: 'none' }}
                    focusBorderColor="primary.500"
                    placeholder="ex: Software Engineer | Content Writer | etc"
                    {...register('title', {
                        required: 'Title required',
                    })}
                    variant="primary"
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <Button mt="40px" type="submit" size="lg" w="100%">
                Continue
            </Button>
        </Box>
    );
}

function GetLanguage() {
    const type = getSignupType();

    return (
        <Box mx="auto" maxW="700px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Great job so far! Now, let us know the languages you speak.
            </Heading>
            <Text mt="32px" textAlign="center">
                StopTalent operates worldwide, and clients value knowing the languages you can
                communicate in. English is essential, but do you speak any other languages?
            </Text>
            <AddLanguage />
            <Button
                mt="40px"
                mx="auto"
                type="submit"
                size="lg"
                w="100%"
                maxW="520px"
                display="flex"
            >
                {`Continue & Next, add ${type === 'client' ? 'Overview' : 'your Skills'}`}
            </Button>
        </Box>
    );
}

function GetSkills() {
    return (
        <Box mx="auto" maxW="520px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Almost there! What type of work brings you here?
            </Heading>
            <Text my="32px" textAlign="center">
                Your skills showcase your expertise to clients and assist us in recommending
                suitable jobs for you. Feel free to add, remove, or type to select additional
                skills.
            </Text>
            <AddSkills />
            <Button mt="40px" type="submit" size="lg" w="100%">
                Continue & Next, write overview
            </Button>
        </Box>
    );
}

function GetBio({ isLoading }: { isLoading: boolean }) {
    const {
        register,
        formState: { errors },
    } = useFormContext<{ introduction: string }>();
    return (
        <Box mx="auto" maxW="520px">
            <Heading fontSize={{ base: '24px', md: '31px' }} textAlign="center" mt="40px">
                Excellent! {`Let's`} create a bio to introduce yourself to the world.
            </Heading>
            <Text mt="32px" textAlign="center" mb="64px">
                Provide a concise overview of your expertise. Clearly highlight the type of work you
                excel at, using paragraphs or bullet points. Remember, you can edit it later, but
                make sure to proofread it now for accuracy.
            </Text>
            <Text as="span">Overview</Text>
            <FormControl mt="64px" isInvalid={Boolean(errors.introduction)} my="16px">
                <Textarea
                    focusBorderColor="primary.500"
                    placeholder="Type here...."
                    {...register('introduction', {
                        required: 'Overview required',
                    })}
                    variant="primary"
                    minH="240px"
                />
                <FormErrorMessage>
                    {errors.introduction && errors.introduction.message}
                </FormErrorMessage>
            </FormControl>
            <Text as="span">At least 100 characters.</Text>
            <Button mt="40px" type="submit" size="lg" w="100%" isLoading={isLoading}>
                Continue
            </Button>
        </Box>
    );
}
