import React, { useState } from 'react';
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    CheckboxGroup,
    Collapse,
    Container,
    Divider,
    Flex,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Text,
    VStack,
} from '@chakra-ui/react';
import { ChatIcon, HiredBudgetIcon, HiredByIcon, HiredTimeLineIcon } from 'assets/icons/user';
import Footer from 'components/footer';
import Header from 'components/header';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { FilterBtnIcon } from 'assets/icons';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

export default function MyProposals() {
    const [isFilter, setIsFilter] = useState(false);
    const { control, watch } = useForm();
    const dateOfBirth = watch('dateOfBirth');
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="50px">
                <Card p="24px">
                    <Flex justify="space-between" align="center">
                        <Heading as="h1" fontSize="32px">
                            Proposals
                        </Heading>
                        <Flex gap="12px" maxW="464px" w="100%" align="center">
                            <InputGroup maxW="400px" size="lg">
                                <InputLeftAddon
                                    height="auto"
                                    bg="transparent"
                                    borderColor="border"
                                    rounded="6px"
                                >
                                    <SearchIcon color="primary.500" />
                                </InputLeftAddon>
                                <Input
                                    pl="0"
                                    variant="primary"
                                    placeholder="Search for job"
                                    rounded="6px"
                                    borderLeft="none"
                                />
                            </InputGroup>
                            <IconButton
                                icon={<FilterBtnIcon />}
                                aria-label="filter btn"
                                onClick={() => setIsFilter(!isFilter)}
                            />
                        </Flex>
                    </Flex>
                    <Collapse in={isFilter}>
                        <Card p="32px" mt="40px">
                            <Heading as="h3" fontSize="20px">
                                Filter by
                            </Heading>
                            <Flex gap="16px" mt="32px">
                                <Box maxW="240px" w="100%">
                                    <Heading as="h6" fontSize="16px">
                                        Proposal Status
                                    </Heading>
                                    <VStack spacing="14px" align="flex-start" mt="24px">
                                        <CheckboxGroup>
                                            <Checkbox isChecked>Active</Checkbox>
                                            <Checkbox>Completed</Checkbox>
                                            <Checkbox>Pending</Checkbox>
                                            <Checkbox>Pause</Checkbox>
                                        </CheckboxGroup>
                                    </VStack>
                                </Box>
                                <Box maxW="240px" w="100%">
                                    <Heading as="h6" fontSize="16px">
                                        Milestone Status
                                    </Heading>
                                    <VStack spacing="14px" align="flex-start" mt="24px">
                                        <CheckboxGroup>
                                            <Checkbox isChecked>All</Checkbox>
                                            <Checkbox>Active</Checkbox>
                                            <Checkbox>Awaited Refund</Checkbox>
                                            <Checkbox>Payment Refund</Checkbox>
                                        </CheckboxGroup>
                                    </VStack>
                                </Box>
                                <Button ml="auto">Apply Filter</Button>
                            </Flex>
                            <Divider borderColor="border" my="32px" />
                            <Text as="label" variant="black">
                                Proposal Start & End Date
                            </Text>
                            <Flex gap="16px" mt="16px">
                                <Controller
                                    control={control}
                                    name="dateOfBirth"
                                    rules={{
                                        required: 'DOB required',
                                    }}
                                    render={({ field: { ...field } }) => (
                                        <DatePicker
                                            className="chakra-input css-1gpsbw3"
                                            selected={
                                                dateOfBirth ? new Date(dateOfBirth) : undefined
                                            }
                                            {...field}
                                            ref={ref => {
                                                field.ref({
                                                    focus: ref?.setFocus,
                                                });
                                            }}
                                            peekNextMonth
                                            showYearDropdown
                                            showMonthDropdown
                                            placeholderText="From"
                                            dropdownMode="select"
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="dateOfBirth"
                                    rules={{
                                        required: 'DOB required',
                                    }}
                                    render={({ field: { ...field } }) => (
                                        <DatePicker
                                            className="chakra-input css-1gpsbw3"
                                            selected={
                                                dateOfBirth ? new Date(dateOfBirth) : undefined
                                            }
                                            {...field}
                                            ref={ref => {
                                                field.ref({
                                                    focus: ref?.setFocus,
                                                });
                                            }}
                                            peekNextMonth
                                            showYearDropdown
                                            showMonthDropdown
                                            placeholderText="From"
                                            dropdownMode="select"
                                        />
                                    )}
                                />
                            </Flex>
                            <Divider borderColor="border" my="32px" />
                            <Text as="label" variant="black">
                                Sort By
                            </Text>
                            <Flex gap="16px" mt="16px">
                                <Select placeholder="Start Date" />
                                <Select placeholder="Ascending" />
                            </Flex>
                        </Card>
                    </Collapse>
                    <Card p="24px" rounded="8px" mt="40px" as={Link} to="/my-proposal-detail">
                        <Heading as="h3" fontSize="20px">
                            Consultancy for solidity smart contract on Polygon
                        </Heading>
                        <Flex mt="32px" gap="24px">
                            <ProposalUtils
                                icon={<HiredByIcon />}
                                label="Hired By"
                                value="Job Category"
                            />

                            <ProposalUtils
                                icon={<HiredBudgetIcon />}
                                label="Budget"
                                value={
                                    <>
                                        <Text fontSize="22px" as="strong">
                                            200.30
                                        </Text>{' '}
                                        USDT
                                    </>
                                }
                            />
                            <ProposalUtils
                                icon={<HiredTimeLineIcon />}
                                label="Deadline"
                                value="08-01-2023 - 13-06-2023"
                            />
                        </Flex>
                        <Text my="32px">
                            Lorem ipsum dolor sit amet consectetur. Vestibulum a eget lectus neque
                            sagittis sagittis placerat porttitor. In morbi pretium cras eu. Platea
                            amet morbi arcu volutpat nunc viverra tempor. Nascetur lorem varius
                            pellentesque sit nisi auctor ultrices metus.
                        </Text>
                        <ButtonGroup ml="auto">
                            <Button size="lg">
                                <ChatIcon />{' '}
                            </Button>
                            <Button variant="light" size="lg" w="160px">
                                Reject
                            </Button>
                            <Button size="lg" w="160px">
                                Accept
                            </Button>
                        </ButtonGroup>
                    </Card>
                    <Divider borderColor="border" my="40px" />
                    <Flex gap="16px" justify="flex-end" align="center">
                        <Button variant="unstyled" bg="bgGray" p="8px" minW="max-content" h="auto">
                            <ChevronLeftIcon fontSize="22px" />
                        </Button>
                        <Flex align="center" gap="8px">
                            {[...Array(3)]?.map((item, i) => {
                                if (i < 3) {
                                    return (
                                        <Box
                                            key={i}
                                            p="8px"
                                            rounded="4px"
                                            bg={i === 0 ? 'primary.50' : 'bgGray'}
                                            color={i === 0 ? 'primary.500' : ''}
                                            minW="34px"
                                            textAlign="center"
                                            cursor="pointer"
                                        >
                                            {i + 1}
                                        </Box>
                                    );
                                }
                            })}
                            <Box p="8px 10px" rounded="4px" bg="bgGray">
                                ...
                            </Box>
                        </Flex>
                        <Button variant="unstyled" bg="bgGray" p="8px" minW="max-content" h="auto">
                            <ChevronRightIcon fontSize="22px" />
                        </Button>
                    </Flex>
                </Card>
            </Container>
            <Footer />
        </>
    );
}

function ProposalUtils({
    icon,
    label,
    value,
    isEdit,
}: {
    // eslint-disable-next-line no-undef
    icon: JSX.Element;
    label: string;
    // eslint-disable-next-line no-undef
    value: string | JSX.Element;
    // eslint-disable-next-line no-undef
    isEdit?: JSX.Element;
}) {
    return (
        <Card
            as={Flex}
            flexDirection="row"
            maxW="380px"
            minW="max-content"
            p="24px 16px"
            align="center"
            gap="20px"
            w="100%"
        >
            {icon}
            <Box>
                <Text display="block" as="span" variant="secondary" fontSize="20px">
                    {label}
                </Text>
                <Text as="span">{value}</Text>
            </Box>
            {isEdit}
        </Card>
    );
}
