import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Checkbox,
    CheckboxGroup,
    Collapse,
    Container,
    Divider,
    Flex,
    Heading,
    IconButton,
    Input,
    InputGroup,
    InputLeftAddon,
    Select,
    Tag,
    Text,
    VStack,
} from '@chakra-ui/react';
import Footer from 'components/footer';
import Header from 'components/header';
import { ChevronLeftIcon, ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import { FilterBtnIcon } from 'assets/icons';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { EditPostIcon } from 'assets/icons/user';

export default function ClientJobListing() {
    const [isFilter, setIsFilter] = useState(false);
    const { control, watch } = useForm();
    const dateOfBirth = watch('dateOfBirth');
    return (
        <>
            <Header />
            <Container maxW="1280px" mt="50px">
                <Card p="24px">
                    <Heading as="h2" fontSize="32px">
                        My Jobs
                    </Heading>
                    <Divider borderColor="border" my="40px" />
                    <Flex justify="space-between" align="center">
                        <Heading as="h3" fontSize="20px">
                            Active Jobs
                        </Heading>
                        <Flex gap="12px" maxW="820px" w="100%" align="center">
                            <InputGroup maxW="400px" w="100%" size="lg">
                                <InputLeftAddon
                                    height="auto"
                                    bg="transparent"
                                    borderColor="border"
                                    rounded="6px"
                                >
                                    <SearchIcon color="primary.500" />
                                </InputLeftAddon>
                                <Input
                                    pl="0"
                                    variant="primary"
                                    placeholder="Search for job"
                                    rounded="6px"
                                    borderLeft="none"
                                />
                            </InputGroup>
                            <IconButton
                                icon={<FilterBtnIcon />}
                                aria-label="filter btn"
                                onClick={() => setIsFilter(!isFilter)}
                            />
                            <Button variant="light" size="lg">
                                Download CSV
                            </Button>
                            <Button size="lg" as={Link} to="/post-job">
                                Post a new job
                            </Button>
                        </Flex>
                    </Flex>
                    <Collapse in={isFilter}>
                        <Card p="32px" mt="56px">
                            <Heading as="h3" fontSize="20px">
                                Filter by
                            </Heading>
                            <Flex gap="16px" mt="32px">
                                <Box maxW="240px" w="100%">
                                    <Heading as="h6" fontSize="16px">
                                        Contract Status
                                    </Heading>
                                    <VStack spacing="14px" align="flex-start" mt="24px">
                                        <CheckboxGroup>
                                            <Checkbox isChecked>Active</Checkbox>
                                            <Checkbox>Completed</Checkbox>
                                            <Checkbox>Pending</Checkbox>
                                            <Checkbox>Pause</Checkbox>
                                        </CheckboxGroup>
                                    </VStack>
                                </Box>
                                <Box maxW="240px" w="100%">
                                    <Heading as="h6" fontSize="16px">
                                        Milestone Status
                                    </Heading>
                                    <VStack spacing="14px" align="flex-start" mt="24px">
                                        <CheckboxGroup>
                                            <Checkbox isChecked>All</Checkbox>
                                            <Checkbox>Active</Checkbox>
                                            <Checkbox>Awaited Refund</Checkbox>
                                            <Checkbox>Payment Refund</Checkbox>
                                        </CheckboxGroup>
                                    </VStack>
                                </Box>
                                <Button ml="auto">Apply Filter</Button>
                            </Flex>
                            <Divider borderColor="border" my="32px" />
                            <Text as="label" variant="black">
                                Contract Start & End Date
                            </Text>
                            <Flex gap="16px" mt="16px">
                                <Controller
                                    control={control}
                                    name="dateOfBirth"
                                    rules={{
                                        required: 'DOB required',
                                    }}
                                    render={({ field: { ...field } }) => (
                                        <DatePicker
                                            className="chakra-input css-1gpsbw3"
                                            selected={
                                                dateOfBirth ? new Date(dateOfBirth) : undefined
                                            }
                                            {...field}
                                            ref={ref => {
                                                field.ref({
                                                    focus: ref?.setFocus,
                                                });
                                            }}
                                            peekNextMonth
                                            showYearDropdown
                                            showMonthDropdown
                                            placeholderText="From"
                                            dropdownMode="select"
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name="dateOfBirth"
                                    rules={{
                                        required: 'DOB required',
                                    }}
                                    render={({ field: { ...field } }) => (
                                        <DatePicker
                                            className="chakra-input css-1gpsbw3"
                                            selected={
                                                dateOfBirth ? new Date(dateOfBirth) : undefined
                                            }
                                            {...field}
                                            ref={ref => {
                                                field.ref({
                                                    focus: ref?.setFocus,
                                                });
                                            }}
                                            peekNextMonth
                                            showYearDropdown
                                            showMonthDropdown
                                            placeholderText="From"
                                            dropdownMode="select"
                                        />
                                    )}
                                />
                            </Flex>
                            <Divider borderColor="border" my="32px" />
                            <Text as="label" variant="black">
                                Sort By
                            </Text>
                            <Flex gap="16px" mt="16px">
                                <Select placeholder="Start Date" />
                                <Select placeholder="Ascending" />
                            </Flex>
                        </Card>
                    </Collapse>
                    <Card p="32px" rounded="8px" mt="56px" as={Link} to="/client-job-detail">
                        <Flex justify="space-between">
                            <Heading as="h3" fontSize="20px">
                                Build a Minimum Viable responsive NFT auction website with
                                blockchain integration
                            </Heading>
                            <Button leftIcon={<EditPostIcon />}>Edit</Button>
                        </Flex>
                        <Flex gap="30px" align="center" my="24px">
                            <Text
                                as="div"
                                bg="success"
                                variant="primary"
                                p="10px"
                                w="126px"
                                rounded="8px"
                                textAlign="center"
                            >
                                Active
                            </Text>
                            {/* <Text>Waiting for Client to fund a new milestone.</Text> */}
                        </Flex>
                        <Text as="span">Job Posted On: May 30, 2023</Text>
                        <Text my="40px">
                            Lorem ipsum dolor sit amet consectetur. Commodo neque lacus sit fusce
                            diam tincidunt nibh nibh commodo. Sed in adipiscing blandit nullam.
                            Aliquet orci porta fermentum sed sit pretium. Scelerisque consectetur
                            nisl feugiat quam dictum vulputate porttitor turpis sit. Aliquet dapibus
                            aliquam enim orci elit congue justo pharetra ut. Scelerisque consequat
                            faucibus ultricies at id. Et aenean ut varius risus ultrices mattis
                            elementum morbi luctus. Leo cras ipsum metus imperdiet. Tortor consequat
                            elit etiam felis nunc auctor.
                        </Text>
                        <Flex gap="14px" justify="space-between">
                            <Box>
                                <Text variant="secondary" as="label">
                                    Skills:
                                </Text>
                                <Flex mt="16px" gap="8px">
                                    <Tag variant="gray">Blockchain</Tag>
                                    <Tag variant="gray">JavaScript</Tag>
                                </Flex>
                            </Box>
                            <Box>
                                <Text variant="secondary" as="label">
                                    Estimated Time:
                                </Text>
                                <Text variant="black" mt="16px">
                                    1 - 3 Months, More than 30 hours/week
                                </Text>
                            </Box>
                            <Box>
                                <Text variant="secondary" as="label">
                                    Proposals:
                                </Text>
                                <Text variant="black" mt="16px">
                                    27+
                                </Text>
                            </Box>
                            <Box>
                                <Text variant="secondary" as="label">
                                    Budget
                                </Text>
                                <Text variant="black">
                                    <Text as="strong" fontSize="32px" variant="black" mr="8px">
                                        350.63
                                    </Text>
                                    USDT
                                </Text>
                            </Box>
                        </Flex>
                    </Card>
                    <Divider borderColor="border" my="40px" />
                    <Flex gap="16px" justify="flex-end" align="center">
                        <Button variant="unstyled" bg="bgGray" p="8px" minW="max-content" h="auto">
                            <ChevronLeftIcon fontSize="22px" />
                        </Button>
                        <Flex align="center" gap="8px">
                            {[...Array(3)]?.map((item, i) => {
                                if (i < 3) {
                                    return (
                                        <Box
                                            key={i}
                                            p="8px"
                                            rounded="4px"
                                            bg={i === 0 ? 'primary.50' : 'bgGray'}
                                            color={i === 0 ? 'primary.500' : ''}
                                            minW="34px"
                                            textAlign="center"
                                            cursor="pointer"
                                        >
                                            {i + 1}
                                        </Box>
                                    );
                                }
                            })}
                            <Box p="8px 10px" rounded="4px" bg="bgGray">
                                ...
                            </Box>
                        </Flex>
                        <Button variant="unstyled" bg="bgGray" p="8px" minW="max-content" h="auto">
                            <ChevronRightIcon fontSize="22px" />
                        </Button>
                    </Flex>
                </Card>
            </Container>
            <Footer />
        </>
    );
}
